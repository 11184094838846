export const TRANSLATIONS_DE = {
  'GLOBAL.ENGLISH': 'Englisch',
  'GLOBAL.GERMAN': 'Deutsch',
  'GLOBAL.FRENCH': 'Französisch',
  'GLOBAL.ITALIAN': 'Italienisch',
  'GLOBAL.STEP_X_OF_Y': 'Schritt {{current}} von {{total}}',
  'GLOBAL.PUSH_NOTIFICATION': 'Push-Nachricht',
  'GLOBAL.MESSAGE': 'Nachricht',
  'GLOBAL.PHONE': 'Telefonnummer',
  'GLOBAL.TITLE': 'Titel',
  'GLOBAL.EMAIL': 'E-Mail',
  'GLOBAL.PASSWORD': 'Passwort',
  'GLOBAL.SAVE': 'Speichern',
  'GLOBAL.DESCRIPTION': 'Beschreibung',
  'GLOBAL.PERIOD': 'Zeitraum',
  'GLOBAL.ERROR': 'Fehler',
  'GLOBAL.ERROR.EMAIL_EXISTS': 'Die angegebene E-Mail-Adresse wird bereits verwendet.',
  'GLOBAL.TRANSLATION_MISSING': 'Übersetzung fehlt',
  'GLOBAL.SUCCESS': 'Erfolg',
  'GLOBAL.LOADING': 'Lädt...',
  'GLOBAL.LOADING_LOGIN': 'Login...',
  'GLOBAL.MAX_100_CHARS': 'Maximal 100 Zeichen erlaubt.',
  'GLOBAL.YES': 'Ja',
  'GLOBAL.NO': 'Nein',
  'GLOBAL.ACCEPT': 'Akzeptieren',
  'GLOBAL.LATER': 'Später',
  'GLOBAL.ABSTAIN': 'Enthalten',
  'GLOBAL.CANCEL': 'Abbrechen',
  'GLOBAL.OK': 'OK',
  'GLOBAL.UPDATE': 'Aktualisieren',
  'GLOBAL.UPDATING': 'Wird aktualisiert...',
  'GLOBAL.PROPERTY': 'Liegenschaft',
  'GLOBAL.MANDATE': 'Immobilie',
  'GLOBAL.MANDATES': 'Immobilien',
  'GLOBAL.EXTERNAL': 'Extern',
  'GLOBAL.NAME': 'Name',
  'GLOBAL.FIRSTNAME': 'Vorname',
  'GLOBAL.LASTNAME': 'Nachname',
  'GLOBAL.NO_ENTRIES': 'Keine Einträge vorhanden.',
  'GLOBAL.NO_MORE_ENTRIES': 'Keine weiteren Einträge vorhanden.',
  'GLOBAL.PRIVATE': 'Privat',
  'GLOBAL.PRIVATE_E': 'Private',
  'GLOBAL.ATTACHMENTS': 'Anhänge',
  'GLOBAL.UNDO': 'Rückgängig',
  'GLOBAL.FROM': 'Von',
  'GLOBAL.NEXT': 'Weiter',
  'GLOBAL.EMAIL_PLACEHOLDER': 'Bitte E-Mail eingeben...',
  'GLOBAL.EMAIL_REQUIRED': 'E-Mail ist erforderlich.',
  'GLOBAL.EMAIL_INVALID': 'Geben Sie eine gültige E-Mail-Adresse ein.',
  'GLOBAL.FIELD_REQUIRED': 'Feld ist erforderlich.',
  'GLOBAL.FIELD_MAX_LENGTH': 'Der Text darf maximal {{length}} Zeichen lang sein.',
  'GLOBAL.FIELD_MIN_NUMBER': 'Minimum: {{number}}',
  'GLOBAL.FIELD_MAX_NUMBER': 'Maximum: {{number}}',
  'GLOBAL.ONLY_NUMBER': 'Bitte Zahl eingeben.',
  'GLOBAL.ONLY_NUMBERS': 'Bitte nur Zahlen eingeben.',
  'GLOBAL.RESET': 'Zurücksetzen',
  'GLOBAL.STREET': 'Strasse',
  'GLOBAL.ZIP': 'PLZ',
  'GLOBAL.CITY': 'Stadt',
  'GLOBAL.OPTIONAL': '(optional)',
  'GLOBAL.CHOOSE_NOW': 'Jetzt auswählen',
  'GLOBAL.CONTACTDATA': 'Kontaktdaten',
  'GLOBAL.VISIBILITY': 'Sichtbarkeit',
  'GLOBAL.COMMENT': 'Kommentar',
  'GLOBAL.PUBLIC': 'Öffentlich',
  'GLOBAL.SENDER': 'Absender',
  'GLOBAL.LOCATION': 'Ortsangabe',
  'GLOBAL.ADMINISTRATION': 'Verwaltung',
  'GLOBAL.LINK': 'Link',
  'GLOBAL.INTRODUCTION': 'Einführung',
  'GLOBAL.IN': 'In',
  'GLOBAL.QUESTIONS': 'Fragen?',
  'GLOBAL.CODE': 'Code',
  'GLOBAL.TO': 'An',
  'GLOBAL.FOR': 'Für',
  'GLOBAL.RESERVATION': 'Reservation',
  'GLOBAL.COMMON_SPACE': 'Gemeinschaftsraum',
  'GLOBAL.COMMON_SPACES': 'Gemeinschaftsräume',
  'GLOBAL.PERSONALLY_FOR_YOU': 'Persönlich an Sie',
  'GLOBAL.FROM_THE_IMMOAPP': 'aus der ImmoApp',
  'GLOBAL.START': 'Start',
  'GLOBAL.HERE': 'Hier',
  'GLOBAL.PARTIES': 'Parteien',
  'GLOBAL.THE': 'die',
  'GLOBAL.RESTRICTED.COMMENT': 'Sie haben aktuell keine Berechtigung um Kommentare zu erfassen. Bei Fragen wenden Sie sich bitte an Ihre Verwaltung.',
  'GLOBAL.RESTRICTED.REPORT': 'Sie haben aktuell keine Berechtigung um Meldungen zu erfassen. Bei Fragen wenden Sie sich bitte an Ihre Verwaltung.',
  'GLOBAL.REMINDER': 'Erinnerung',

  'ERROR.UNEXPECTED_RETRY_WITH_EXPLANATION': 'Es ist ein unerwarteter Fehler aufgetreten: {{error}}. Bitte versuchen Sie es erneut.',
  'ERROR.UNEXPECTED_RETRY_SIMPLE': 'Es ist ein unerwarteter Fehler aufgetreten. Bitte versuchen Sie es erneut.',

  'DATE.SHORT': 'd. LLL',
  'DATE.LONG': "dd.MM.YYYY 'um' HH:mm 'Uhr'",
  'DATE.FULL': "dd. MMMM YYYY 'um' HH:mm 'Uhr'",
  'DATE.HOUR': 'HH [Uhr]',

  'ACCOUNT_ROLE.TENANT_RESTRICTED': 'Mieter (eingeschränkt)',
  'ACCOUNT_ROLE.TENANT': 'Mieter',
  'ACCOUNT_ROLE.OWNER': 'Eigentümer',
  'ACCOUNT_ROLE.JANITOR': 'Hauswart',
  'ACCOUNT_ROLE.MANAGER': 'Bewirtschafter',
  'ACCOUNT_ROLE.ADMIN': 'Admin',

  'APP.WEB_PUSH_NA.TEXT': 'Ihr Browser unterstützt leider keine Push-Nachrichten. Um stets über alle Neuigkeiten bestens informiert zu sein, empfehlen wir Ihnen die iOS oder Android App herunterzuladen.',
  'APP.WEB_PUSH_NA.BUTTON.HIDE': 'Ausblenden',
  'APP.UPDATE.AVAILABLE': 'App-Update verfügbar',
  'APP.UPDATE.UPDATE_NOW_QUESTION': 'Möchten Sie jetzt auf die neueste Version aktualisieren?',
  'APP.UPDATE.LOADING': 'Neue App-Version wird geladen...',
  'APP.UPDATE.INSTALLING': 'Neue App-Version wird installiert...',
  'APP.NO_INTERNET_CONNECTION.TEXT': 'Keine Internetverbindung.',
  'APP.BACKBUTTON_EXIT_WARNING': 'Drücken Sie erneut den Zurück-Button um die ImmoApp zu beenden.',
  'APP.UPDATE.NEEDED.HEADER': 'Update benötigt',
  'APP.UPDATE.NEEDED.MESSAGE': 'Es steht ein wichtiges Sicherheitsupdate zur Verfügung. Um die ImmoApp weiterhin nutzen zu können, aktualisieren Sie bitte auf die neueste Version.',
  'APP.UPDATE.NEEDED.BUTTON': 'Jetzt aktualisieren',

  'TABS.HOME': 'Home',
  'TABS.DOCUMENTS': 'Dokumente',
  'TABS.VOTES': 'Abstimmung',
  'TABS.PROFILE': 'Profil',

  'REPAIR.STATUS_0': 'Pendent',
  'REPAIR.STATUS_1': 'In Arbeit',
  'REPAIR.STATUS_2': 'Erledigt',

  'RESERVATION.STATUS_0': 'Pendent',
  'RESERVATION.STATUS_1': 'In Arbeit',
  'RESERVATION.STATUS_2': 'Bestätigt',

  'AUTHENTICATION.LOGIN': 'Einloggen',
  'AUTHENTICATION.FORGOT_PASSWORD': 'Passwort vergessen?',
  'AUTHENTICATION.REGISTER': 'Registrieren',
  'AUTHENTICATION.NO_ACCOUNT': 'Noch kein Account?',
  'AUTHENTICATION.INSTRUCTIONS': 'Bitte Accountdaten eingeben.',
  'AUTHENTICATION.PASSWORD_PLACEHOLDER': 'Bitte Passwort eingeben...',
  'AUTHENTICATION.PASSWORD_REQUIRED': 'Passwort wird benötigt.',
  'AUTHENTICATION.PASSWORD_LENGTH': 'Das Passwort muss mindestens 6 Zeichen lang sein.',
  'AUTHENTICATION.PASSWORDS_NOT_THE_SAME': 'Die beiden Passwörter sind nicht gleich.',
  'AUTHENTICATION.INVALID_CREDENTIAL': 'Zugangsdaten nicht gültig. Bitte versuchen Sie es erneut.',
  'AUTHENTICATION.WRONG_PASSWORD': 'Das eingegebene Passwort ist falsch. Bitte versuchen Sie es erneut.',
  'AUTHENTICATION.TOO_MANY_REQUEST': 'Zu viele fehlgeschlagene Anmeldeversuche. Der Zugang zu Ihrem Konto wurde kurzfristig gesperrt. Bitte versuchen Sie es später noch einmal.',
  'AUTHENTICATION.EMAIL_NOT_VERIFIED': 'Ihre E-Mail ist noch nicht verifiziert. Es wurde Ihnen eine Anleitung an Ihre E-Mail-Adresse gesendet.',
  'AUTHENTICATION.USER_NOT_FOUND': 'Wir konnten keinen Benutzer mit dieser E-Mail-Adresse finden. Bitte versuchen Sie es erneut.',
  'AUTHENTICATION.NO_RIGHTS': 'Ihr Benutzer besitzt keine Zugriffsrechte. Falls Sie vermuten, dass dies ein Fehler ist, kontaktieren Sie bitte Ihre Verwaltung.',
  'AUTHENTICATION.SELECT_SPACE_FIRST': 'Bitte wählen Sie zunächst eine Verwaltung aus.',
  'AUTHENTICATION.SIGN_IN_FAILED': 'Anmeldung fehlgeschlagen.',
  'AUTHENTICATION.RESET_PASSWORD_FAILED': 'Passwort-Zurücksetzen fehlgeschlagen.',
  'AUTHENTICATION.NO_SPACES_AVAILABLE': 'Die von Ihnen eingegebene E-Mail-Adresse ist keiner Verwaltung zugewiesen.',
  'AUTHENTICATION.OTHER_ERROR': 'Anmeldung fehlgeschlagen. Bitte versuchen Sie es erneut.',
  'AUTHENTICATION.SELECT_ADMINISTRATION': 'Verwaltung wählen',
  'AUTHENTICATION.UPDATE_PASSWORD_ALERT.HEADER': 'ImmoApp Version 2',
  'AUTHENTICATION.UPDATE_PASSWORD_ALERT.MESSAGE': 'Die ImmoApp wurde aktualisiert. Aus Sicherheitsgründen bitten wir Sie, ein neues Passwort zu setzen.',
  'AUTHENTICATION.UPDATE_PASSWORD_ALERT.NEW_PASSWORD': 'Neues Passwort...',
  'AUTHENTICATION.UPDATE_PASSWORD_ALERT.NEW_PASSWORD_REPEAT': 'Neues Passwort wiederholen...',

  'AUTHENTICATION.SIGNUP.STEP_1_TITLE': 'Information',
  'AUTHENTICATION.SIGNUP.STEP_2_TITLE': 'E-Mail & Zugangscode',
  'AUTHENTICATION.SIGNUP.STEP_3_TITLE': 'Name & Passwort',
  'AUTHENTICATION.SIGNUP.STEP_1_DESCRIPTION': 'Falls Sie bereits Ihre persönlichen Zugangsdaten von Ihrer Verwaltung erhalten haben, können sich direkt im Startbildschirm einloggen. Eine Registrierung wird in dem Fall nicht benötigt.',
  'AUTHENTICATION.SIGNUP.STEP_2_DESCRIPTION': 'Bitte geben Sie Ihre E-Mail und den 6-stelligen Zugangscode an, den Sie von Ihrer Verwaltung erhalten haben.',
  'AUTHENTICATION.SIGNUP.STEP_3_DESCRIPTION': 'Bitte geben Sie Ihren Namen und ein selbstgewähltes Passwort ein.',
  'AUTHENTICATION.SIGNUP.STEP_4_DESCRIPTION_A': 'Sie haben sich erfolgreich angemeldet. Vielen Dank für Ihre Registrierung.',
  'AUTHENTICATION.SIGNUP.STEP_4_DESCRIPTION_B': 'Bitte bestätigen Sie noch Ihre E-Mail-Adresse. Es wurde Ihnen dafür eine Verifizierungs-E-Mail zugeschickt.',
  'AUTHENTICATION.SIGNUP.STEP_4_DESCRIPTION_C': 'Hinweis: Die Verwaltung überprüft zunächst Ihren Account, bevor Sie Zugriff zur ImmoApp erhalten. Sie werden über die Accountfreigabe per E-Mail informiert.',
  'AUTHENTICATION.SIGNUP.FINISH': 'Fertig',
  'AUTHENTICATION.SIGNUP.TERMS': 'Hiermit erkläre ich mich mit den Datenschutzbestimmungen einverstanden.',
  'AUTHENTICATION.SIGNUP.ERROR.INVITE_CODE_REQUIRED': 'Zugangscode wird benötigt.',
  'AUTHENTICATION.SIGNUP.ERROR.FIRST_NAME_REQUIRED': 'Vorname wird benötigt.',
  'AUTHENTICATION.SIGNUP.ERROR.LAST_NAME_REQUIRED': 'Nachname wird benötigt.',
  'AUTHENTICATION.SIGNUP.ERROR.INVITE_CODE_INVALID': 'Der eingegebene Zugangscode ist nicht gültig. Bitte versuchen Sie es erneut oder wenden Sie sich an Ihre Verwaltung.',
  'AUTHENTICATION.SIGNUP.ERROR.DOMAIN_INVALID': 'Die Domain Ihrer E-Mail-Adresse ({{domain}}) konnte nicht verifiziert werden. Bitte achten Sie auf Tippfehler und versuchen Sie es erneut. Falls der Fehler weiterhin auftreten sollte, kontaktieren Sie bitte Ihre Verwaltung.',
  'AUTHENTICATION.SIGNUP.INVITE_CODE_PLACEHOLDER': 'Zugangscode',
  'AUTHENTICATION.INVITE_CODE_LENGTH': 'Der Zugangscode muss 6 Zeichen lang sein.',

  'AUTHENTICATION.RECOVER_PASSWORD.TITLE': 'Setzen Sie Ihr Passwort zurück',
  'AUTHENTICATION.RECOVER_PASSWORD.DESCRIPTION': 'Bitte geben Sie Ihre E-Mail-Adresse ein und wir senden Ihnen eine E-Mail zum Zurücksetzen Ihres Passworts.',
  'AUTHENTICATION.RECOVER_PASSWORD.BUTTON': 'Passwort zurücksetzen',
  'AUTHENTICATION.RECOVER_PASSWORD.SUCCESS_MESSAGE': 'Eine E-Mail mit Anweisungen wurde erfolgreich an Sie gesendet.',

  'PROFILE.TITLE': 'Profil',
  'PROFILE.TENANT.TITLE': 'Mieter',
  'PROFILE.TENANT.TITLE_LONG': 'Mieter Administration',
  'PROFILE.TENANT.ADMINISTER_TITLE': 'Mieter verwalten',
  'PROFILE.TENANT.ADMINISTER_DESCRIPTION': 'Mieter',
  'PROFILE.TENANT.INVITED_ON': 'Eingeladen am',
  'PROFILE.TENANT.NO_TENANTS_AVAILABLE': 'Sie verwalten aktuell keine Mieter.',
  'PROFILE.TENANT.ADD.TITLE': 'Mieter hinzufügen',
  'PROFILE.TENANT.ADD.MANDATE.PLACEHOLDER': 'Bitte wählen Sie eine Immobilie aus',
  'PROFILE.TENANT.ADD.FIRSTNAME': 'Vorname',
  'PROFILE.TENANT.ADD.FIRSTNAME.PLACEHOLDER': 'Vorname',
  'PROFILE.TENANT.ADD.LASTNAME': 'Nachname',
  'PROFILE.TENANT.ADD.LASTNAME.PLACEHOLDER': 'Nachname',
  'PROFILE.TENANT.ADD.EMAIL_REQUIRED': 'E-Mail ist erforderlich.',
  'PROFILE.TENANT.ADD.EMAIL_INVALID': 'Geben Sie eine gültige E-Mail-Adresse ein.',
  'PROFILE.TENANT.ADD.MANDATE_REQUIRED': 'Immobilie ist erforderlich.',
  'PROFILE.TENANT.ADD.FIRSTNAME_REQUIRED': 'Vorname ist erforderlich.',
  'PROFILE.TENANT.ADD.LASTNAME_REQUIRED': 'Nachname ist erforderlich.',
  'PROFILE.TENANT.ADD.LOADING_MESSAGE': 'Mieter wird angelegt...',
  'PROFILE.TENANT.ADD.SUCCESS': 'Mieter wurde erfolgreich angelegt.',
  'PROFILE.TENANT.ADD.ERROR.EXISTING_EMAIL': 'Die eingegebene E-Mail-Adresse ist bereits einem anderen Account zugeordnet.',

  'PROFILE.TENANT.EDIT.TITLE': 'Mieter editieren',
  'PROFILE.TENANT.EDIT.MESSAGE': 'Hier können Sie Ihrem Mieter die Zugangsdaten erneut zuschicken und das Mietverhältnis (und somit den Zugang zur ImmoApp) beenden.',
  'PROFILE.TENANT.EDIT.TERMINATE': 'Mietverhältnis beenden',
  'PROFILE.TENANT.EDIT.TERMINATE.LOADING_MESSAGE': 'Mietverhältnis wird beendet...',
  'PROFILE.TENANT.EDIT.TERMINATE.SUCCESS': 'Mieter wurde erfolgreich entfernt und besitzt nun keinen Zugang mehr zur ImmoApp.',
  'PROFILE.TENANT.EDIT.RESEND_ACCESS': 'Zugang erneut senden',
  'PROFILE.TENANT.EDIT.RESEND_ACCESS.LOADING_MESSAGE': 'Zugang wird verschickt...',
  'PROFILE.TENANT.EDIT.RESEND_ACCESS.SUCCESS': 'Zugangsdaten wurden erfolgreich an die E-Mail-Adresse des Mieters verschickt.',
  'PROFILE.INFO.TITLE': 'Infos',
  'PROFILE.INFO.YOUR_DATA_TITLE': 'Ihre Daten',
  'PROFILE.INFO.YOUR_DATA_DESCRIPTION': 'Eingeloggt als',
  'PROFILE.INFO.TERMS_OF_USE_TITLE': 'Nutzungsbedingungen',
  'PROFILE.INFO.TERMS_OF_USE_DESCRIPTION': 'Regeln für die ImmoApp',
  'PROFILE.INFO.INSTRUCTION_TITLE': 'Anleitung',
  'PROFILE.INFO.INSTRUCTION_GENERATING': 'Anleitung wird generiert...',
  'PROFILE.INFO.INSTRUCTION_DESCRIPTION': 'Einführung in die ImmoApp',
  'PROFILE.INFO.CONTACT_TITLE': 'Kontakt',
  'PROFILE.INFO.CONTACT_DESCRIPTION': 'Verwaltung direkt kontaktieren',
  'PROFILE.INFO.LEGAL_TITLE': 'Rechtliches',
  'PROFILE.INFO.LEGAL_DESCRIPTION': 'Datenschutzerklärung',
  'PROFILE.SETTINGS.TITLE': 'Einstellungen',
  'PROFILE.SETTINGS.ACCOUNT_TITLE': 'Account Einstellungen',
  'PROFILE.SETTINGS.ACCOUNT_DESCRIPTION': 'E-Mail, Passwort & Löschung',
  'PROFILE.SETTINGS.APP_TITLE': 'App Einstellungen',
  'PROFILE.SETTINGS.APP_DESCRIPTION': 'Sprache & Benachrichtigungen',
  'PROFILE.SETTINGS.DELETE_ACCOUNT': 'Account löschen',
  'PROFILE.SETTINGS.DELETE_ACCOUNT.CONFIRM': 'Wenn Sie mit OK bestätigen, wird die Löschung Ihres Accounts beauftragt.',
  'PROFILE.SETTINGS.DELETE_ACCOUNT.SUCCESS': 'Ihre Anfrage wurde erfolgreich versendet. Wir werden Sie in den kommenden Wochen per E-Mail benachrichtigen, sobald Ihr Account gelöscht wurde.',
  'PROFILE.SETTINGS.LOGOUT': 'Ausloggen',
  'PROFILE.SETTINGS.LOGOUT.CONFIRM': 'Möchten Sie sich abmelden?',
  'PROFILE.SETTINGS.LOGOUT.IN_PROGRESS': 'Sie werden ausgeloggt. Bitte haben Sie einen Moment Geduld.',
  'PROFILE.UPDATE_PHOTO.TITLE': 'Foto bearbeiten',
  'PROFILE.UPDATE_PHOTO_SUCCESS': 'Ihr Foto wurde erfolgreich aktualisiert.',

  'PROFILE.APP_SETTINGS.SELECT_LANGUAGE': 'Sprache wählen',
  'PROFILE.APP_SETTINGS.PUSH_NOTIFICATIONS': 'Push Benachrichtigungen',
  'PROFILE.APP_SETTINGS.EMAIL_NOTIFICATIONS': 'E-Mail Benachrichtigungen',
  'PROFILE.APP_SETTINGS.LANGUAGE': 'Sprache',

  'PROFILE.APP_SETTINGS.PUSH_PREFERENCES.DOCUMENT.TITLE': 'Dokumente',
  'PROFILE.APP_SETTINGS.PUSH_PREFERENCES.DOCUMENT.DESCRIPTION': 'Neu hinzugefügte Dokumente',
  'PROFILE.APP_SETTINGS.PUSH_PREFERENCES.MANDATE.TITLE': 'Aktuelles',
  'PROFILE.APP_SETTINGS.PUSH_PREFERENCES.MANDATE.DESCRIPTION': 'News und Schadensmeldungen',
  'PROFILE.APP_SETTINGS.PUSH_PREFERENCES.PINBOARD.TITLE': 'Pinnwand',
  'PROFILE.APP_SETTINGS.PUSH_PREFERENCES.PINBOARD.DESCRIPTION': 'Neuigkeiten von Ihren Nachbarn',
  'PROFILE.APP_SETTINGS.PUSH_PREFERENCES.VOTE.TITLE': 'Abstimmungen',
  'PROFILE.APP_SETTINGS.PUSH_PREFERENCES.VOTE.DESCRIPTION': 'Updates und Erinnerungen',
  'PROFILE.APP_SETTINGS.PUSH_PREFERENCES.STAFF.TITLE': 'Registrierung (nur für Mitarbeiter)',
  'PROFILE.APP_SETTINGS.PUSH_PREFERENCES.STAFF.DESCRIPTION': 'Hinweis bei neuer Registrierung',
  'PROFILE.APP_SETTINGS.PUSH_PREFERENCES.RESERVATION.TITLE': 'Reservationen',
  'PROFILE.APP_SETTINGS.PUSH_PREFERENCES.RESERVATION.DESCRIPTION': 'Updates und Erinnerungen',

  'PROFILE.APP_SETTINGS.EMAIL_PREFERENCES.MESSAGE.TITLE': 'Nachrichten',
  'PROFILE.APP_SETTINGS.EMAIL_PREFERENCES.MESSAGE.DESCRIPTION': 'E-Mail bei neuer Nachricht erhalten',
  'PROFILE.APP_SETTINGS.EMAIL_PREFERENCES.REPAIR.TITLE': 'Tickets Neu',
  'PROFILE.APP_SETTINGS.EMAIL_PREFERENCES.REPAIR.DESCRIPTION': 'E-Mail bei neuem Ticket erhalten',
  'PROFILE.APP_SETTINGS.EMAIL_PREFERENCES.REPAIR_UPDATE.TITLE': 'Tickets Update',
  'PROFILE.APP_SETTINGS.EMAIL_PREFERENCES.REPAIR_UPDATE.DESCRIPTION': 'E-Mail bei neuem Ticket Status erhalten',
  'PROFILE.APP_SETTINGS.EMAIL_PREFERENCES.PINBOARD.TITLE': 'Pinnwandeinträge',
  'PROFILE.APP_SETTINGS.EMAIL_PREFERENCES.PINBOARD.DESCRIPTION': 'E-Mail bei neuem Pinnwandeintrag erhalten',
  'PROFILE.APP_SETTINGS.EMAIL_PREFERENCES.COMMENT.TITLE': 'Kommentare',
  'PROFILE.APP_SETTINGS.EMAIL_PREFERENCES.COMMENT.DESCRIPTION': 'E-Mail bei neuem Kommentar erhalten',
  'PROFILE.APP_SETTINGS.EMAIL_PREFERENCES.VOTEITEM.TITLE': 'Abstimmung Traktandum (nur für Mitarbeiter)',
  'PROFILE.APP_SETTINGS.EMAIL_PREFERENCES.VOTEITEM.DESCRIPTION': 'E-Mail bei neuem Traktandum erhalten',
  'PROFILE.APP_SETTINGS.EMAIL_PREFERENCES.RESERVATION.TITLE': 'Reservationen',
  'PROFILE.APP_SETTINGS.EMAIL_PREFERENCES.RESERVATION.DESCRIPTION': 'E-Mail mit Updates und Erinnerungen erhalten',

  'PROFILE.ACCOUNT_SETTINGS.UPDATE_PASSWORD': 'Passwort wurde erfolgreich aktualisiert.',
  'PROFILE.ACCOUNT_SETTINGS.UPDATE_EMAIL': 'E-Mail wurde erfolgreich aktualisiert. Aus Sicherheitsgründen müssen Sie sich erneut einloggen.',
  'PROFILE.ACCOUNT_SETTINGS.UPDATE_PASSWORD_TITLE': 'Passwort ändern',
  'PROFILE.ACCOUNT_SETTINGS.UPDATE_EMAIL_TITLE': 'E-Mail ändern',
  'PROFILE.ACCOUNT_SETTINGS.UPDATE_EMAIL_ERP_NOTICE1': 'Eine Anpassung Ihrer E-Mail-Adresse muss im System Ihrer Verwaltung erfolgen.',
  'PROFILE.ACCOUNT_SETTINGS.UPDATE_EMAIL_ERP_NOTICE2': 'Klicken Sie hier, um Ihre Verwaltung per E-Mail zu kontaktieren.',
  'PROFILE.ACCOUNT_SETTINGS.UPDATE_PASSWORD_PLACEHOLDER': 'Geben Sie ein neues Passwort ein...',
  'PROFILE.ACCOUNT_SETTINGS.UPDATE_EMAIL_PLACEHOLDER': 'Geben Sie eine neue E-Mail ein...',
  'PROFILE.ACCOUNT_SETTINGS.UPDATE_EMAIL_INPUT': 'Neue E-Mail',
  'PROFILE.ACCOUNT_SETTINGS.UPDATE_EMAIL_REPEATED': 'Bitte geben Sie eine neue E-Mail-Adresse ein.',
  'PROFILE.ACCOUNT_SETTINGS.UPDATE_PASSWORD_REPEATED': 'Bitte geben Sie ein neues Passwort ein.',
  'PROFILE.ACCOUNT_SETTINGS.UPDATE_PASSWORD_INPUT': 'Neue Passwort',
  'PROFILE.ACCOUNT_SETTINGS.CURRENT_PASSWORD_PLACEHOLDER': 'Geben Sie Ihr aktuelles Passwort ein...',
  'PROFILE.ACCOUNT_SETTINGS.CURRENT_PASSWORD_TITLE': 'Aktuelles Passwort',
  'PROFILE.ACCOUNT_SETTINGS.CURRENT_PASSWORD_NOTE': 'Um Missbrauch vorzubeugen, bitten wir Sie zunächst Ihr Passwort erneut einzugeben, bevor Sie die Änderungen vornehmen können.',

  'PROFILE.CONTACT.TITLE': 'Kontakt',
  'PROFILE.CONTACT.DEVELOPMENT': 'Entwickelt von',

  'PROFILE.PERSON_DATA.STATUS': 'Status',
  'PROFILE.PERSON_DATA.POST_BOX': 'Postfach',
  'PROFILE.PERSON_DATA.PRIVATE_PHONE': 'Tel. (Privat)',
  'PROFILE.PERSON_DATA.MOBILE_PHONE': 'Tel. (Mobil)',
  'PROFILE.PERSON_DATA.WORK_PHONE': 'Tel. (Arbeit)',

  'PROFILE.TERMS_OF_USE.TITLE': 'Nutzungsbedingungen der ImmoApp',
  'PROFILE.TERMS_OF_USE.SUBTITLE': 'Gemeinsam für eine respektvolle und positive App-Erfahrung',
  'PROFILE.TERMS_OF_USE.INTRO_TITLE': 'Was wir bei der Nutzung der ImmoApp erwarten',
  'PROFILE.TERMS_OF_USE.INTRO_TEXT1': 'Um sicherzustellen, dass sich alle in unserer Gemeinschaft willkommen und wohl fühlen, haben wir einige grundlegende Regeln für die ImmoApp festgelegt. Diese Regeln spiegeln unsere gemeinsamen Werte wider und bilden die Grundlage für eine respektvolle und positive App-Erfahrung.',
  'PROFILE.TERMS_OF_USE.INTRO_TEXT2': 'Wir bitten alle Nutzer*innen, die folgenden Richtlinien zu beachten:',
  'PROFILE.TERMS_OF_USE.LIST_1_TITLE': 'Veröffentlichung persönlicher Daten',
  'PROFILE.TERMS_OF_USE.LIST_1_TEXT': 'Persönliche Informationen, die zur Identifizierung einer Privatperson führen könnten, dürfen nur mit ausdrücklicher Zustimmung der betreffenden Person veröffentlicht werden. Dies gilt auch für Bilder/Videos von Personengruppen oder zufällige Personen/Autokennzeichen im Hintergrund.',
  'PROFILE.TERMS_OF_USE.LIST_2_TITLE': 'Belästigung und/oder Anstiftung zur Gewalt',
  'PROFILE.TERMS_OF_USE.LIST_2_TEXT': 'Jegliche Form von Belästigung, Bedrohung oder Aufrufen zur Gewalt gegen Einzelpersonen oder Personengruppen ist in der ImmoApp nicht gestattet.',
  'PROFILE.TERMS_OF_USE.LIST_3_TITLE': 'Diskriminierung',
  'PROFILE.TERMS_OF_USE.LIST_3_TEXT': 'Wir tolerieren keine Diskriminierung oder Hassrede aus irgendeinem Grund. Jede*r ist in unserer Gemeinschaft willkommen und wird so akzeptiert, wie er/sie ist. Äußern Sie Ihre Meinungen und Erfahrungen konstruktiv, ohne andere zu beleidigen, unnötige Verallgemeinerungen zu treffen oder zu provozieren.',
  'PROFILE.TERMS_OF_USE.LIST_4_TITLE': 'Störendes Verhalten',
  'PROFILE.TERMS_OF_USE.LIST_4_TEXT': 'Wiederholtes veröffentlichen von gleichen oder ähnlichen Inhalten, unnötig unfreundliche Antworten oder absichtliches Stören der ImmoApp-Erfahrung anderer Nutzer*innen sind nicht gestattet.',
  'PROFILE.TERMS_OF_USE.LIST_5_TITLE': 'Aufdringliches sexuelles Verhalten',
  'PROFILE.TERMS_OF_USE.LIST_5_TEXT': 'Aufdringliches sexuelles Verhalten oder das Anbieten von sexuellen Dienstleistungen wird nicht toleriert. Begegnen Sie anderen Nutzer*innen immer respektvoll und mit Würde.',
  'PROFILE.TERMS_OF_USE.LIST_6_TITLE': 'Gewalttätige und/oder explizit sexuelle Inhalte',
  'PROFILE.TERMS_OF_USE.LIST_6_TEXT': 'Jegliche Art von drastischen gewalttätigen und/oder explizit sexuellen Inhalten (in schriftlicher oder visueller Form) sind nicht erlaubt. Bilder von Personen sind erlaubt, solange alle intimen Körperregionen / Geschlechtsteile bedeckt sind.',
  'PROFILE.TERMS_OF_USE.LIST_7_TITLE': 'Inhalte von Drittanbietern',
  'PROFILE.TERMS_OF_USE.LIST_7_TEXT': 'Das Teilen von urheberrechtlich geschützten Inhalten ist in der ImmoApp nicht gestattet.',
  'PROFILE.TERMS_OF_USE.LIST_8_TITLE': 'Kommerzielle Inhalte',
  'PROFILE.TERMS_OF_USE.LIST_8_TEXT': 'Kommerzielle Werbung ist in der ImmoApp nicht erlaubt. Private Kleinanzeigen sind jedoch in Ordnung.',
  'PROFILE.TERMS_OF_USE.LIST_9_TITLE': 'Illegale Aktivitäten',
  'PROFILE.TERMS_OF_USE.LIST_9_TEXT': 'Die Ausführung, Förderung, Planung oder Anstiftung zu illegalen Aktivitäten ist nicht gestattet.',
  'PROFILE.TERMS_OF_USE.MODERATION_TITLE': 'Moderation durch die Verwaltung',
  'PROFILE.TERMS_OF_USE.MODERATION_TEXT1': 'Die Moderation der ImmoApp liegt in den Händen der jeweiligen Verwaltung. Es ist ihre Verantwortung und ihr Privileg, dafür zu sorgen, dass alle Nutzer*innen die App und die Beiträge der Gemeinschaft in einer sicheren, respektvollen und positiven Atmosphäre genießen können. Die Verwaltung achtet darauf, dass die oben genannten Richtlinien eingehalten werden, und ist berechtigt, entsprechend zu handeln, wenn Verstöße festgestellt werden.',
  'PROFILE.TERMS_OF_USE.MODERATION_TEXT2': 'Sollten Sie auf problematische Inhalte stoßen oder Fragen zur Moderation haben, wenden Sie sich bitte direkt an Ihre Verwaltung. Wir danken Ihnen für Ihr Verständnis und Ihre Mitwirkung bei der Schaffung einer einladenden und respektvollen Gemeinschaft in der ImmoApp.',

  'DASHBOARD.NO_DESCRIPTION_AVAILABLE': 'Keine Beschreibung vorhanden.',

  'REPORT.TICKET.REPAIR.TITLE': 'Schadensmeldung',
  'REPORT.TICKET.REPAIR.INTERNAL.DESCRIPTION': 'Die Verwaltung (und optional Ihre Nachbarn) über einen Schaden informieren',
  'REPORT.TICKET.REPAIR.EXTERNAL.DESCRIPTION': 'Die Verwaltung über einen Schaden informieren',
  'REPORT.TICKET.KEYORDER.TITLE': 'Schlüssel- oder Zugangskarte',
  'REPORT.TICKET.KEYORDER.DESCRIPTION': 'Verlorene Schlüssel ersetzen, Nachschlüssel bestellen oder Zylinder ersetzen',
  'REPORT.TICKET.NAMEPLATE.TITLE': 'Namensschild',
  'REPORT.TICKET.NAMEPLATE.DESCRIPTION': 'Neues Namensschild für Haustüre, Wohnungstüre oder Briefkasten',
  'REPORT.TICKET.DOCUMENT.TITLE': 'Dokument oder Formular',
  'REPORT.TICKET.DOCUMENT.DESCRIPTION': 'Vertrag, Reglement, Antragsformular und andere Dokumente bestellen',
  'REPORT.TICKET.OTHER.TITLE': 'Sonstige Mitteilung',
  'REPORT.TICKET.OTHER.DESCRIPTION': 'Die Verwaltung über ein sonstiges Anliegen informieren',
  'REPORT.MESSAGE': 'Nachricht',
  'REPORT.PINBOARD': 'Pinnwandeintrag',
  'REPORT.VOTE_ITEM': 'Traktandum',
  'REPORT.ADD.TITLE': 'Hallo, wie können wir helfen?',
  'REPORT.ADD.SECTION.REPAIR': 'Schadensmeldung',
  'REPORT.ADD.SECTION.ORDER': 'Bestellung',
  'REPORT.ADD.SECTION.OTHER': 'Weitere Anliegen',
  'REPORT.ADD.NOTE.REPAIR.PUBLIC': 'Sie {{action}} eine öffentliche Schadensmeldung für {{location}}.',
  'REPORT.ADD.NOTE.REPAIR.PRIVATE': 'Sie {{action}} eine private Schadensmeldung für {{location}}.',
  'REPORT.ADD.NOTE.KEYORDER': 'Sie {{action}} eine Schlüssel- oder Kartenbestellung für {{location}}.',
  'REPORT.ADD.NOTE.NAMEPLATE': 'Sie {{action}} eine Namensschildbestellung für {{location}}.',
  'REPORT.ADD.NOTE.DOCUMENT': 'Sie {{action}} eine Dokument- oder Formularbestellung für {{location}}.',
  'REPORT.ADD.NOTE.PINBOARD': 'Sie {{action}} einen öffentliche Pinnwandeintrag für {{location}}.',
  'REPORT.ADD.NOTE.RESERVATION': 'Sie {{action}} eine Reservation für {{location}}.',
  'REPORT.ADD.NOTE.VOTEITEM': 'Sie {{action}} ein Traktandum für {{location}}.',
  'REPORT.ADD.NOTE.OTHER': 'Sie {{action}} eine private sonstige Mitteilung für {{location}}.',
  'REPORT.ADD.NOTE.CREATING': 'erfassen',
  'REPORT.ADD.NOTE.EDITING': 'bearbeiten',
  'REPORT.ADD.SUBMIT': 'Abschicken',
  'REPORT.ADD.LIST.PINBOARD.TITLE': 'Pinnwandeintrag',
  'REPORT.ADD.LIST.PINBOARD.DESCRIPTION': 'Infos, Angebote und Veranstaltungen mit Ihren Nachbarn teilen',
  'REPORT.ADD.LIST.RESERVATION.TITLE': 'Reservation',
  'REPORT.ADD.LIST.RESERVATION.DESCRIPTION': 'Gemeinschaftsraum reservieren',
  'REPORT.ADD.LIST.VOTE_ITEM.TITLE': 'Traktandum',
  'REPORT.ADD.LIST.VOTE_ITEM.DESCRIPTION': 'Diskussions- oder Abstimmungspunkt für die nächste Versammlung erfassen',
  'REPORT.ADD.NEW_REPAIR': 'Neue Schadensmeldung',
  'REPORT.ADD.NEW_PINBOARD': 'Neuer Pinnwandeintrag',
  'REPORT.ADD.NEW_RESERVATION': 'Neue Reservation',
  'REPORT.ADD.NEW_KEYORDER': 'Neue Schlüssel- oder Kartenbestellung',
  'REPORT.ADD.NEW_NAMEPLATE': 'Neue Namensschildbestellung',
  'REPORT.ADD.NEW_DOCUMENT': 'Neue Dokument- oder Formularbestellung',
  'REPORT.ADD.NEW_VOTE_ITEM': 'Neues Traktandum',
  'REPORT.ADD.RESERVATION.PLACE.TITLE': 'Gemeinschaftsraum',
  'REPORT.ADD.RESERVATION.PLACE.PLACEHOLDER': 'Gemeinschaftsraum wählen...',
  'REPORT.ADD.RESERVATION.PLACE.CONFIRMATION_NEEDED': 'Hinweis: Reservationen für diesen Gemeinschaftsraum müssen zunächst von der Verwaltung bestätigt werden.',
  'REPORT.ADD.RESERVATION.TIME.TITLE': 'Tag und Zeit',
  'REPORT.ADD.NEW_OTHER': 'Sonstige Mitteilung',
  'REPORT.ADD.LOCATION': 'Ort',
  'REPORT.ADD.LOCATION.PLACEHOLDER': 'Liegenschaft wählen...',
  'REPORT.ADD.LOCATION.DETAILS': 'Weitere Details zum Ort',
  'REPORT.ADD.PUBLIC': 'Öffentlich',
  'REPORT.ADD.PUBLIC.VISIBILITY': 'Für Nachbarn sichtbar?',
  'REPORT.ADD.NAMEPLATE.TITLE': 'Namensschild',
  'REPORT.ADD.NAMEPLATE.ROW1': 'Zeile 1',
  'REPORT.ADD.NAMEPLATE.ROW2': 'Zeile 2',
  'REPORT.ADD.KEYORDER.TITLE': 'Weitere Infos zum Schlüssel / Karte',
  'REPORT.ADD.KEYORDER.QUANTITY': 'Anzahl',
  'REPORT.ADD.KEYORDER.LABEL': 'Schlüsselbezeichnung',
  'REPORT.ADD.ATTACHMENTS.ADD': 'Anhang hinzufügen',
  'REPORT.ADD.ATTACHMENTS.REPAIR': 'Bitte laden Sie Bilder des Schadens hoch.',
  'REPORT.ADD.ATTACHMENTS.KEYORDER': 'Bitte laden Sie ein Bild des Schlüssels / Karte hoch.',
  'REPORT.ADD.TITLE_DESCRIPTION': 'Titel / Nachricht',
  'REPORT.ADD.TITLE_DESCRIPTION.DESCRIPTION': 'Nachricht',
  'REPORT.SUBMIT_REPORT.REPAIR.LOADING': 'Ihre Schadensmeldung wird übermittelt...',
  'REPORT.SUBMIT_REPORT.ORDER.LOADING': 'Ihre Bestellung wird übermittelt...',
  'REPORT.SUBMIT_REPORT.OTHER.LOADING': 'Ihre Mitteilung wird übermittelt...',
  'REPORT.SUBMIT_REPORT.PINBOARD.LOADING': 'Ihr Pinnwandeintrag wird übermittelt...',
  'REPORT.SUBMIT_REPORT.VOTE_ITEM.LOADING': 'Ihr Traktandum wird übermittelt...',
  'REPORT.SUBMIT_REPORT.RESERVATION.LOADING': 'Ihre Reservation wird übermittelt...',
  'REPORT.SUBMIT_REPORT.REPAIR.SUCCESS': 'Ihre Schadensmeldung wurde erfolgreich übermittelt.',
  'REPORT.SUBMIT_REPORT.ORDER.SUCCESS': 'Ihre Bestellung wurde erfolgreich übermittelt.',
  'REPORT.SUBMIT_REPORT.OTHER.SUCCESS': 'Ihre Mitteilung wurde erfolgreich übermittelt.',
  'REPORT.SUBMIT_REPORT.PINBOARD.SUCCESS': 'Ihr Pinnwandeintrag wurde erfolgreich übermittelt.',
  'REPORT.SUBMIT_REPORT.VOTE_ITEM.SUCCESS': 'Ihr Traktandum wurde erfolgreich übermittelt.',
  'REPORT.SUBMIT_REPORT.RESERVATION.SUCCESS': 'Ihre Reservation wurde erfolgreich übermittelt.',
  'REPORT.UPDATE_REPORT.REPAIR.LOADING': 'Schadensmeldung wird aktualisiert...',
  'REPORT.UPDATE_REPORT.ORDER.LOADING': 'Bestellung wird aktualisiert...',
  'REPORT.UPDATE_REPORT.OTHER.LOADING': 'Mitteilung wird aktualisiert...',
  'REPORT.UPDATE_REPORT.PINBOARD.LOADING': 'Pinnwandeintrag wird aktualisiert...',
  'REPORT.UPDATE_REPORT.VOTE_ITEM.LOADING': 'Traktandum wird aktualisiert...',
  'REPORT.UPDATE_REPORT.RESERVATION.LOADING': 'Reservation wird aktualisiert...',
  'REPORT.UPDATE_REPORT.REPAIR.SUCCESS': 'Ihre Schadensmeldung wurde erfolgreich aktualisiert.',
  'REPORT.UPDATE_REPORT.ORDER.SUCCESS': 'Ihre Bestellung wurde erfolgreich aktualisiert.',
  'REPORT.UPDATE_REPORT.OTHER.SUCCESS': 'Ihre Mitteilung wurde erfolgreich aktualisiert.',
  'REPORT.UPDATE_REPORT.PINBOARD.SUCCESS': 'Ihr Pinnwandeintrag wurde erfolgreich aktualisiert.',
  'REPORT.UPDATE_REPORT.VOTE_ITEM.SUCCESS': 'Ihr Traktandum wurde erfolgreich aktualisiert.',
  'REPORT.UPDATE_REPORT.RESERVATION.SUCCESS': 'Ihre Reservation wurde erfolgreich aktualisiert.',
  'REPORT.TOO_MANY_REQUESTS': 'Es gab zu viele Zugriffe in kurzer Zeit. Bitte warten Sie ein paar Minuten und versuchen Sie es dann erneut.',
  'REPORT.UPLOAD_CANCEL': 'Upload wurde abgebrochen.',
  'REPORT.ADD.ACCEPT_PRIVACY_POLICY_WRAPPER': 'Ich akzeptiere die {{link}}.',
  'REPORT.ADD.ACCEPT_PRIVACY_POLICY_WORD': 'Datenschutzerklärung',
  'REPORT.ADD.TITLE_REQUIRED': 'Bitte geben Sie einen Titel ein.',
  'REPORT.ADD.STATUS_REQUIRED': 'Bitte wählen Sie einen Status aus.',
  'REPORT.ADD.DESCRIPTION_REQUIRED': 'Bitte geben Sie eine Beschreibung ein.',
  'REPORT.ADD.MESSAGE_REQUIRED': 'Bitte geben Sie eine Nachricht ein.',
  'REPORT.ADD.CAPTCHA_REQUIRED': 'Bitte lösen Sie zunächst das CAPTCHA.',
  'REPORT.ADD.PROPERTY_REQUIRED': 'Bitte wählen Sie eine Immobilie aus.',
  'REPORT.ADD.FIRSTNAME_REQUIRED': 'Bitte geben Sie einen Vornamen ein.',
  'REPORT.ADD.LASTNAME_REQUIRED': 'Bitte geben Sie einen Nachnamen ein.',
  'REPORT.DELETE.TITLE': 'Eintrag löschen',
  'REPORT.DELETE.DESCRIPTION': 'Wenn Sie mit OK bestätigen, wird der von Ihnen verfasste Eintrag unwiderruflich gelöscht.',
  'REPORT.DELETE.NOT_POSSIBLE': 'Das Ticket kann nicht mehr gelöscht werden, da sich der Status bereits verändert hat.',
  'REPORT.DELETE.SUCCESS': 'Der Eintrag wurde erfolgreich gelöscht.',

  'REPORT.EDIT.GENERAL': 'Eintrag bearbeiten',
  'REPORT.EDIT.REPAIR': 'Schadensmeldung bearbeiten',
  'REPORT.EDIT.PINBOARD': 'Pinnwandeintrag bearbeiten',
  'REPORT.EDIT.RESERVATION': 'Reservation bearbeiten',
  'REPORT.EDIT.KEYORDER': 'Schlüssel- oder Kartenbestellung bearbeiten',
  'REPORT.EDIT.NAMEPLATE': 'Namensschildbestellung bearbeiten',
  'REPORT.EDIT.DOCUMENT': 'Dokument- oder Formularbestellung bearbeiten',
  'REPORT.EDIT.VOTE_ITEM': 'Traktandum bearbeiten',
  'REPORT.EDIT.CONTACT_DATA': 'Datenanfrage bearbeiten',
  'REPORT.EDIT.OTHER': 'Sonstige Mitteilung bearbeiten',

  'VOTES.ITEMS.SUBMITTED_ON': 'Übermittelt am',
  'VOTES.ITEMS.EDIT_NOT_ALLOWED': 'Ihr Traktandum wird bereits in einer Abstimmung genutzt und kann daher nicht mehr bearbeitet werden.',

  'VOTES.TITLE': 'Abstimmungen',
  'VOTES.MY_VOTE_ITEMS': 'Meine Traktanden',
  'VOTES.DATE_UNTIL': 'bis',
  'VOTES.LIST.TITLE': 'Abstimmungen im',
  'VOTES.DATE_END': 'Ende',
  'VOTES.NO_VOTES_AVAILABLE': 'Keine Abstimmungen vorhanden.',
  'VOTES.NO_VOTE_ITEMS_SUBMITTED': 'Sie haben noch keine Traktanden eingereicht.',
  'VOTES.STATUS_ACTIVE_NOT_REGISTERED': 'Jetzt registrieren und abstimmen',
  'VOTES.STATUS_ACTIVE_REGISTERED.VOTE': 'Abstimmung läuft bis',
  'VOTES.STATUS_ACTIVE_REGISTERED.SURVEY': 'Umfrage läuft bis',
  'VOTES.STATUS_WARRANT_REGISTERED': 'Vollmacht vergeben',
  'VOTES.STATUS_WARRANT_COMPLETED': 'Mit Vollmacht abgeschlossen',
  'VOTES.STATUS_COMPLETED.VOTE': 'Abstimmung erfolgreich abgeschlossen',
  'VOTES.STATUS_COMPLETED.SURVEY': 'Umfrage erfolgreich abgeschlossen',
  'VOTES.STATUS_NOT_STARTED_NOT_REGISTERED': 'Jetzt registrieren',
  'VOTES.STATUS_NOT_STARTED_REGISTERED.VOTE': 'Abstimmung beginnt am',
  'VOTES.STATUS_NOT_STARTED_REGISTERED.SURVEY': 'Umfrage beginnt am',
  'VOTES.STATUS_INACTIVE.VOTE': 'Abstimmung nicht mehr aktiv',
  'VOTES.STATUS_INACTIVE.SURVEY': 'Umfrage nicht mehr aktiv',
  'VOTES.STAFF_ALL_VOTES_IN_ADMIN_APP': 'Innerhalb der ImmoApp werden für Mitarbeiter nur die Abstimmungen der letzten 2 Jahre angezeigt. Um ältere Abstimmungen zu sehen, öffnen Sie bitte die Admin-App.',

  'VOTE.STATUS_NOT_ALLOWED.LABEL': 'Sie haben keine Berechtigung an der Abstimmung teilzunehmen. Dies kann vorkommen wenn für Ihre Eigentümernummer bereits eine Stimmabgabe erfolgt ist.',
  'VOTE.STATUS_NOT_STARTED_NOT_REGISTERED.LABEL.VOTE': 'Jetzt registrieren und eine automatische Erinnerung zum Beginn der Abstimmung erhalten.',
  'VOTE.STATUS_NOT_STARTED_NOT_REGISTERED.LABEL.SURVEY': 'Jetzt registrieren und eine automatische Erinnerung zum Beginn der Umfrage erhalten.',
  'VOTE.STATUS_NOT_STARTED_NOT_REGISTERED_LIVE.LABEL': 'Jetzt registrieren',
  'VOTE.STATUS_NOT_STARTED_NOT_REGISTERED.BUTTON.VOTE': 'Registrierung zur Abstimmung',
  'VOTE.STATUS_NOT_STARTED_NOT_REGISTERED.BUTTON.SURVEY': 'Registrierung zur Umfrage',
  'VOTE.STATUS_NOT_STARTED_REGISTERED.LABEL.VOTE': 'Abstimmung startet am {{dateStart}}. Sie sind registriert und werden benachrichtigt.',
  'VOTE.STATUS_NOT_STARTED_REGISTERED.LABEL.SURVEY': 'Umfrage startet am {{dateStart}}. Sie sind registriert und werden benachrichtigt.',
  'VOTE.STATUS_NOT_STARTED_REGISTERED_LIVE.LABEL.VOTE': 'Abstimmung startet am {{dateStart}}. Sie sind registriert.',
  'VOTE.STATUS_NOT_STARTED_REGISTERED_LIVE.LABEL.SURVEY': 'Umfrage startet am {{dateStart}}. Sie sind registriert.',
  'VOTE.STATUS_NOT_STARTED_REGISTERED.BUTTON': 'Abstimmen',
  'VOTE.STATUS_ACTIVE_NOT_REGISTERED.LABEL': 'Jetzt registrieren und sofort abstimmen.',
  'VOTE.STATUS_ACTIVE_NOT_REGISTERED.BUTTON.VOTE': 'Registrierung zur Abstimmung',
  'VOTE.STATUS_ACTIVE_NOT_REGISTERED.BUTTON.SURVEY': 'Registrierung zur Umfrage',
  'VOTE.STATUS_ACTIVE_NOT_REGISTERED.BUTTON_LIVE.VOTE': 'Registrierung zur Live-Abstimmung',
  'VOTE.STATUS_ACTIVE_NOT_REGISTERED.BUTTON_LIVE.SURVEY': 'Registrierung zur Live-Umfrage',
  'VOTE.STATUS_ACTIVE_REGISTERED.BUTTON': 'Jetzt abstimmen',
  'VOTE.STATUS_COMPLETED.BUTTON': 'Ihre Auswahl ansehen',
  'VOTE.STATUS_WARRANT_COMPLETED.BUTTON': 'Auswahl ansehen',
  'VOTE.STATUS_COMPLETED_LIVE.LABEL': 'Bis zum Ende der Live-Abstimmung können Sie Ihre Auswahl noch ändern.',
  'VOTE.STATUS_COMPLETED_LIVE.BUTTON': 'Auswahl ändern',
  'VOTE.STATUS_COMPLETED_ONLINE_NOW_LIVE.LABEL': 'Sie haben im Rahmen der Online-Vorabstimmung Ihre Stimme eingereicht. Sollten Sie sich dazu entscheiden, an der Live-Abstimmung teilzunehmen, können Sie sich neu registrieren. In dem Fall wird Ihre vorherige Stimmabgabe gelöscht.',
  'VOTE.STATUS_INACTIVE.REGISTER_MODAL.DESCRIPTION': 'Ab dem {{date}} kann hier über die Anträge der "{{title}}" elektronisch abgestimmt werden.',
  'VOTE.STATUS_INACTIVE.REGISTER_MODAL.BOLD_TEXT': 'Erinnerung und Zugangscode via E-Mail erhalten.',
  'VOTE.STATUS_ACTIVE.REGISTER_MODAL.DESCRIPTION': 'Seit dem {{date}} kann hier über die Anträge der "{{title}}" elektronisch abgestimmt werden.',
  'VOTE.STATUS_ACTIVE.REGISTER_MODAL.LIVE.BOLD_TEXT': 'Jetzt registrieren und sofort abstimmen.',
  'VOTE.STATUS_ACTIVE.REGISTER_MODAL.ONLINE.BOLD_TEXT': 'Zugangscode via E-Mail erhalten.',
  'VOTE.CODE_RANGE_VALIDATION': 'Der Code muss eine Zahl zwischen 1000 und 9999 sein.',
  'VOTE.CODE_REQUIRED': 'Bitte geben Sie Ihren Code ein.',
  'VOTE.CODE_INVALID': 'Ungültiger Code. Bitte versuchen Sie es erneut.',
  'VOTE.CODE_MODAL.TITLE': 'Code eingeben',
  'VOTE.CODE_MODAL.DESCRIPTION_1': 'Bitte geben Sie hier Ihren Zugangscode ein.',
  'VOTE.CODE_MODAL.DESCRIPTION_2_LIVE': 'Bei einer Live-Abstimmung erhalten Sie diesen direkt von der Verwaltung.',
  'VOTE.CODE_MODAL.DESCRIPTION_2_ONLINE': 'Sie finden diesen in der E-Mail Benachrichtigung.',
  'VOTE.CODE_MODAL.REQUEST_ACCESS_CODE_AGAIN': 'Zugangscode erneut anfordern',
  'VOTE.CODE_MODAL.REQUEST_ACCESS_CODE_AGAIN.SUCCESS': 'Der Zugangscode wurde Ihnen erneut per E-Mail zugesendet. Schauen Sie bitte ebenfalls in Ihrem Spam-Ordner nach.',
  'VOTE.CODE_MODAL.VOTE_NOW': 'Jetzt abstimmen',
  'VOTE.CODE_MODAL.VOTING_PERIOD.VOTE': 'Die Online-Abstimmung ist bis zum {{date}} verfügbar.',
  'VOTE.CODE_MODAL.VOTING_PERIOD.SURVEY': 'Die Online-Umfrage ist bis zum {{date}} verfügbar.',
  'VOTE.REGISTER_MODAL.UNREGISTERED.TITLE': 'Registrierung',
  'VOTE.REGISTER_MODAL.UNREGISTERED.BUTTON': 'Jetzt registrieren',
  'VOTE.REGISTER_MODAL.REGISTERED.TITLE': 'Registrierung erfolgreich',
  'VOTE.REGISTER_MODAL.START_SOON': 'Nur noch wenige Minuten',
  'VOTE.REGISTER_MODAL.DAYS_UNTIL_START': {
    none: 'Noch {{count}} Tage',
    singular: 'Noch {{count}} Tag',
    plural: 'Noch {{count}} Tage',
  },
  'VOTE.REGISTER_MODAL.HOURS_UNTIL_START': {
    none: 'und {{count}} Stunden',
    singular: 'und {{count}} Stunde',
    plural: 'und {{count}} Stunden',
  },
  'VOTE.REGISTER_MODAL.PART_2.VOTE': 'bis zum Beginn der Abstimmung.',
  'VOTE.REGISTER_MODAL.PART_2.SURVEY': 'bis zum Beginn der Umfrage.',
  'VOTE.WARRANT.LOADING': 'Vollmacht wird geladen...',
  'VOTE.WARRANT.EMAIL_NAME_PLACEHOLDER': 'Nachname / E-Mail eingeben...',
  'VOTE.WARRANT.BANNER': 'Ihnen wurde eine Vollmacht erteilt. Ihre Stimme gilt auch für',
  'VOTE.WARRANT.ASSIGNED_TO': 'Vollmacht vergeben an {{receiver}}',
  'VOTE.WARRANT.SHOW': 'Vollmacht anzeigen',
  'VOTE.WARRANT.REVOKE': 'Vollmacht widerrufen',
  'VOTE.WARRANT.REVOKE_DESCRIPTION': 'Wenn Sie mit OK bestätigen, wird die Vollmacht für {{receiver}} widerrufen.',
  'VOTE.WARRANT_MODAL.STEP_1.DESCRIPTION': 'Geben Sie im Feld unten den Nachname oder die E-Mail-Adresse des Vollmachtempfängers ein. Das System überprüft dann, ob ein Eigentümer mit diesen Daten in Ihrer Liegenschaft existiert.',
  'VOTE.WARRANT_MODAL.STEP_2.DESCRIPTION': 'Der Vollmachtgeber, <b>{{warrantGiverName}}</b>, bevollmächtigt hiermit <b>{{warrantReceiverName}} ({{warrantReceiverEmail}})</b> mit der Vertretung seiner Interessen an der <b>{{voteTitle}}</b>.\n\nDer Bevollmächtigte ist berechtigt, in meinem Namen das Stimmrecht an der vorerwähnten Versammlung auszuüben. Alle Schritte, die der Bevollmächtigte an dieser Versammlung unternimmt, sind für mich rechtsverbindlich.',
  'VOTE.WARRANT_MODAL.NO_ENTRIES': 'Keine Eigentümer gefunden.',
  'VOTE.WARRANT_MODAL.SUBMIT_BUTTON': 'Vollmacht erteilen',
  'VOTE.WARRANT_MODAL.SUCCESS_TEXT': 'Die Vollmacht wurde erfolgreich erteilt. Eine Kopie wurde Ihnen und dem Vollmachtsempfänger per E-Mail zugesendet.',

  'VOTE.REVIEW.SIGNATURE_NOTE': 'Wenn Sie mit einem Touchscreen-Gerät arbeiten, können Sie Ihre Unterschrift per Stift oder Finger in das obige Feld schreiben. Unterstützt Ihr Gerät keine Fingereingabe, können Sie Ihre Signatur mit der Maus zeichnen.',
  'VOTE.REVIEW.LEGAL_NOTE.VOTE': 'Hiermit bevollmächtigen Sie die {{administration}} mit der Vertretung Ihrer Interessen (gemäss obiger Stimmabgabe) an der "{{title}}". Bitte überprüfen Sie Ihre Auswahl sorgfältig. Die hier abgegebene Stimme ist rechtlich verbindlich.',
  'VOTE.REVIEW.LEGAL_NOTE.SURVEY': 'Bitte überprüfen Sie Ihre Auswahl sorgfältig. Die hier abgegebene Stimme kann nicht mehr verändert werden.',
  'VOTE.REVIEW.PLACEHOLDER_VOTE': 'Abstimmung',
  'VOTE.REVIEW.PLACEHOLDER_ADMINISTRATION': 'Verwaltung',
  'VOTE.SUBMIT.SIGNATURE_MISSING': 'Bitte geben Sie zunächst Ihre Unterschrift ein.',
  'VOTE.SUBMIT.SIGNATURE_MISSING_REVIEW': 'Sie finden das Unterschriftenfeld im aktuellen Bildschirm unterhalb von "Ihre Auswahl".',
  'VOTE.SUBMIT.IN_PROGRESS': 'Ihre Auswahl wird übermittelt...',
  'VOTE.SUBMIT.SUCCESS.DEFAULT': 'Ihre Auswahl wurde erfolgreich übermittelt. Eine Kopie wird Ihnen per E-Mail zugesendet.',
  'VOTE.SUBMIT.SUCCESS.WARRANT': 'Ihre Auswahl wurde erfolgreich übermittelt und die Vollmacht erstellt. Eine Kopie wird Ihnen per E-Mail zugesendet.',

  'VOTE.LIST.CONFIRM_VOTES.BUTTON': 'Auswahl prüfen',
  'VOTE.LIST.FOOTER.TEXT_LIVE': 'Ihre Auswahl wird in Echtzeit (LIVE) an die Verwaltung übermittelt. Bis zum Ende jedes Traktandums können Sie Ihre Auswahl noch ändern.',
  'VOTE.LIST.FOOTER.TEXT_ONLINE': 'Bitte stimmen Sie zuerst über alle Anträge ab.',
  'VOTE.LIST.MOTIONS.VOTE': 'Anträge der Abstimmung',
  'VOTE.LIST.MOTIONS.SURVEY': 'Umfragepunkte',
  'VOTE.LIST.NO_MOTIONS_AVAILABLE': 'Keine Anträge vorhanden.',
  'VOTE.LIST.ITEM.INACTIVE': 'Abstimmungspunkt nicht aktiv.',
  'VOTE.LIST.ITEM.PAUSED': 'Pausiert',
  'VOTE.LIST.ITEM.COMPLETED': 'Beendet',

  'VOTE.REVIEW.SIGNATURE': 'Unterschrift',
  'VOTE.REVIEW.YOUR_SELECTION': 'Ihre Auswahl',
  'VOTE.REVIEW.SUBMIT_VOTES': 'Auswahl übermitteln',
  'VOTE.REVIEW.VIEW_SELECTION_MANUAL': 'Ihre Auswahl wurde am {{date}} von der Verwaltung erfasst.',
  'VOTE.REVIEW.VIEW_SELECTION_WARRANT': 'Die Auswahl wurde am {{date}} per Vollmacht übermittelt.',
  'VOTE.REVIEW.VIEW_SELECTION_ONLINE.VOTE': 'Sie haben Ihre Auswahl am {{date}} in der Online-Vorabstimmung übermittelt.',
  'VOTE.REVIEW.VIEW_SELECTION_ONLINE.SURVEY': 'Sie haben Ihre Auswahl am {{date}} in der Online-Umfrage übermittelt.',
  'VOTE.REVIEW.VIEW_SELECTION_LIVE.VOTE': 'Sie haben Ihre Auswahl am {{date}} in der Live-Abstimmung übermittelt.',
  'VOTE.REVIEW.VIEW_SELECTION_LIVE.SURVEY': 'Sie haben Ihre Auswahl am {{date}} in der Live-Umfrage übermittelt.',
  'VOTE.REVIEW.CHANGES_NOT_ALLOWED.VOTE': 'Eine nachträgliche Änderung ist rechtlich nicht gestattet.',
  'VOTE.REVIEW.CHANGES_NOT_ALLOWED.SURVEY': 'Eine nachträgliche Änderung ist nicht gestattet.',

  'DASHBOARD.PROPERTIES.TITLE': 'Immobilien & Kontakte',
  'DASHBOARD.PROPERTIES.CONTACT': 'Kontakt',
  'DASHBOARD.PROPERTIES.CONTACTS': 'Kontakte',
  'DASHBOARD.PROPERTIES.USEFUL_INFORMATION': 'Nützliche Infos',
  'DASHBOARD.PROPERTIES.MANDATE.INACTIVE': 'Inaktiv',

  'DASHBOARD.PROPERTIES.DETAIL.EMAIL': 'E-Mail: ',
  'DASHBOARD.PROPERTIES.DETAIL.COMPANY': 'Firma: ',
  'DASHBOARD.PROPERTIES.DETAIL.TEL_NR': 'Tel-Nr: ',
  'DASHBOARD.PROPERTIES.DETAIL.MISSING_INFORMATION': 'Kontaktinfos fehlen',

  'DASHBOARD.OVERVIEW.HERO.BUTTON.MULTIPLE': 'Immobilien & Kontakte',
  'DASHBOARD.OVERVIEW.HERO.BUTTON.SINGLE': 'Wichtige Kontakte',
  'DASHBOARD.OVERVIEW.REPAIR_REPORT.BUTTON': 'Schadensmeldung',
  'DASHBOARD.OVERVIEW.REPORTS_LIST.TITLE': 'Aktuelles im',
  'DASHBOARD.OVERVIEW.REPORTS_LIST.ALL': 'Alle\nEinträge',
  'DASHBOARD.OVERVIEW.REPORTS_LIST.MINE': 'Meine\nEinträge',
  'DASHBOARD.OVERVIEW.REPORTS_LIST.MESSAGES': 'Nachrichten',
  'DASHBOARD.OVERVIEW.REPORTS_LIST.PINBOARD': 'Pinnwand',
  'DASHBOARD.OVERVIEW.REPORTS_LIST.REPAIRS': 'Reparaturen',
  'DASHBOARD.OVERVIEW.REPORTS_LIST.RESERVATIONS': 'Reservationen',
  'DASHBOARD.OVERVIEW.COMMENT': 'Kommentar',
  'DASHBOARD.OVERVIEW.COMMENTS': 'Kommentare',
  'DASHBOARD.OVERVIEW.ATTACHMENT': 'Anhang',
  'DASHBOARD.OVERVIEW.LOAD_MORE_ENTRIES': 'Weitere Einträge laden',

  'DASHBOARD.REPORT.SUBSCRIBE': 'Sie werden über alle Aktualisierungen und Kommentare benachrichtigt.',
  'DASHBOARD.REPORT.UNSUBSCRIBE': 'Benachrichtungen deaktiviert.',
  'DASHBOARD.REPORT.YOUR_ADMINISTRATION': 'Ihrer Verwaltung',
  'DASHBOARD.REPORT.ADD_STATUS.DESCRIPTION': 'Beschreibung',
  'DASHBOARD.REPORT.ADD_STATUS.TEXT': 'Text',
  'DASHBOARD.REPORT.ADD_STATUS.DESCRIPTION.PLACEHOLDER': 'Beschreibung eingeben',
  'DASHBOARD.REPORT.ADD_STATUS.STATUS': 'Status',
  'DASHBOARD.REPORT.ADD_STATUS.STATUS.PLACEHOLDER': 'Status auswählen',
  'DASHBOARD.REPORT.ADD_STATUS.TITLE': 'Status hinzufügen',
  'DASHBOARD.REPORT.ADD_STATUS.SUCCESS': 'Status wurde erfolgreich zum Ticket hinzugefügt.',
  'DASHBOARD.REPORT.ADD_STATUS.PUSH.DESCRIPTION': 'Soll eine Push-Nachricht verschickt werden? Nur Benutzer die Push-Nachrichten aktiviert haben, erhalten die Nachricht.',
  'DASHBOARD.REPORT.ADD_STATUS.EMAIL.DESCRIPTION': 'Soll eine E-Mail verschickt werden? Nur Benutzer die den E-Mail Versand aktiviert haben, erhalten die E-Mail. (Standardmässig deaktiviert)',
  'DASHBOARD.REPORT.ADD_YAROWA.TITLE': 'Yarowa Prozess erstellen',
  'DASHBOARD.REPORT.ADD_YAROWA.ALERT.MESSAGE': 'Wenn Sie mit OK bestätigen, werden Sie zum Portal der Yarowa AG weitergeleitet und können einen Dienstleister beauftragen.',
  'DASHBOARD.REPORT.PENDING_YAROWA.TITLE': 'Prozess wird erstellt...',
  'DASHBOARD.REPORT.PENDING_YAROWA.DESCRIPTION': 'Bitte schliessen Sie den Prozess auf der Yarowa Seite ab. Sobald der Prozess erstellt wurde, wird dieses Fenster automatisch geschlossen.',
  'DASHBOARD.REPORT.GO_TO_YAROWA.TITLE': 'Yarowa Prozess öffnen',
  'DASHBOARD.REPORT.REMOVE_YAROWA.TITLE': 'Yarowa Prozess entfernen',
  'DASHBOARD.REPORT.REMOVE_YAROWA.ALERT.MESSAGE': 'Wenn Sie mit OK bestätigen, wird die Verbindung zum Yarowa Prozess entfernt. Falls Sie den Prozess komplett löschen möchten, entfernen Sie diesen bitte auch innerhalb der Yarowa Plattform.',
  'DASHBOARD.REPORT.REMOVE_YAROWA.COMPLETED': 'Yarowa Prozess wurde erfolgreich entfernt.',
  'DASHBOARD.REPORT.RECEIVERS.PUBLIC_EXTERNAL_TICKET': 'Externes Ticket: Nur für Bewirtschafter sichtbar, bis eine Immobilie zugeordnet wird.',
  'DASHBOARD.REPORT.RECEIVERS.PRIVATE_EXTERNAL_TICKET': 'Privates externes Ticket: Nur für Bewirtschafter sichtbar, bis eine Immobilie zugeordnet wird.',
  'DASHBOARD.REPORT.RECEIVERS.PRIVATE_INTERNAL_TICKET': 'Privates Ticket: Nur für Hauswart, Bewirtschafter und Absender sichtbar.',
  'DASHBOARD.REPORT.RECEIVERS.PRIVATE_INTERNAL_RESERVATION': 'Private Reservierung: Nur für Bewirtschafter und Absender sichtbar.',
  'DASHBOARD.REPORT.COMMENTS.TITLE': 'Kommentare',
  'DASHBOARD.REPORT.COMMENTS.PLACEHOLDER': 'Neuen Kommentar hier eingeben...',
  'DASHBOARD.REPORT.COMMENTS.SUBMIT': 'Kommentar senden',
  'DASHBOARD.REPORT.COMMENTS.DELETE.HEADER': 'Kommentar löschen',
  'DASHBOARD.REPORT.COMMENTS.DELETE.CONFIRMATION': 'Möchten Sie diesen Kommentar löschen?',
  'DASHBOARD.REPORT.COMMENTS.STATUS.EDITED': 'Editiert',
  'DASHBOARD.REPORT.COMMENTS.NA_YET': 'Es sind noch keine Kommentare erfasst worden.',
  'DASHBOARD.REPORT.COMMENTS.STATUS.DELETED': 'Kommentar wurde gelöscht.',
  'DASHBOARD.REPORT.COMMENTS.CREATE.PROGRESS': 'Kommentar wird übermittelt...',
  'DASHBOARD.REPORT.COMMENTS.CREATE.SUCCESS': 'Kommentar wurde erfolgreich erstellt.',
  'DASHBOARD.REPORT.COMMENTS.EDIT.SUCCESS': 'Kommentar wurde erfolgreich aktualisiert.',
  'DASHBOARD.REPORT.COMMENTS.DELETE.SUCCESS': 'Kommentar wurde erfolgreich gelöscht.',
  'DASHBOARD.REPORT.COMMENTS.RESTORED.SUCCESS': 'Kommentar wurde erfolgreich wiederhergestellt.',
  'DASHBOARD.REPORT.COMMENTS.DISABLED_USER_INFO': 'Kommentare deaktiviert.',
  'DASHBOARD.REPORT.PERSONAL_MESSAGE': 'Persönliche Nachricht',
  'DASHBOARD.REPORT.CONTACT_INFOS_FOR_REPORTING_COMMENT': 'Melden Sie diesen Kommentar der Verwaltung.',
  'DASHBOARD.REPORT.CONTACT_INFOS_ONLY_VISIBLE_FOR_ADMINISTRATION': 'Diese Kontaktinfos sind nur für die Verwaltung sichtbar.',
  'DASHBOARD.REPORT.ERROR.NOT_AVAILABLE': 'Der Eintrag ist nicht verfügbar.',
  'DASHBOARD.REPORT.SHARE': 'Per E-Mail teilen',
  'DASHBOARD.REPORT.SHARE.HEADER': 'Per E-Mail teilen',
  'DASHBOARD.REPORT.SHARE.MESSAGE': 'Wer soll die E-Mail erhalten?',
  'DASHBOARD.REPORT.SHARE.HEADER.EMAIL': 'E-Mail-Addresse',
  'DASHBOARD.REPORT.SHARE.INVALID_EMAIL': 'Ungültige E-Mail-Adresse.',
  'DASHBOARD.REPORT.SHARE.SUCCESS': 'E-Mail wurde erfolgreich versendet.',
  'DASHBOARD.REPORT.SHARE.SEND': 'Senden',
  'DASHBOARD.REPORT.SCREENSHOT_TAKE': 'Screenshot erstellen',
  'DASHBOARD.REPORT.SCREENSHOT_CREATING': 'Screenshot wird erstellt...',

  'LOCATION.ALL_PROPERTIES': 'Alle Liegenschaften',
  'LOCATION.DISABLED.PINBOARD': 'Die Pinnwand ist für diese Immobilie deaktiviert',

  'DOCUMENTS.TITLE': 'Dokumente',
  'DOCUMENTS.SEARCH.TITLE': 'Dokumentensuche',
  'DOCUMENTS.SEARCH.PLACEHOLDER': 'Nach Dokumenten suchen...',
  'DOCUMENTS.SEARCH.TOO_FEW_CHARACTERS': 'Mindestens 3 Zeichen benötigt.',
  'DOCUMENTS.INACTIVE_ONLY_VISIBLE_TO_ADMINS': 'Inaktive Immobilie - Nur sichtbar für Admins',
  'DOCUMENTS.ALL': 'Alle Dokumente',
  'DOCUMENTS.RECENT': 'Kürzlich hinzugefügte Dokumente',
  'DOCUMENTS.NO_ENTRIES': 'Keine Dateien gefunden.',
  'DOCUMENTS.MORE_SEARCH_RESULTS': 'Weitere Suchergebnisse verfügbar. Bitte verfeinern Sie Ihre Suche.',
  'DOCUMENTS.PERSONAL_FOLDER': 'Persönlicher Ordner',
  'DOCUMENTS.DOWNLOAD_ALL.NO_FILES': 'Es befinden sich keine Dateien in diesem Ordner.',
  'DOCUMENTS.DOWNLOAD_ALL.TITLE': 'Alle herunterladen',
  'DOCUMENTS.DOWNLOAD_ALL_FILES.MESSAGE': 'Möchten Sie alle Dateien dieses Ordners herunterladen?',
  'DOCUMENTS.DOWNLOAD_ALL_ATTACHMENTS.MESSAGE': 'Möchten Sie alle Anhänge herunterladen?',

  'PAGE_DENIED.TITLE': 'Kein Zugriff',
  'PAGE_DENIED.SUBTITLE': 'Kein Zugriff',
  'PAGE_DENIED.BACK_HOME': 'Zurück',
  'PAGE_NOT_FOUND.TITLE': 'Seite nicht gefunden',
  'PAGE_NOT_FOUND.SUBTITLE': 'Seite nicht gefunden',
  'PAGE_NOT_FOUND.BACK_HOME': 'Zurück',
  'PAGE_ERROR.TITLE': 'Unerwarteter Fehler',
  'PAGE_ERROR.SUBTITLE': 'Unerwarteter Fehler - Bitte überprüfen Sie Ihre Internetverbindung.',
  'PAGE_ERROR.BACK_HOME': 'Zurück',

  'FILE_LOADER.NEW_PHOTO': 'Ein neues Foto aufnehmen',
  'FILE_LOADER.PHOTOS': {
    none: 'Fotos wählen',
    singular: 'Foto wählen',
    plural: 'Fotos wählen',
  },
  'FILE_LOADER.VIDEOS': 'Videos wählen',
  'FILE_LOADER.DOCUMENTS': 'Dokumente wählen',
  'FILE_LOADER.NOT_SUPPORTED': 'Das Herunterladen von Dateien wird von Ihrem Gerät bzw. Browser nicht unterstützt.',
  'FILE_LOADER.SINGLE.DOWNLOAD_ERROR': 'Die Datei konnte nicht heruntergeladen werden.',
  'FILE_LOADER.SINGLE.DOWNLOAD_SUCCESS': 'Die Datei wurde erfolgreich heruntergeladen.',
  'FILE_LOADER.MULTIPLE.DOWNLOADING': 'Die Dateien werden heruntergeladen...',
  'FILE_LOADER.MULTIPLE.DOWNLOAD_ERROR': 'Die Dateien konnten nicht heruntergeladen werden.',
  'FILE_LOADER.MULTIPLE.DOWNLOAD_SUCCESS': 'Die Dateien wurden erfolgreich heruntergeladen.',
  'FILE_LOADER.NO_FILE_SELECTED': 'Keine Datei ausgewählt.',
  'FILE_LOADER.MAX_1_VIDEO': 'Maximal 1 Video erlaubt.',
  'FILE_LOADER.MAX_MB': 'Datei ist zu groß. Max: {{size}} MB.',
  'FILE_LOADER.LIMIT_TO_X': {
    none: 'Bitte nicht mehr als {{count}} Dateien auswählen.',
    singular: 'Bitte nicht mehr als {{count}} Datei auswählen.',
    plural: 'Bitte nicht mehr als {{count}} Dateien auswählen.',
  },

  'FILE_VIEWER.ERROR.SHARE_NOT_SUPPORTED': 'Die Teilen-Funktionalität wird nicht unterstützt.',
  'FILE_VIEWER.ERROR.FILE_CANNOT_BE_SHARED': 'Die Datei kann nicht geteilt werden.',
  'FILE_VIEWER.ERROR.FILE_CANNOT_BE_DISPLAYED': 'Die Datei kann nicht angezeigt werden.',
  'FILE_VIEWER.ERROR.FILE_CANNOT_BE_DISPLAYED_OR_DOWNLOADED': 'Die Datei kann nicht angezeigt oder heruntergeladen werden.',
  'FILE_VIEWER.X_RESULTS': {
    none: 'Keine Ergebnisse',
    singular: '{{count}} Ergebnis',
    plural: '{{count}} Ergebnisse',
  },
  'FILE_VIEWER.FILE_IS_LOADING': 'Die Datei wird geladen...',

  'IMAGE_ALT.APP.LOGO': 'App logo',
  'IMAGE_ALT.DASHBOARD_HEADER.BACKGROUND': 'Immobilie',
  'IMAGE_ALT.USER': 'Angemeldeter Benutzer',
  'IMAGE_ALT.REPORT.COMMENT.OWNER': 'Absender des Kommentars',
  'IMAGE_ALT.PROFILE.USER': 'Benutzerprofil',
  'IMAGE_ALT.SIGN_IN': 'Loginseite',
  'IMAGE_ALT.SIGN_UP': 'Anmeldeseite',
  'IMAGE_ALT.VOTE.REGISTER_MODAL': 'Registrieren Sie sich, um abzustimmen',

  'INFOCENTER.SEGMENT.TICKET': 'Anliegen melden',
  'INFOCENTER.SEGMENT.FAQS': 'FAQs',
  'INFOCENTER.SEGMENT.DOCUMENTS': 'Dokumente',
  'INFOCENTER.WEBSITE.TITLE': 'Besuchen Sie die {{adminName}} Webseite',
  'INFOCENTER.WEBSITE.TEXT': 'Besuchen Sie unsere Webseite für alle weiteren Informationen rund um {{adminName}}.',
  'INFOCENTER.WEBSITE.BUTTON': 'Zur Webseite',
  'INFOCENTER.LOAD.TITLE': 'Informiert bleiben mit der ImmoApp',
  'INFOCENTER.LOAD.TEXT': 'Mit der ImmoApp bleiben Sie stets über alle Neuigkeiten zu Ihrer Liegenschaft bestens informiert. Zudem sehen Sie alle von Ihnen übermittelten Meldungen mit dem aktuellen Status.',
  'INFOCENTER.FAQ.SECTION.ADD': 'Neue Sektion',
  'INFOCENTER.FAQ.SECTION.TITLE': 'Neue Sektion',
  'INFOCENTER.FAQ.ITEM.HEAD_TITLE': 'Neues Element Kopf',
  'INFOCENTER.FAQ.ITEM.BODY_TITLE': 'Neues Element Körper',
  'INFOCENTER.FAQ.SECTION.TRANSLATE_DE': 'Übersetzen in Deutsch',
  'INFOCENTER.FAQ.SECTION.TRANSLATE_EN': 'Übersetzen in Englisch',
  'INFOCENTER.FAQ.SECTION.TRANSLATE_FR': 'Übersetzen in Französisch',
  'INFOCENTER.FAQ.SECTION.TRANSLATE_IT': 'Übersetzen in Italienisch',
  'INFOCENTER.FAQ.SECTION.DELETED': 'FAQ-Abschnitt gelöscht.',
  'INFOCENTER.FAQ.DATA_UPDATED': 'FAQs wurden erfolgreich aktualisiert.',
  'INFOCENTER.FAQ.DELETE_POPOVER.TITLE': 'Löschen nicht möglich',
  'INFOCENTER.FAQ.DELETE_POPOVER.CONTENT': 'Bitte löschen Sie zunächst alle Elemente in diesem Abschnitt.',
  'INFOCENTER.DOCUMENTS.NONE': 'Keine Dokumente vorhanden.',
  'INFOCENTER.TRANSLATE_MODAL.TITLE': 'Übersetzen von Texten',
  'INFOCENTER.TRANSLATE_MODAL.SUBTITLE': 'Aktuelle Übersetzung',
  'INFOCENTER.TRANSLATE_MODAL.INPUT_PLACEHOLDER': 'Übersetzung eingeben',
  'INFOCENTER.TRANSLATE_MODAL.DELETE': 'Übersetzung löschen',
  'INFOCENTER.NOT_ACTIVATED_YET': 'Das Infocenter ist für Ihre Verwaltung noch nicht aktiviert.',

  STARTING_BACKEND_KEYS: '---',

  'PUSH.NEW_ASSIGNMENT': 'Neue Auftragserteilung',
  'PUSH.NEW_COMMENT': 'Neuer Kommentar',
  'PUSH.NEW_MANUAL_REGISTRATION': 'Neue manuelle Registrierung',
  'PUSH.STATUS': 'Status',
  'PUSH.MESSAGE': 'Nachricht',
  'PUSH.REPAIR': 'Schadensmeldung',
  'PUSH.KEYORDER': 'Schlüssel- oder Kartenbestellung',
  'PUSH.NAMEPLATE': 'Namensschildbestellung',
  'PUSH.DOCUMENT': 'Dokument- oder Formularbestellung',
  'PUSH.OTHER': 'Sonstige Mitteilung',
  'PUSH.PINBOARD_ENTRY': 'Pinnwandeintrag',
  'PUSH.RESERVATION': 'Reservierungseingang',
  'PUSH.NEW_VOTE_ITEM': 'Neues Traktandum',
  'PUSH.NEW_DOCUMENTS': 'Neue Dokumente',
  'PUSH.NEW_DOCUMENTS_REMAINING_one': '+ {{count}} weiteres Dokument',
  'PUSH.NEW_DOCUMENTS_REMAINING_other': '+ {{count}} weitere Dokumente',
  'PUSH.APPROVAL': 'Freigabe',
  'PUSH.VOTE': 'Abstimmung',
  'PUSH.NEW_VOTE': 'Neue Abstimmung',
  'PUSH.SURVEY': 'Umfrage',
  'PUSH.VOTE_SUBMITTED_FROM': 'Stimmabgabe von',
  'PUSH.VOTE_AVAILABLE_NOW': 'Die Abstimmung "{{voteTitle}}" ist ab jetzt verfügbar. Geben Sie Ihre Stimme digital in der ImmoApp ab.',

  'EMAIL.HEADER.GREETING': 'Sehr geehrte/r {{name}}',
  'EMAIL.HEADER.ACCOUNT_ACTIVATED': 'Ihr Account wurde freigeschaltet',
  'EMAIL.HEADER.INVITED_BY_OWNER': 'Ihr Vermieter hat Sie eingeladen',
  'EMAIL.HEADER.IMMOAPP_ACCESS': 'Die {{appName}} (Zugang und Anleitung)',
  'EMAIL.HEADER.ADMIN_ACCESS': 'Zugang zum Verwaltungsbereich',
  'EMAIL.HEADER.NEW_PRIVATE_REPAIR': 'Neue private Schadensmeldung',
  'EMAIL.HEADER.NEW_MESSAGE': 'Neue Nachricht',
  'EMAIL.HEADER.NEW_REPAIR': 'Neue Schadensmeldung',
  'EMAIL.HEADER.NEW_KEYORDER': 'Neue Schlüssel- oder Kartenbestellung',
  'EMAIL.HEADER.NEW_DOCUMENT': 'Neue Dokument- oder Formularbestellung',
  'EMAIL.HEADER.NEW_OTHER': 'Neue sonstige Mitteilung',
  'EMAIL.HEADER.NEW_NAMEPLATE': 'Neue Namensschildbestellung',
  'EMAIL.HEADER.NEW_PINBOARD': 'Neuer Pinnwandeintrag',
  'EMAIL.HEADER.NEW_VOTEITEM': 'Neues Traktandum',
  'EMAIL.HEADER.NEW_RESERVATION': 'Neue Reservation',
  'EMAIL.HEADER.FORWARDED_MESSAGE': 'Weitergeleitete Nachricht',
  'EMAIL.HEADER.FORWARDED_REPAIR': 'Weitergeleitete Schadensmeldung',
  'EMAIL.HEADER.FORWARDED_KEYORDER': 'Weitergeleitete Schlüssel- oder Kartenbestellung',
  'EMAIL.HEADER.FORWARDED_DOCUMENT': 'Weitergeleitete Dokument- oder Formularbestellung',
  'EMAIL.HEADER.FORWARDED_OTHER': 'Weitergeleitete sonstige Mitteilung',
  'EMAIL.HEADER.FORWARDED_NAMEPLATE': 'Weitergeleitete Namensschildbestellung',
  'EMAIL.HEADER.FORWARDED_PINBOARD': 'Weitergeleiteter Pinnwandeintrag',
  'EMAIL.HEADER.FORWARDED_VOTEITEM': 'Weitergeleitetes Traktandum',
  'EMAIL.HEADER.FORWARDED_RESERVATION': 'Weitergeleitete Reservation',
  'EMAIL.HEADER.REPAIR_UPDATED': 'Schadensmeldung "{{entryTitle}}" in {{locationName}} wurde auf "{{status}}" aktualisiert',
  'EMAIL.HEADER.RESERVATION_UPDATED': '"{{entryTitle}}" in {{locationName}} wurde auf "{{status}}" aktualisiert',
  'EMAIL.HEADER.KEYORDER_UPDATED': 'Schlüssel- oder Kartenbestellung "{{entryTitle}}" von {{senderName}} wurde auf "{{status}}" aktualisiert',
  'EMAIL.HEADER.NAMEPLATE_UPDATED': 'Namensschildbestellung "{{entryTitle}}" von {{senderName}} wurde auf "{{status}}" aktualisiert',
  'EMAIL.HEADER.DOCUMENT_UPDATED': 'Dokument- oder Formularbestellung "{{entryTitle}}" von {{senderName}} wurde auf "{{status}}" aktualisiert',
  'EMAIL.HEADER.OTHER_UPDATED': 'Sonstige Mitteilung "{{entryTitle}}" von {{senderName}} wurde auf "{{status}}" aktualisiert',
  'EMAIL.HEADER.NEW_COMMENT_MESSAGE': 'Neuer Kommentar zur Nachricht',
  'EMAIL.HEADER.NEW_COMMENT_REPAIR': 'Neuer Kommentar zur Schadensmeldung',
  'EMAIL.HEADER.NEW_COMMENT_KEYORDER': 'Neuer Kommentar zur Schlüssel- oder Kartenbestellung',
  'EMAIL.HEADER.NEW_COMMENT_NAMEPLATE': 'Neuer Kommentar zur Namensschildbestellung',
  'EMAIL.HEADER.NEW_COMMENT_DOCUMENT': 'Neuer Kommentar zur Dokument- oder Formularbestellung',
  'EMAIL.HEADER.NEW_COMMENT_OTHER': 'Neuer Kommentar zur sonstigen Mitteilung',
  'EMAIL.HEADER.NEW_COMMENT_PINBOARD': 'Neuer Kommentar zum Pinnwandeintrag',
  'EMAIL.HEADER.NEW_COMMENT_RESERVATION': 'Neuer Kommentar zu',
  'EMAIL.HEADER.ACCESS_CODE_FOR.VOTE': 'Ihr Zugangscode für die Abstimmung',
  'EMAIL.HEADER.ACCESS_CODE_FOR.SURVEY': 'Ihr Zugangscode für die Umfrage',
  'EMAIL.HEADER.WARRANT.GIVEN': 'Vergebene Vollmacht für die Abstimmung',
  'EMAIL.HEADER.WARRANT.RECEIVED': 'Erhaltene Vollmacht für die Abstimmung',
  'EMAIL.HEADER.VOTE_RESULTS.DEFAULT': 'Ihre Stimmabgabe für',
  'EMAIL.HEADER.VOTE_RESULTS.WARRANT': 'Ihre Vollmacht für',
  'EMAIL.HEADER.INVITE.FUTURE': 'Admin-Update ImmoApp: Benutzer werden in Kürze eingeladen',
  'EMAIL.HEADER.INVITE.PAST': 'Admin-Update ImmoApp: Benutzer wurden eingeladen',
  'EMAIL.HEADER.EXTERNAL': 'Neue Nachricht aus der ImmoApp',
  'EMAIL.HEADER.EMAIL_CHANGE': 'Sicherheitshinweis: E-Mail-Adresse wurde geändert',
  'EMAIL.HEADER.PASSWORD_CHANGE': 'Sicherheitshinweis: Passwort wurde geändert',

  'EMAIL.CONTENT.ACCOUNT_ACTIVATED': 'Ihr Account für die {{appName}} wurde freigeschaltet.',
  'EMAIL.CONTENT.DIRECT_ACCOUNT_LOGIN': 'Sie können sich jetzt mit Ihrer E-Mail-Adresse und dem von Ihnen gewählten Passwort einloggen.',
  'EMAIL.CONTENT.VERIFY_ACCOUNT_LOGIN_1': 'Bitte bestätigen Sie noch Ihre E-Mail-Adresse in dem Sie auf den folgenden Link klicken',
  'EMAIL.CONTENT.VERIFY_ACCOUNT_LOGIN_2A': 'Danach können Sie sich mit Ihrer E-Mail-Adresse und dem von Ihnen gewählten Passwort einloggen.',
  'EMAIL.CONTENT.VERIFY_ACCOUNT_LOGIN_2B': 'Danach können Sie sich mit Ihrer E-Mail-Adresse und dem folgenden Passwort einloggen',
  'EMAIL.CONTENT.IMMOAPP_AVAILABILITY': 'Die ImmoApp ist kostenlos in den App Stores und als Web-Applikation erhältlich',
  'EMAIL.CONTENT.WEB_BROWSER': 'Web / Browser',
  'EMAIL.CONTENT.IMMOAPP_INTRODUCTION': 'Hier finden Sie eine kleine Einführung in die ImmoApp',
  'EMAIL.CONTENT.INVITED_BY_OWNER': 'Ihr Vermieter hat Sie zur Nutzung der {{appName}} eingeladen.',
  'EMAIL.CONTENT.IMMOAPP_LINKS': 'Sie können die ImmoApp über folgende Links kostenlos nutzen',
  'EMAIL.CONTENT.IMMOAPP_AVAILABLE': 'Ab sofort steht Ihnen die {{appName}} für Ihr Mobiltelefon, Tablet und Computer zur Verfügung.',
  'EMAIL.CONTENT.FUNCTIONALITIES_INTRO': 'Mittels der App können Sie ab sofort jederzeit',
  'EMAIL.CONTENT.FUNCTIONALITIES_REPAIRS': 'Schadensmeldungen erfassen',
  'EMAIL.CONTENT.FUNCTIONALITIES_KEYORDER': 'Schlüssel oder Zugangskarte bestellen',
  'EMAIL.CONTENT.FUNCTIONALITIES_NAMEPLATE': 'Namensschild bestellen',
  'EMAIL.CONTENT.FUNCTIONALITIES_DOCUMENTORDER': 'Dokumente und Formulare bestellen',
  'EMAIL.CONTENT.FUNCTIONALITIES_NEWS': 'Neuigkeiten rund um Ihre Liegenschaft erhalten',
  'EMAIL.CONTENT.FUNCTIONALITIES_PINBOARD': 'Pinnwandeinträge für Ihre Nachbarn verfassen',
  'EMAIL.CONTENT.FUNCTIONALITIES_DOCUMENTS': 'Dokumente der Liegenschaft online einsehen',
  'EMAIL.CONTENT.FUNCTIONALITIES_VOTES': 'An Eigentümerversammlungen teilnehmen',
  'EMAIL.CONTENT.FUNCTIONALITIES_TENANTS': 'Mieter selbstständig zur ImmoApp hinzufügen und entfernen. Die Mieter erhalten dadurch einen beschränkten Zugang zu Neuigkeiten, Schadensmeldungen, Pinnwandeinträgen und ausgewählten Dokumenten.',
  'EMAIL.CONTENT.ACCOUNT_ALREADY_CREATED': 'Es wurde bereits ein Account für Sie erstellt. Sie können sich mit folgenden Login-Daten anmelden',
  'EMAIL.CONTENT.RECOMMENDATION_PASSWORD_CHANGE': 'Wir empfehlen, dass Sie Ihr Passwort bei der nächsten Anmeldung über den Menüpunkt "Einstellungen" abändern.',
  'EMAIL.CONTENT.SEND_EMAIL_TO': 'Senden Sie bitte eine E-Mail an {{contact}}',
  'EMAIL.CONTENT.INVITED_TO_ADMIN_AREA': 'Sie wurden zum Verwaltungsbereich der {{appName}} eingeladen.',
  'EMAIL.CONTENT.ADMIN_LOGIN': 'Sie können sich mit folgenden Login-Daten im Verwaltungsbereich und der ImmoApp (siehe unten) anmelden',
  'EMAIL.CONTENT.ADMIN_SALUTATION': 'Hallo Admin',
  'EMAIL.CONTENT.HOW_TO_CHANGE_PASSWORD_Q': 'Wie kann ich mein Passwort ändern?',
  'EMAIL.CONTENT.HOW_TO_CHANGE_PASSWORD_A': 'Dies kann in der App über den Menüpunkt "Einstellungen" und anschliessende Auswahl von "Passwort zurücksetzen" erfolgen. Wir empfehlen, dass Sie Ihr Passwort bei der nächsten Anmeldung abändern.',
  'EMAIL.CONTENT.NEW_MESSAGE': 'Es wurde eine neue Nachricht erfasst.',
  'EMAIL.CONTENT.NEW_REPAIR': 'Es wurde eine neue Schadensmeldung erfasst.',
  'EMAIL.CONTENT.NEW_KEYORDER': 'Es wurde eine neue Schlüssel- oder Kartenbestellung erfasst.',
  'EMAIL.CONTENT.NEW_NAMEPLATE': 'Es wurde eine neue Namensschildbestellung erfasst.',
  'EMAIL.CONTENT.NEW_DOCUMENT': 'Es wurde eine neue Dokument- oder Formularbestellung erfasst.',
  'EMAIL.CONTENT.NEW_OTHER': 'Es wurde eine neue sonstige Mitteilung erfasst.',
  'EMAIL.CONTENT.NEW_PINBOARD': 'Es wurde ein neuer Pinnwandeintrag erfasst.',
  'EMAIL.CONTENT.NEW_VOTEITEM': 'Es wurde ein neues Traktandum erfasst. Sie können dies in der Admin-App ganz einfach zu einer bestehenden oder neuen Abstimmung hinzufügen.',
  'EMAIL.CONTENT.NEW_RESERVATION': 'Es wurde eine neue Reservation erstellt.',
  'EMAIL.CONTENT.EXTERNAL.SUBMITTED': 'Der Eintrag wurde über das öffentlich verfügbare Kontaktformular eingereicht.',
  'EMAIL.CONTENT.EXTERNAL.VISIBLE': 'Nur für Bewirtschafter sichtbar, bis eine Immobilie zugeordnet wird.',
  'EMAIL.CONTENT.FORWARDED_MESSAGE': 'Es wurde Ihnen eine Nachricht aus der ImmoApp weitergeleitet.',
  'EMAIL.CONTENT.FORWARDED_REPAIR': 'Es wurde Ihnen eine Schadensmeldung aus der ImmoApp weitergeleitet.',
  'EMAIL.CONTENT.FORWARDED_KEYORDER': 'Es wurde Ihnen eine Schlüssel- oder Kartenbestellung aus der ImmoApp weitergeleitet.',
  'EMAIL.CONTENT.FORWARDED_NAMEPLATE': 'Es wurde Ihnen eine Namensschildbestellung aus der ImmoApp weitergeleitet.',
  'EMAIL.CONTENT.FORWARDED_DOCUMENT': 'Es wurde Ihnen eine Dokument- oder Formularbestellun aus der ImmoApp weitergeleitet.',
  'EMAIL.CONTENT.FORWARDED_OTHER': 'Es wurde Ihnen eine sonstige Mitteilung aus der ImmoApp weitergeleitet.',
  'EMAIL.CONTENT.FORWARDED_PINBOARD': 'Es wurde Ihnen ein Pinnwandeintrag aus der ImmoApp weitergeleitet.',
  'EMAIL.CONTENT.FORWARDED_VOTEITEM': 'Es wurde Ihnen ein Traktandum aus der ImmoApp weitergeleitet.',
  'EMAIL.CONTENT.FORWARDED_RESERVATION': 'Es wurde eine Reservation aus der ImmoApp an Sie weitergeleitet.',
  'EMAIL.CONTENT.REPAIR_UPDATED': 'Die Schadensmeldung "{{entryTitle}}" wurde aktualisiert.',
  'EMAIL.CONTENT.RESERVATION_UPDATED': 'Die "{{entryTitle}}" wurde aktualisiert.',
  'EMAIL.CONTENT.KEYORDER_UPDATED': 'Die Schlüssel- oder Kartenbestellung "{{entryTitle}}" wurde aktualisiert.',
  'EMAIL.CONTENT.NAMEPLATE_UPDATED': 'Die Namensschildbestellung "{{entryTitle}}" wurde aktualisiert.',
  'EMAIL.CONTENT.DOCUMENT_UPDATED': 'Die Dokument- oder Formularbestellung "{{entryTitle}}" wurde aktualisiert.',
  'EMAIL.CONTENT.OTHER_UPDATED': 'Die sonstige Mitteilung "{{entryTitle}}" wurde aktualisiert.',
  'EMAIL.CONTENT.NEW_STATUS': 'Neuer Status',
  'EMAIL.CONTENT.NO_DESCRIPTION': 'Ohne Beschreibung',
  'EMAIL.CONTENT.INITIAL_SENDER': 'Ursprünglicher Absender',
  'EMAIL.CONTENT.NEW_COMMENT_MESSAGE': 'Es wurde eine neuer Kommentar zur Nachricht "{{entryTitle}}" erfasst.',
  'EMAIL.CONTENT.NEW_COMMENT_REPAIR': 'Es wurde eine neuer Kommentar zur Schadensmeldung "{{entryTitle}}" erfasst.',
  'EMAIL.CONTENT.NEW_COMMENT_KEYORDER': 'Es wurde eine neuer Kommentar zur Schlüssel- oder Kartenbestellung "{{entryTitle}}" erfasst.',
  'EMAIL.CONTENT.NEW_COMMENT_NAMEPLATE': 'Es wurde eine neuer Kommentar zur Namensschildbestellung "{{entryTitle}}" erfasst.',
  'EMAIL.CONTENT.NEW_COMMENT_DOCUMENT': 'Es wurde eine neuer Kommentar zur Dokument- oder Formularbestellung "{{entryTitle}}" erfasst.',
  'EMAIL.CONTENT.NEW_COMMENT_OTHER': 'Es wurde eine neuer Kommentar zur sonstigen Mitteilung "{{entryTitle}}" erfasst.',
  'EMAIL.CONTENT.NEW_COMMENT_PINBOARD': 'Es wurde eine neuer Kommentar zum Pinnwandeintrag "{{entryTitle}}" erfasst.',
  'EMAIL.CONTENT.NEW_COMMENT_RESERVATION': 'Es wurde eine neuer Kommentar zu "{{entryTitle}}" erfasst.',
  'EMAIL.CONTENT.NOW_AVAILABLE.VOTE': 'Die Abstimmung "{{title}}" ist jetzt verfügbar.',
  'EMAIL.CONTENT.NOW_AVAILABLE.SURVEY': 'Die Umfrage "{{title}}" ist jetzt verfügbar.',
  'EMAIL.CONTENT.VOTE_WITH_CODE': 'Sie können ab sofort mit folgendem Zugangscode abstimmen',
  'EMAIL.CONTENT.SUBMIT_THANK_YOU.VOTE': 'Besten Dank für Ihre Teilnahme an der Abstimmung',
  'EMAIL.CONTENT.SUBMIT_THANK_YOU.SURVEY': 'Besten Dank für Ihre Teilnahme an der Umfrage',
  'EMAIL.CONTENT.SUBMIT_RESULTS.DEFAULT': 'Angehängt finden Sie eine Kopie Ihrer Stimmabgabe.',
  'EMAIL.CONTENT.SUBMIT_RESULTS.WARRANT': 'Angehängt finden Sie eine Kopie Ihrer Vollmacht.',
  'EMAIL.CONTENT.VOTE_RESULT_PDF_TITLE.DEFAULT': 'Stimmzettel',
  'EMAIL.CONTENT.VOTE_RESULT_PDF_TITLE.WARRANT': 'Vollmacht',
  'EMAIL.CONTENT.KEYORDER.QUANTITY': 'Anzahl',
  'EMAIL.CONTENT.KEYORDER.LABEL': 'Schlüsselbezeichnung',
  'EMAIL.CONTENT.NAMEPLATE.ROW1': 'Zeile 1',
  'EMAIL.CONTENT.NAMEPLATE.ROW2': 'Zeile 2',
  'EMAIL.CONTENT.ATTACHMENTS_REMOVED': 'Die vom Absender hinzugefügten Anhänge überschreiten das Limit von 25 MB und wurden daher aus dieser E-Mail entfernt. Sie können die Anhänge in der ImmoApp einsehen.',
  'EMAIL.CONTENT.WARRANT.GIVEN': 'Sie haben eine Vollmacht an {{warrantPartnerName}} ({{warrantPartnerEmail}}) für die Abstimmung "{{voteTitle}}" ({{voteMandateName}}) vergeben.',
  'EMAIL.CONTENT.WARRANT.RECEIVED': 'Sie haben eine Vollmacht von {{warrantPartnerName}} ({{warrantPartnerEmail}}) für die Abstimmung "{{voteTitle}}" ({{voteMandateName}}) erhalten.',
  'EMAIL.CONTENT.WARRANT.ATTACHED_COPY': 'Angehängt finden Sie eine Kopie der Vollmacht.',
  'EMAIL.CONTENT.INVITE.FUTURE.1': 'Die folgende Liste zeigt alle {{quantity}} Benutzer welche um {{time}} (CET) eingeladen werden.',
  'EMAIL.CONTENT.INVITE.FUTURE.2': 'Hinweis: Falls Sie dies nicht wünschen, können Sie den automatischen Versand der Einladungen in der WebApp unter "Plattform verwalten" > "ImmoApp" > "ERP-Verbindung" > "Benutzer automatisch erstellen" deaktivieren. Bitte kontaktieren Sie in diesem Fall auch den Tech Support der ImmoDigi AG, damit nötige Anpassungen durchgeführt werden können.',
  'EMAIL.CONTENT.INVITE.PAST.1': 'Die folgende Liste zeigt alle {{quantity}} Benutzer welche eben erfolgreich erstellt und eingeladen wurden.',
  'EMAIL.CONTENT.INVITE.SKIPPED': '(Es wurden {{quantity}} Benutzer übersprungen. Dieses Limit existiert um mögliche Fehler beim Import zu vermeiden. Führen Sie die Aktion erneut in der Web-App aus, damit die restlichen Benutzer ebenfalls eine Einladung erhalten.)',
  'EMAIL.CONTENT.EXTERNAL': 'Ihre Verwaltung hat eine neue Nachricht in der ImmoApp erfasst.',
  'EMAIL.CONTENT.PREVIEW': 'Hinweis: Diese E-Mail dient lediglich als Vorschau und enthält daher keine Anhänge.',
  'EMAIL.CONTENT.TITLE_OF_MESSAGE': 'Titel der Nachricht',
  'EMAIL.CONTENT.TICKET_ASSIGNMENT1': 'Sie haben einen neuen Auftrag von {{administrationName}} zugeteilt bekommen.',
  'EMAIL.CONTENT.TICKET_ASSIGNMENT2': 'Klicken Sie {{link}} um den Auftrag in der ImmoApp zu öffnen.',
  'EMAIL.CONTENT.EMAIL_CHANGE_1': 'Wir möchten Sie darüber informieren, dass Ihre E-Mail-Adresse für Ihr ImmoApp-Konto eben auf "{{newEmail}}" geändert wurde.',
  'EMAIL.CONTENT.PASSWORD_CHANGE_1': 'Wir möchten Sie darüber informieren, dass Ihr Passwort für Ihr ImmoApp-Konto eben geändert wurde.',
  'EMAIL.CONTENT.EMAIL_PASSWORD_CHANGE_2': 'Wenn Sie diese Änderung nicht durchgeführt haben, setzen Sie sich bitte umgehend mit unserem Support-Team unter {{supportEmail}} in Verbindung.',
  'EMAIL.CONTENT.EMAIL_PASSWORD_CHANGE_3': 'Vielen Dank, dass Sie auf die Sicherheit Ihres ImmoApp-Kontos achten.',
  'EMAIL.CONTENT.RESERVATION.DETAILS_TITLE': 'Reservationsdetails',
  'EMAIL.CONTENT.RESERVATION.DETAILS_DESCRIPTION': '{{place}} reserviert am {{date}} von {{time_start}} bis {{time_end}}.',

  'EMAIL.FOOTER.UNSUBSCRIBE.INTRO': 'Diese E-Mail wird automatisch an Benutzer der ImmoApp im Auftrag von {{contact}} versendet.',
  'EMAIL.FOOTER.UNSUBSCRIBE.PUBLIC': 'Falls Sie diese E-Mails nicht mehr erhalten möchten, schreiben Sie uns an: {{contact}}.',
  'EMAIL.FOOTER.UNSUBSCRIBE.SETTINGS': 'Falls Sie diese E-Mails nicht mehr erhalten möchten, können Sie dies in den Einstellungen der {{immoappLink}} deaktivieren.',
  'EMAIL.FOOTER.GREETING': 'Freundliche Grüsse',
  'EMAIL.FOOTER.LEGAL': 'Software bereitgestellt von ImmoDigi AG.',
  'EMAIL.FOOTER.EXTERNAL': 'Öffnen Sie die Nachricht jetzt in der ImmoApp. Mit der ImmoApp erhalten Sie alle Neuigkeiten aus Ihrer Liegenschaft direkt auf Ihr Smartphone. Sie können die ImmoApp im {{appStoreLink}} und im {{playStoreLink}} herunterladen oder direkt im {{browserLink}} öffnen. Sie haben von Ihrer Verwaltung bereits Ihre persönlichen Zugangsdaten via E-Mail erhalten. Sollten Sie diese nicht mehr besitzen, können Sie die "Passwort-Zurücksetzen"-Funktion in der ImmoApp nutzen.<br/>Falls Sie diese E-Mails nicht mehr erhalten möchten, melden Sie sich bitte in der ImmoApp an und ändern Sie die Benachrichtigungseinstellungen.',

  'INTRO_PDF.TITLE': 'Einführung in die ImmoApp',
  'INTRO_PDF.WELCOME.TITLE': 'Einleitung',
  'INTRO_PDF.WELCOME.TEXT': 'Willkommen und vielen Dank für Ihr Interesse an der ImmoApp. Im folgenden Dokument finden Sie zahlreiche Informationen und Erklärungen für einen erfolgreichen Einstieg in die ImmoApp. Falls Sie irgendwelche Fragen haben sollten, stehen wir Ihnen gerne jederzeit zur Verfügung',
  'INTRO_PDF.SEE_VIDEO': 'Für eine ganz kurzen Überblick können Sie sich auch gerne unser anderthalb-minütiges Infovideo anschauen',
  'INTRO_PDF.DISCOVER_IMMOAPP': 'Die ImmoApp entdecken',
  'INTRO_PDF.DOWNLOAD_IMMOAPP': 'ImmoApp herunterladen und starten',
  'INTRO_PDF.LINKS.INTRO': 'Sie können die ImmoApp über folgende Links nutzen',
  'INTRO_PDF.LINKS.IOS': 'Für iOS',
  'INTRO_PDF.LINKS.ANDROID': 'Für Android',
  'INTRO_PDF.LINKS.BROWSER': 'Für Web / Browser',
  'INTRO_PDF.START_LOGIN': 'Starten Sie die ImmoApp und loggen Sie sich mit den Zugangsdaten ein, die wir Ihnen per E-Mail zugesendet haben.',
  'INTRO_PDF.HOME.TITLE': 'Startbildschirm',
  'INTRO_PDF.HOME.OVERVIEW': 'Sobald Sie die ImmoApp gestartet haben, sehen Sie die Startseite mit folgenden Bereichen',
  'INTRO_PDF.HOME.A.TEXT': 'A) Der Startbereich zeigt Ihr Profilbild links, sowie ein Infotext und Bild zu einer Ihrer Immobilien.',
  'INTRO_PDF.HOME.A.CLICK1': 'Per Klick auf den runden weißen Button gelangen Sie zu einer Übersicht aller Ihrer Immobilien.',
  'INTRO_PDF.HOME.A.CLICK2': 'Per Klick auf den Button "Schadensmeldung" öffnet sich ein neues Fenster zur Eingabe der Schadensmeldung.',
  'INTRO_PDF.HOME.B.TITLE': 'B) Alle Nachrichten, Pinnwandeinträge und Schadensmeldungen, sortiert nach Monaten.',
  'INTRO_PDF.HOME.B.CLICK': 'Per Klick auf einen Eintrag öffnet sich eine neue Ansicht mit weiteren Details.',
  'INTRO_PDF.IMPORTANT_CONTACTS.TITLE': 'Wichtige Kontakte',
  'INTRO_PDF.IMPORTANT_CONTACTS.TEXT': 'Per Klick auf den runden weißen Button aus A) gelangen Sie zu einer Übersicht aller Ihrer Immobilien. Wählen Sie eine Immobilie aus und Sie gelangen auf eine Detailseite wo alle wichtigen Kontaktdaten aufgelistet sind.',
  'INTRO_PDF.REPORT.TITLE': 'Detailansicht einer Meldung',
  'INTRO_PDF.REPORT.TEXT_1': 'Klicken Sie nun auf einen Eintrag aus dem Startbildschirm, um die Detailansicht zu sehen.',
  'INTRO_PDF.REPORT.TEXT_2': 'Im oberen Bereich sehen Sie die angehängten Bilder, falls welche vorhanden sind. Per Swipe nach links und rechts, können Sie weitere Bilder einsehen.',
  'INTRO_PDF.REPORT.TEXT_3': 'Falls es sich um ein Ticket handelt, wird in einer übersichtlichen Historie alle bisherigen Fortschritte angezeigt: von der Erstellung bis zum erfolgreichen Abschluss bleiben die Mieter und Eigentümer dadurch jederzeit bestens informiert.',
  'INTRO_PDF.REPORT.TEXT_4': 'Im unteren Bereich befinden sich Anhänge und Kommentare, die der Nachricht hinzugefügt wurden.',
  'INTRO_PDF.REPORT.TEXT_5': 'Sie können einen übermittelten Kommentar nachträglich editieren oder löschen, falls nötig.',
  'INTRO_PDF.DOCUMENTS.TITLE': 'Dokumente',
  'INTRO_PDF.DOCUMENTS.TEXT_1': 'Durch die Navigationsleiste (am unteren Rand der ImmoApp) können Sie zu den Dokumenten wechseln.',
  'INTRO_PDF.DOCUMENTS.TEXT_2': 'Falls Sie bereits wissen wonach Sie suchen, können Sie den Dateinamen auf der Startseite in die Suche eingeben.',
  'INTRO_PDF.DOCUMENTS.TEXT_3': 'Andernfalls klicken Sie auf eine Immobilie um zu dessen Hauptordner zu navigieren. Klicken Sie sich durch das Dateisystem bis Sie zu dem gewünschten Dokument gelangen und öffnen Sie dieses durch einen Klick.',
  'INTRO_PDF.DOCUMENTS.TEXT_4': 'Tipp: Falls die Dokumente etwas zu klein zum Lesen sind, können Sie diese auch exportieren und auf einem größeren Bildschirm betrachten.',
  'INTRO_PDF.ADD_REPORT.TITLE': 'Meldung hinzufügen',
  'INTRO_PDF.ADD_REPORT.TEXT_1': 'Über den farbigen runden Butten unten in der Mitte der ImmoApp, gelangen Sie zu einer Auswahl an Meldungen, die Sie an die Verwaltung übermitteln können.',
  'INTRO_PDF.ADD_REPORT.TEXT_2': 'Wählen Sie eine der Optionen aus und es öffnet sich ein neues Fenster in dem Sie Informationen wie Ort, Titel und Beschreibung eintragen können. Zudem können Bilder, Videos und Dokumente optional hinzugefügt werden.',
  'INTRO_PDF.ADD_REPORT.TEXT_3': 'Im Falle einer Schadensmeldung können Sie ebenfalls festlegen, ob die Nachricht öffentlich sein soll, und damit alle Mieter und Eigentümer der Liegenschaft automatisch benachrichtigt werden, oder ob die Meldung nur für Sie und die Verwaltung sichtbar ist.',
  'INTRO_PDF.VOTES.TITLE': 'Abstimmungen',
  'INTRO_PDF.VOTES.A': 'A) Überblick: Klicken Sie eine Abstimmung an.',
  'INTRO_PDF.VOTES.B': 'B) Detailansicht: Hier können Sie sich registrieren.',
  'INTRO_PDF.VOTES.C': 'C) Zugangscode: Sobald die Abstimmung aktiv ist, können Sie den Code eingeben.',
  'INTRO_PDF.VOTES.D': 'D) Anträge: Übersicht über alle Abstimmungspunkte.',
  'INTRO_PDF.VOTES.E': 'E) Abstimmen: Klicken Sie auf jeden Abstimmungspunkt und wählen Sie eine Antwort aus.',
  'INTRO_PDF.VOTES.F': 'F) Übermitteln: Sobald alles ausgewählt wurde, können Sie die Abstimmung übermitteln.',
  'INTRO_PDF.PROFILE.TITLE': 'Profil',
  'INTRO_PDF.PROFILE.TEXT_1': 'Durch die Navigationsleiste (am unteren Rand der ImmoApp) können Sie zu Ihrem Profil wechseln.',
  'INTRO_PDF.PROFILE.TEXT_2': 'Mit einem Klick auf das runde Bild im oberen Bereich können Sie Ihrem Account ein Profilbild hinzufügen.',
  'INTRO_PDF.PROFILE.TEXT_3': 'Dieses Profilbild wird neben von Ihnen erstellten Kommentaren angezeigt.',
  'INTRO_PDF.PROFILE.TEXT_4': 'Innerhalb des Profil-Bildschirms finden Sie zudem viele nützliche Informationen wie z.B. einen erneuten Zugriff auf diese Anleitung und die Kontaktdetails der Verwaltung.',
  'INTRO_PDF.APP_SETTINGS.TITLE': 'App Einstellungen',
  'INTRO_PDF.APP_SETTINGS.TEXT_1': 'In dem Bildschirm "Profil -> App Einstellungen" können Sie die Sprache der ImmoApp ändern und Benachrichtigungen an- und ausschalten',
  'INTRO_PDF.APP_SETTINGS.TEXT_2': 'Push-Benachrichtigungen (standardmässig aktiviert).',
  'INTRO_PDF.APP_SETTINGS.TEXT_3': 'E-Mail-Benachrichtigungen (standardmässig deaktiviert).',
  'INTRO_PDF.CONTACT.TITLE': 'Kontakt',
  'INTRO_PDF.CONTACT.TEXT_1': 'Wir hoffen Ihnen hat der "Rundgang" durch die ImmoApp gefallen und wir konnten Ihr Interesse wecken.',
  'INTRO_PDF.CONTACT.TEXT_2': 'Falls Sie sonst noch Fragen haben, stehen wir Ihnen gerne jederzeit zur Verfügung',
  'INTRO_PDF.CONTACT.TEXT_3': 'Freundliche Grüsse',
  'INTRO_PDF.CONTACT.TEXT_4': 'Ihr {{administration}} Team',
  'INTRO_PDF.FOOTER.PAGE': 'Seite',

  STARTING_ADMINAPP_KEYS: '---',

  'global.interest': 'Zins',
  'global.inflation': 'Teuerung',
  'global.from': 'ab',
  'global.until': 'bis',
  'global.months': 'Monaten',
  'global.years': 'Jahren',
  'global.days': 'Tagen',
  'global.hours': 'Stunden',
  'global.hour': 'Stunde',
  'global.minutes': 'Minuten',
  'global.seconds': 'Sekunden',
  'global.updateData': 'Daten aktualisieren',
  'global.allEntries': 'Alle Einträge.',
  'global.entriesFromTimeLimit': 'Einträge der letzten {time} Monate.',
  'global.search': 'Suchen...',
  'global.loadAll': 'Alle laden',
  'global.choices': 'Auswahlmöglichkeiten',
  'global.pleaseWait': 'Bitte warten...',
  'global.portal': 'Portal',
  'global.currency': 'CHF',
  'global.dashboard': 'Dashboard',
  'global.flexible': 'Flexibel',
  'global.mandate': 'Immobilie',
  'global.mandates': 'Immobilien',
  'global.property': 'Liegenschaft',
  'global.properties': 'Liegenschaften',
  'global.account': 'Account',
  'global.object': 'Objekt',
  'global.objects': 'Objekte',
  'global.apartments': 'Wohnungen',
  'global.residents': 'Bewohner',
  'global.message': 'Nachricht',
  'global.messages': 'Nachrichten',
  'global.repair': 'Ticket',
  'global.repairs': 'Tickets',
  'global.report': 'Bericht',
  'global.pinboard': 'Pinnwand',
  'global.pinboardEntry': 'Pinnwandeintrag',
  'global.pinboardEntries': 'Pinnwandeinträge',
  'global.formGuide': 'Meldungs-Assistent',
  'global.user': 'Benutzer',
  'global.voteChoice': 'Stimme',
  'global.voteChoices': 'Stimmen',
  'global.vote': 'Abstimmung',
  'global.votes': 'Abstimmungen',
  'global.survey': 'Umfrage',
  'global.person': 'Person',
  'global.persons': 'Personen',
  'global.settings': 'Einstellungen',
  'global.info': 'Info',
  'global.confirm': 'Bestätigen',
  'global.hide': 'Ausblenden',
  'global.hidden': 'Ausgeblendet',
  'global.yes': 'Ja',
  'global.no': 'Nein',
  'global.undecided': 'Egal',
  'global.abstain': 'Enth.',
  'global.none': 'Keine',
  'global.ok': 'OK',
  'global.cancel': 'Abbrechen',
  'global.forward': 'Weiter',
  'global.back': 'Zurück',
  'global.inDelivery': 'In Zustellung',
  'global.sent': 'Gesendet',
  'global.noErrors': 'Keine Fehler',
  'global.error': 'Fehler',
  'global.showAll': 'Alle anzeigen',
  'global.userroles': 'Benutzerrollen',
  'global.recipientlist': 'Empfängerliste für {messageTypeString}',
  'global.norecipients': 'Keine Empfänger.',
  'global.generalErrorMessage': 'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.',
  'global.generalErrorMessageLong': 'Es gab einen unerwarteten Fehler! Bitte versuchen Sie es erneut, indem Sie die Seite aktualisieren.',
  'global.emailExistsErrorMessage': 'Fehler: Es existiert bereits ein Benutzer mit der angegebenen E-Mail-Adresse.',
  'global.previewemail.success': 'E-Mail Vorschaut wurde erfolgreich an {email} gesendet. Bitte beachten Sie, dass es bis zu 5 Minuten dauern kann, bis die E-Mail in Ihrem Postfach erscheint.',
  'global.previewemail.sending': 'E-Mail Vorschau wird gesendet...',
  'global.previewemail.send': 'E-Mail Vorschau an {email} senden',
  'global.previewemail.whoreceives': 'Welche Benutzerrollen erhalten die Nachricht? (Hinweis: Admins erhalten immer die Nachricht.)',
  'global.previewemail.warning': 'Folgende Benutzer erhalten wegen ihrer persönlichen App-Einstellungen keine {messageTypeString}',
  'global.action': 'Aktion',
  'global.done': 'Fertig',
  'global.reset': 'Zurücksetzen',
  'global.synced': 'Synced',
  'global.missing': 'Fehlt',
  'global.infoMissing': 'Information fehlt',
  'global.loading': 'Wird geladen...',
  'global.actionInProgress': 'Aktion wird ausgeführt...',
  'global.private': 'Privat',
  'global.active': 'Aktiv',
  'global.inactive': 'Inaktiv',
  'global.scheduled': 'Geplant',
  'global.completed': 'Abgeschlossen',
  'global.registered': 'Registriert',
  'global.voting': 'Stimmt ab',
  'global.upload': 'Hochladen',
  'global.uploadShort': 'Hochladen',
  'global.replace': 'Ersetzen',
  'global.save': 'Speichern',
  'global.tenant': 'Mieter',
  'global.tenants': 'Mieter',
  'global.tenantRestricted': 'Mieter i.Künd.',
  'global.owner': 'Eigentümer',
  'global.owners': 'Eigentümer',
  'global.ownerFkstwe': 'Mitglieder',
  'global.noOwnersFound': 'Keine Eigentümer gefunden.',
  'global.janitor': 'Hauswart',
  'global.management': 'Verwaltung',
  'global.manager': 'Bewirtschafter',
  'global.admin': 'Bewirtschafter (Admin)',
  'global.adminRights': 'Adminrechte',
  'global.name': 'Name',
  'global.address': 'Adresse',
  'global.email': 'Email',
  'global.emailAddress': 'E-Mail-Adresse',
  'global.close': 'Schließen',
  'global.closeWindow': 'Fenster schließen',
  'global.password': 'Passwort',
  'global.repeat': 'Wiederholen',
  'global.sender': 'Absender',
  'global.receiver': 'Empfänger',
  'global.noDataFound': 'Keine Daten vorhanden',
  'global.noFilesAvailable': 'Keine Dateien vorhanden.',
  'global.noFilesUploaded': 'Keine Dateien hochgeladen.',
  'global.data': 'Daten',
  'global.import': 'Import',
  'global.statistics': 'Statistiken',
  'global.document': 'Dokument',
  'global.documents': 'Dokumente',
  'global.status': 'Status',
  'global.mailDelivery': 'Postversand',
  'global.selectStatus': 'Status auswählen',
  'global.condition': 'Zustand',
  'global.timeInfix': 'um',
  'global.timeSuffix': 'Uhr',
  'global.system': 'System',
  'global.cloudStorage': 'Cloud Speicher',
  'global.folder': 'Ordner',
  'global.title': 'Titel',
  'global.description': 'Beschreibung',
  'global.usefulInfosAbbr': 'Nützl. Infos',
  'global.usefulInfos': 'Nützliche Infos',
  'global.sendDate': 'Versanddatum',
  'global.date': 'Datum',
  'global.empty': 'Leer',
  'global.image': 'Bild',
  'global.images': 'Bilder',
  'global.content': 'Inhalt',
  'global.file': 'Datei',
  'global.files': 'Dateien',
  'global.fileName': 'Dateiname',
  'global.fileNameRequired': 'Dateiname erforderlich',
  'global.evaluation': 'Auswertung',
  'global.details': 'Details',
  'global.linkDetailPage': 'Zur Detailseite',
  'global.copy': 'Kopieren',
  'global.duplicate': 'Duplizieren',
  'global.edit': 'Bearbeiten',
  'global.utilize': 'Verwenden',
  'global.view': 'Anschauen',
  'global.delete': 'Löschen',
  'global.reactivate': 'Reaktivieren',
  'global.deactivate': 'Deaktivieren',
  'global.verified': 'Verifiziert',
  'global.not': 'Nicht',
  'global.notVerifiedYet': 'Noch nicht verifiziert',
  'global.approve': 'Freischalten',
  'global.reject': 'Ablehnen',
  'global.push': 'Push',
  'global.news': 'News',
  'global.all': 'Alle',
  'global.type': 'Typ',
  'global.city': 'Ort',
  'global.id': 'ID',
  'global.add': 'Hinzufügen',
  'global.translate': 'Übersetzen',
  'global.constructionYear': 'Baujahr',
  'global.public': 'Öffentlich',
  'global.publicText': 'Soll das Ticket öffentlich sichtbar sein?',
  'global.pushToggle': 'Push-Nachricht',
  'global.pushText': 'Soll eine Push-Nachricht verschickt werden? Nur Benutzer die Push-Nachrichten aktiviert haben, erhalten die Nachricht. (Standardmässig aktiviert)',
  'global.defaultActivated': 'Standardmässig aktiviert',
  'global.defaultDeactivated': 'Standardmässig deaktiviert',
  'global.emailInternalToggle': 'E-Mail an ImmoApp-Nutzer',
  'global.emailExternalToggle': 'E-Mail an Nicht-ImmoApp-Nutzer',
  'global.emailExternalText': 'Sollen alle Benutzer der ausgewählten Immobilien/Liegenschaften, die bereits zur ImmoApp eingeladen wurden, aber diese noch nicht aktiv nutzen, ebenfalls eine E-Mail mit den Informationen dieser Nachricht erhalten? Hinweis: Innerhalb der E-Mail befindet sich ein Link um sich von zukünftigen E-Mails abzumelden.',
  'global.emailToggle': 'E-Mail',
  'global.emailInternToggle': 'E-Mail Intern',
  'global.emailExternToggle': 'E-Mail Extern',
  'global.emailText': 'Soll eine E-Mail verschickt werden? Nur Benutzer die den E-Mail Versand aktiviert haben, erhalten die E-Mail.',
  'global.staff': 'Mitarbeiter',
  'global.category': 'Kategorie',
  'global.firstName': 'Vorname',
  'global.lastName': 'Nachname',
  'global.dateOfBirth': 'Geb.-Datum',
  'global.filter': 'Filter',
  'global.total': 'Total',
  'global.quantity': 'Anzahl',
  'global.complement': 'Ergänzen',
  'global.lastUpdate': 'Aktualisiert',
  'global.update': 'Aktualisieren',
  'global.sort': 'Sortieren',
  'global.updated': 'Aktualisiert',
  'global.mode': 'Modus',
  'global.new': 'Neu',
  'global.na': 'N/A',
  'global.communication': 'Kommunikation',
  'global.logout': 'Abmelden',
  'global.enterTitle': 'Titel eingeben',
  'global.enterMessage': 'Nachricht eingeben',
  'global.enterDescription': 'Beschreibung eingeben',
  'global.enterInfos': 'Infos eingeben',
  'global.typeDropdown': 'Bitte wählen Sie den Typ aus',
  'global.enterLastnameEmail': 'Nachname / E-Mail eingeben',
  'global.receiverDropdown': 'Bitte wählen Sie die Empfänger aus',
  'global.mandateDropdown': 'Bitte wählen Sie eine Immobilie aus',
  'global.propertyDropdown': 'Bitte wählen Sie eine Liegenschaft aus',
  'global.propertiesDropdown': 'Bitte wählen Sie eine oder mehrere Liegenschaften aus',
  'global.version': 'Version',
  'global.errorGeneric': 'Feld ist erforderlich',
  'global.errorTitle': 'Titel ist erforderlich',
  'global.errorDescription': 'Beschreibung ist erforderlich',
  'global.errorMandateDropdown': 'Immobilie ist erforderlich',
  'global.errorPropertyDropdown': 'Liegenschaft ist erforderlich',
  'global.deleteConfirmLong': 'Möchten Sie diesen Datensatz wirklich löschen?',
  'global.deleteConfirmShort': 'Wirklich löschen?',
  'global.code': 'Code',
  'global.optional': 'Optional',
  'global.fieldRequired': 'Dieses Feld wird benötigt.',
  'global.selectComponentType': 'Bitte Typ der Bauteile auswählen.',
  'global.numbersOnly': 'Es sind nur Zahlen erlaubt.',
  'global.max4': 'Maximal 4 Zeichen erlaubt.',
  'global.max10': 'Maximal 10 Zeichen erlaubt.',
  'global.max50': 'Maximal 50 Zeichen erlaubt.',
  'global.min4': 'Mindestens 4 Zeichen erforderlich.',
  'global.enterProperValue': 'Geben Sie den richtigen Wert ein.',
  'global.valueBelow100': 'Geben Sie einen Wert unter 100 ein.',
  'global.onlyIntegers': 'Geben Sie nur ganze Zahlen ein.',
  'global.validEmail': 'Bitte geben Sie eine gültige E-Mail-Adresse an.',
  'global.passwordCharacterValidation': 'Das Passwort sollte mindestens 6 Zeichen lang sein.',
  'global.repeatPasswordMismatch': 'Passwörter sind nicht identisch.',
  'global.street': 'Strasse',
  'global.houseNumber': 'Haus-Nr',
  'global.street_and_nr': 'Strasse & Haus-Nr',
  'global.zipCode': 'PLZ',
  'global.reusableFile': 'Wiederverwendbare Datei',
  'global.reusableFiles': 'Wiederverwendbare Dateien',
  'global.enterSender': 'Absender eingeben',
  'global.uploadRestrictionsFiles': 'Nur Dateien bis max. 20 MB erlaubt.',
  'global.uploadRestrictionsImages': 'Nur Bilder bis max. 20 MB erlaubt.',
  'global.dateOfIncident': 'Datum des Schadens',
  'global.selectDate': 'Datum auswählen',
  'global.selectTime': 'Zeit auswählen',
  'global.errorDate': 'Datum ist erforderlich',
  'global.errorUser': 'Absender ist erforderlich',
  'global.access': 'Zugriff',
  'global.accesses': 'Zugriffe',
  'global.accessRights': 'Zugriffsrechte',
  'global.noAccessForTool': 'Sie haben aktuell noch keinen Zugriff auf dieses Tool.',
  'global.actionConfirm': 'Aktion ausführen?',
  'global.zip': 'Postleitzahl',
  'global.withoutAreaCode': 'ohne Vorwahl',
  'global.terminationProcess': 'Kündigungsprozess',
  'global.home': 'Startseite',
  'global.removedDisallowedCharacter': 'Nicht erlaubtes Sonderzeichen wurde entfernt.',
  'global.automatic': 'Automatisch',
  'global.manual': 'Manuell',
  'global.loadingIssue': 'Es liegt ein Ladeproblem vor',
  'global.agendaItem': 'Traktandum',
  'global.phoneNumberShort': 'Tel.Nummer',
  'global.phoneNumberLong': 'Telefonnummer',
  'global.extraInfo': 'Extra Info',
  'global.comment': 'Kommentar',
  'global.comments': 'Kommentare',
  'global.commentsToggleText': 'Kommentare erlauben?',
  'global.addComment': 'Kommentar Hinzufügen',
  'global.commentAdded': 'Kommentar wurde erfolgreich hinzugefügt.',
  'global.moreInfo': 'Mehr Infos',
  'global.fileUploaded': 'Datei wurde erfolgreich hochgeladen.',
  'global.filesUploaded': 'Dateien wurden erfolgreich hochgeladen.',
  'global.filesUpdated': 'Dateien wurden erfolgreich aktualisiert.',
  'global.downloadResults': 'Abstimmungsergebnisse herunterladen?',
  'global.download': 'Herunterladen',
  'global.downloadAgain': 'Erneut herunterladen',
  'global.downloadSuccess': 'Download erfolgreich',
  'global.downloadInProgress': 'Download läuft',
  'global.role': 'Rolle',
  'global.selectRole': 'Rolle auswählen',
  'global.allProperties': 'Alle Liegenschaften',
  'global.noObjectConnection': 'Ohne Zuordnung zu Objekten',
  'global.dateAndDetails': 'Datum & Details',
  'global.attachment': 'Anhang',
  'global.attachments': 'Anhänge',
  'global.handyman': 'Hauswart',
  'global.notification': 'Benachrichtigung',
  'error.page500message': 'Es ist ein unbekannter Fehler aufgetreten. Die Entwickler wurden informiert und werden das Problem zeitnah beheben. Bitte probieren Sie es später erneut.',
  'menu.manageEmployees': 'Mitarbeiter verwalten',
  'menu.managePlatform': 'Plattform verwalten',
  'business.maxTwoComponents': 'Die Demo-Version erlaubt max. 2 Gebäudeteile',
  'auth.userNotFound': 'Es gibt keinen Benutzer mit der eingegebenen E-Mail-Adresse. Der Benutzer wurde möglicherweise gelöscht.',
  'auth.wrongPassword': 'Das Passwort ist ungültig.',
  'auth.invalidPassword': 'Das Passwort ist ungültig oder der Benutzer hat kein Passwort.',
  'auth.invalidEmail': 'Die E-Mail-Adresse ist falsch formatiert.',
  'auth.unsupportedPersistence': 'Ihr Browser scheint keinen Zugriff auf Cookies zu erlauben. Bitte aktivieren Sie diese und versuchen Sie es erneut.',
  'auth.unsupportedApp': 'Bitte wechseln Sie zur neuen Browser-Version der ImmoApp auf {webappUrl}',
  'auth.fillFields': 'Bitte geben Sie Ihre E-Mail-Adresse und Passwort ein.',
  'auth.weakPassword': 'Das Passwort sollte aus mindestens 6 Zeichen bestehen.',
  'auth.emailExistsTenantTitle': 'Mieter existiert bereits',
  'auth.emailExistsTenantText': 'Die angegebene E-Mail-Adresse wird von einem vorhandenen Mieter verwendet. Bitte bestätigen Sie, dass Sie für diesen Mieter einen Kündigungsprozess starten möchten.',
  'auth.emailExistsOwnerStaffTitle': 'Benutzer existiert bereits',
  'auth.emailExistsOwnerStaffText': "Die angegebene E-Mail-Adresse von einem vorhandenen Benutzer verwendet. Da dieser Benutzer nicht den Status 'Mieter' besitzt, können Sie für diesen Benutzer keinen Kündigungsprozess starten.",
  'auth.emailNotVerifiedTitle': 'Ihr Account ist noch nicht verifiziert.',
  'auth.emailNotVerifiedRepeat': 'erneut ',
  'auth.emailNotVerifiedText': 'Es wurde Ihnen {repeat}eine Verifizierungs-Email zugeschickt. Bitte schauen Sie in Ihrem Postfach nach.',
  'auth.noPermissionsLong': 'Sie haben leider keine Berechtigung, auf diese Seite zuzugreifen.',
  'auth.noPermissionsShort': 'Fehlende oder unzureichende Berechtigungen',
  'auth.invalidZipCode': 'Ungültige Postleitzahl',
  'auth.userRegisteredSuccess': 'Benutzer wurde erfolgreich registriert.',
  'auth.userRegisteredError': 'Benutzer konnte nicht registriert werden. Bitte versuchen Sie es später erneut.',
  'message.email.internal.showlist': 'Empfängerliste für E-Mail Intern anzeigen',
  'message.email.external.showlist': 'Empfängerliste für E-Mail Extern anzeigen',
  'message.notification.showlist': 'Empfängerliste für Push-Nachricht anzeigen',
  'mandate.pageSubTitle': 'Ihre Immobilien im Überblick',
  'mandate.pageSearchbox': 'In Immobilien suchen...',
  'mandate.filterButtonThree': 'Grundfläche',
  'mandate.filterButtonFour': '< 100.000 CHF',
  'mandate.sublabelMandateStatus': 'Nur aktive Immobilien stehen in der ImmoApp zur Verfügung und werden zu Ihrem Abo-Kontingent gezählt.',
  'mandate.sublabelMandateImage': 'Laden Sie hier ein Bild der Immobilie hoch.',
  'mandate.sublabelPinboard': 'Dürfen neue Pinnwandeinträge für diese Immobilie veröffentlicht werden?',
  'mandate.sublabelFormGuide': 'Kann der Meldungs-Assistent für diese Immobilie genutzt werden?',
  'mandate.addMandateImage': 'Geben Sie ein gültiges Bild ein',
  'mandate.tablecolumnTwo': 'Name + Adresse',
  'mandate.StatusFilterNotSync': 'Out of Sync',
  'mandate.deleted': 'Immobilie wurde erfolgreich gelöscht.',
  'mandate.updated': 'Immobilie wurde erfolgreich aktualisiert.',
  'mandate.facility': 'Einrichtung',
  'mandate.form.error': 'Bitte wählen Sie mindestens eine Immobilie oder Liegenschaft aus.',
  'ticket.repair': 'Schadensmeldung',
  'ticket.keyorder': 'Bestellung: Schlüssel- oder Zugangskarte',
  'ticket.nameplate': 'Bestellung: Namensschild',
  'ticket.document': 'Bestellung: Dokument oder Formular',
  'ticket.other': 'Sonstige Mitteilung',
  'page.signInRememberMe': 'Eingeloggt bleiben',
  'page.signInButton': 'Einloggen',
  'page.signInForgotPass': 'Passwort vergessen',
  'repairs.contactInfo.noEmail': 'E-Mail nicht verfügbar',
  'repairs.contactInfo.noPhone': 'Telefon nicht verfügbar',
  'signintools.accesses': 'Zugänge',
  'signintools.adminAccessStaff': 'Verwaltungszugang',
  'signintools.adminAccessImmoMove': 'Anmeldung zum Kündigungsprozess',
  'signintools.signIn': 'Anmelden',
  'signintools.registerQuestion': 'Noch kein Account?',
  'signintools.register': 'Registrierung',
  'signintools.registerAction': 'Registrieren',
  'signintools.registerActionNow': 'Jetzt registrieren',
  'signintools.registerSuccessTitle': 'Registrierung erfolgreich!',
  'signintools.registerSuccessTextMain': 'Vielen Dank für Ihre Registrierung. Bitte bestätigen Sie noch Ihre E-Mail-Adresse. Es wurde Ihnen dafür eine Verifizierungs-E-Mail zugeschickt.',
  'signintools.registerSuccessTextConditional': 'Hinweis: Die Verwaltung überprüft zunächst Ihren Account, bevor Sie Zugriff darauf haben. Sie werden über die Freigabe per E-Mail informiert.',
  'signintools.registerErrorTitle': 'Registrierung fehlgeschlagen!',
  'signintools.registerErrorTextEmailExists': 'Die eingegebene E-Mail-Adresse wird bereits verwendet. Bitte versuchen Sie es erneut. Falls Sie Hilfe benötigen, wenden Sie sich bitte an Ihre Verwaltung.',
  'signintools.registerErrorTextGeneral': 'Bitte versuchen Sie es erneut. Falls der Fehler weiterhin auftreten sollte, wenden Sie sich bitte an Ihre Verwaltung.',
  'signintools.forgotPassword': 'Passwort vergessen?',
  'signintools.forgotLogin': 'Zugangsdaten vergessen?',
  'signintools.legal': 'Rechtliches',
  'signintools.immoDigi': 'ImmoDigi AG',
  'signintools.contact': 'Kontakt',
  'signintools.faq': 'FAQs',
  'signintools.termsOfUse': 'Nutzungsbedingungen',
  'signintools.website': 'Webseite',
  'signintools.moreAboutImmoDigi': 'Mehr zu ImmoDigi',
  'signintools.moreAboutImmoApp': 'Mehr zu ImmoApp',
  'signintools.imprint': 'Impressum',
  'signintools.dataProtection': 'Datenschutz',
  'signintools.accessCode': 'Zugangscode',
  'signintools.wrongInput': 'Falsche E-Mail-Adresse oder falsche Postleitzahl.',
  'signintools.resetPasswordTitle': 'Passwort zurücksetzen',
  'signintools.resetPasswordText': 'Wenn Sie mit OK bestätigen, wird Ihnen eine Anleitung zur Änderung Ihres Passworts per E-Mail zugeschickt.',
  'signintools.resendCodeText': 'Wenn Sie mit OK bestätigen, wird Ihnen erneut der Zugangscode an Ihre E-Mail zugeschickt.',
  'themeSwitcher.settings': 'Einstellungen',
  'topbar.help': 'Hilfe',
  'dashboard.pageTitle1': 'Ihre Immobilien',
  'dashboard.pageTitle2': 'Alle Neuigkeiten',
  'dashboard.pageTitle3': 'Statusüberblick',
  'dashboard.importantContacts': 'Wichtige Kontakte',
  'dashboard.actionEmail': 'E-Mail verfassen',
  'dashboard.actionPhone': 'Anrufen',
  'dashboard.repairsPending': '{repairs} pendent',
  'dashboard.repairsInProgress': '{repairs} in Arbeit',
  'dashboard.repairsCompleted': '{repairs} erledigt',
  'dashboard.noContactsAvailable': 'Keine Kontakte vorhanden.',
  'dashboard.entriesFromTimeLimit': 'Einträge der letzten {time} Monate berücksichtigt.',
  'dashboard.allEntriesTaken': 'Alle Einträge berücksichtigt.',
  'mandateDetails.pageSearchbox': 'In Benutzer suchen...',
  'mandateDetails.cardTitleOne': 'Allgemeine Informationen',
  'mandateDetails.cardSubTitleThree': 'Statistik',
  'mandateDetails.updateImage': 'Bild aktualisieren',
  'person.pageSubTitle': 'Auflistung aller Benutzer',
  'person.pageSearchbox': 'In Benutzer suchen...',
  'person.addButton': 'Eintrag hinzufügen',
  'person.addPerson': 'Person hinzufügen',
  'person.addNewPerson': 'Neue Person hinzufügen',
  'person.updatePerson': 'Person aktualisieren',
  'person.companyName': 'Name der Firma',
  'person.addMandateImage': 'Geben Sie ein gültiges Bild ein',
  'person.addCompanyName': 'Firmennamen eingeben',
  'person.addOwnerName': 'Geben Sie den Namen des Besitzers ein',
  'person.userData': 'Benutzer Daten',
  'person.staffData': 'Mitarbeiter Daten',
  'person.importedData': 'Importierte Daten',
  'person.emailExternal': 'E-Mail extern',
  'person.company': 'Firma',
  'person.phoneNumber': 'Tel-Nr',
  'person.mobile': 'Tel. Mobil',
  'person.phoneHome': 'Tel. Privat',
  'person.phoneWork': 'Tel. Arbeit',
  'person.fax': 'Fax',
  'person.city': 'Stadt',
  'person.salutation': 'Anrede',
  'person.street': 'Straße',
  'person.website': 'Webseite',
  'person.deleted': 'Benutzer gelöscht',
  'person.edited': 'Benutzer aktualisiert',
  'person.emailAndVerified': 'Email + Verifizierung',
  'person.showContactTitle': 'Als wichtigen Kontakt anzeigen?',
  'person.showContactText': 'Der Mitarbeiter wird in der ImmoApp und der WebApp als Kontakt für alle zugeordneten Immobilien angezeigt.',
  'person.userRestrictedTitle': 'Kommunikation einschränken?',
  'person.userRestrictedText': 'Falls aktiviert, darf der Benutzer keine Meldungen oder Kommentare veröffentlichen.',
  'person.isContact': 'Als Kontakt gelistet',
  'person.isNotContact': 'Nicht als Kontakt gelistet',
  'upload.size': 'Größe',
  'upload.inprogress': 'Upload wird durchgeführt...',
  'upload.progress': 'Upload Fortschritt',
  'upload.errorFileCount': 'Zu viele Dateien ausgewählt.',
  'upload.errorFileSize': 'Die Dateigröße darf 20 MB nicht überschreiten.',
  'upload.errorFileType': 'Dateiformat wird nicht unterstützt.',
  'upload.errorFileTypeImages': 'Die Datei muss vom Typ sein: .jpg, .jpeg, .png',
  'upload.delete': 'Datei löschen?',
  'repair.externalTitle': 'Extern erfasstes Ticket',
  'repair.externalDescription': 'Nur für Bewirtschafter sichtbar, bis eine Immobilie zugeordnet wird.',
  'repair.moreTypesInImmoApp': 'Falls ein anderer Typ benötigt wird, erfassen Sie das Ticket bitte in der {immoappLink}.',
  'repair.pageSubTitle': 'Alle Tickets im Überblick',
  'repair.pageSearchbox': 'In Tickets suchen…',
  'repair.addButton': 'Ticket Hinzufügen',
  'repair.updateRepair': 'Ticket aktualisieren',
  'repair.addRepair': 'Ticket hinzufügen',
  'repair.viewRepair': 'Ticket (Lesemodus)',
  'repair.addFileError': 'Die ausgewählte Datei ist nicht gültig.',
  'repair.tablecolumnOne': 'Meldung',
  'repair.statusZero': 'Pendent',
  'repair.statusOne': 'In Arbeit',
  'repair.statusTwo': 'Erledigt',
  'repair.selectHandyman': 'Hauswart auswählen',
  'repair.handymanHireTitle': 'Hauswart beauftragen',
  'repair.handymanNotificationText': 'Soll der Hauswart über die Auftragserteilung per E-Mail und Push benachrichtigt werden?',
  'repair.handymanIndividualMessage': 'Individuelle Nachricht für den Hauswart',
  'repair.handymanMessageNote': 'Hinweis: Die E-Mail enthält automatisch alle vorhandenen Informationen des Tickets (Titel, Beschreibung, Empfänger, Status, Absender, Dateien, etc).',
  'repair.handymanAddStatusText': 'Möchten Sie auch einen Status zum Ticket hinzufügen?',
  'repair.errorHandymanDropdown': 'Hauswart ist erforderlich',
  'repair.errorStatusDropdown': 'Status ist erforderlich',
  'repair.addressProvided': 'Übermittelte Adresse',
  'repair.deleted': 'Ticket wurde erfolgreich gelöscht.',
  'repair.updated': 'Ticket wurde erfolgreich aktualisiert.',
  'repair.yarowa_removed': 'Yarowa Prozess wurde erfolgreich entfernt.',
  'repair.locationDetails': 'Weitere Details zum Ort (Optional)',
  'repair.moreInformation': 'Weitere Angaben',
  'repair.enterLocationDetails': 'Details eingeben (Optional)',
  'repair.timelineMeta': 'Von {name} am {date}',
  'repair.timelineUpdated': 'Verlauf wurde erfolgreich aktualisiert.',
  'repair.inserted': 'Ticket wurde erfolgreich hinzugefügt.',
  'repair.pushTextPrivateCreate': 'Falls aktiviert, wird eine Push-Nachricht an alle zuständigen Mitarbeiter verschickt.',
  'repair.emailTextPrivateCreate': 'Falls aktiviert, wird eine E-Mail an alle zuständigen Mitarbeiter verschickt.',
  'repair.pushTextPrivateUpdate': 'Falls aktiviert, wird eine Push-Nachricht an alle zuständigen Mitarbeiter und an den Benutzer verschickt, der die Meldung übermittelt hat.',
  'repair.emailTextPrivateUpdate': 'Falls aktiviert, wird eine E-Mail an alle zuständigen Mitarbeiter und an den Benutzer verschickt, der die Meldung übermittelt hat.',
  'repair.history': 'Historie',
  'repair.addStatus': 'Status hinzufügen',
  'repair.updateStatus': 'Status aktualisieren',
  'repair.withoutMandate': 'Noch ohne zugeordnete Immobilie',
  'repair.withoutMandatePropertyInfo': 'Wählen Sie zunächst eine Immobilie aus',
  'repair.withoutProperty': 'Noch ohne zugeordnete Liegenschaft',
  'repair.withoutSender': 'Noch ohne zugeordneten Absender',
  'repair.submittedContactDetails': 'Übermittelte Kontaktdaten',
  'repair.addHandymanInfo': 'Sie können einen Hauswart unter Aktionen zuordnen.',
  'repair.contactInfo.noInfo': 'Keine Kontaktdaten verfügbar.',
  'repair.contactInfo.emailSubject': 'Reparaturmeldung',
  'messages.errorsFor': 'Fehler für',
  'messages.pageSubTitle': 'Ihre Nachrichten im Überblick',
  'messages.pageSearchbox': 'In Nachrichten suchen...',
  'messages.addButton': 'Nachricht hinzufügen',
  'messages.addMessage': 'Nachricht hinzufügen',
  'messages.updateMessage': 'Nachricht aktualisieren',
  'messages.viewMessage': 'Nachricht (Lesemodus)',
  'messages.addMessageImage': 'Geben Sie ein gültiges Bild ein',
  'messages.allMandates': 'Alle Immobilien',
  'messages.pushStatusDefault': '[kein Status]',
  'messages.pushStatusNoPush': 'Keine Push-Nachricht',
  'messages.pushStatusSendingOut': 'Wird versendet...',
  'messages.pushStatusSentOut': 'Versandt',
  'messages.propertyNotice': 'Hinweis: Nachrichten an Liegenschaften können von allen Bewohnern einer Immobilie gesehen werden. Es werden jedoch nur die Bewohner der Liegenschaft per Push-Notification benachrichtigt.',
  'messages.errorSelectValue': 'Bitte wählen Sie einen Wert aus',
  'messages.errorDate': 'Bitte wählen Sie ein Datum',
  'messages.errorCategory': 'Kategorie ist erforderlich',
  'messages.fileProgress': 'Dateien werden hochgeladen...',
  'messages.previewEmailSuccess': 'E-Mail wurde erfolgreich versendet.',
  'messages.inserted': 'Nachricht wurde erfolgreich hinzugefügt.',
  'messages.updated': 'Nachricht wurde erfolgreich aktualisiert.',
  'messages.deleted': 'Nachricht wurde erfolgreich gelöscht.',
  'messages.entriesFromTimeLimit': 'Zukünftige & Einträge der letzten {time} Monate.',
  'documents.noticeHeader': 'Ein Benutzer hat Zugriff auf diesen Ordner wenn mindestens eine Bedingung zutrifft:',
  'documents.noticeBullet1': "Die Rolle des Benutzers ist Teil des Feldes 'Benutzergruppen'.",
  'documents.noticeBullet2': "Der Benutzer wurde dem Feld 'Einzelne Benutzer' hinzugefügt.",
  'documents.noticeBullet3': 'Der Benutzer hat per Import die Zugriffsrechte zu dem Ordner erhalten.',
  'documents.noticeBullet4': "Der Benutzer besitzt die Rolle 'Admin'.",
  'documents.noticeBullet4abaimmo': "Der Benutzer besitzt die Rolle 'Bewirtschafter' oder 'Admin'.",
  'documents.noticeReset': 'Hinweis: wenn Sie alle Benutzergruppen entfernen, wird der Ordner auf die standardmäßigen Zugriffsrechte zurückgesetzt.',
  'documents.accessGroups': 'Benutzergruppen',
  'documents.accessUser': 'Einzelne Benutzer',
  'documents.accessUserImport': 'Importierte Benutzer',
  'documents.selectGroup': 'Benutzergruppe auswählen (optional)',
  'documents.selectUser': 'Benutzer auswählen (optional)',
  'documents.noImportedUsers': 'Keine importierten Benutzer',
  'documents.accessRightsUpdated': 'Zugriffsrechte wurden erfolgreich aktualisiert.',
  'documents.mandate': 'Immobilie',
  'documents.property': 'Liegenschaft',
  'documents.object': 'Objekt',
  'documents.person': 'Person',
  'settings.tabCorporateIdentity': 'Firmenidentität',
  'settings.tabInfocenter': 'Infocenter',
  'settings.tabImmoApp': 'ImmoApp',
  'settings.tabImmoMove': 'ImmoMove',
  'settings.newManuallyRegisteredUsers': 'Neue manuell registrierte Benutzer',
  'settings.erpConnection': 'ERP-Verbindung',
  'settings.staffExisting': 'Vorhanden',
  'settings.changeLogo': 'Logo ändern',
  'settings.selectLogo': 'Logo auswählen',
  'settings.syncmeTitle': 'SyncMe Synchronisation',
  'settings.defaultAccessGroupNotice': 'Welche Benutzergruppen sollen standardmäßig Zugriff auf Ordner besitzen?',
  'settings.syncmeText': 'Sie nutzen aktuell die Software "SyncMe" um Ihre Dateien mit der ImmoApp zu synchronisieren.',
  'settings.documentsSync': 'Dokumenten Synchronisation',
  'settings.documentsSyncButtonText': 'Jetzt synchronisieren',
  'settings.documentsSynced': 'Synchronisation erfolgreich gestartet.',
  'settings.documentsSyncing': 'Synchronisation wird gestartet...',
  'settings.documentsAlreadySyncing': 'Synchronisation wurde bereits gestartet. Bitte haben Sie einen Moment Geduld.',
  'settings.dataReset': 'Daten zurücksetzen',
  'settings.dataResetButtonText': 'Jetzt zurücksetzen',
  'settings.dataResetting': 'Daten werden zurückgesetzt...',
  'settings.dataResetted': 'Daten wurden erfolgreich zurückgesetzt.',
  'settings.colorUpdate': 'Akzentfarbe ändern',
  'settings.colorUpdateTextCurrent': 'Aktuelle Farbe',
  'settings.colorUpdateTextNew': 'Neue Farbe',
  'settings.colorUpdateButtonText': 'Farbe ändern',
  'settings.colorUpdated': 'Akzentfarbe wurde erfolgreich aktualisiert.',
  'settings.inviteEmailTitle': 'Einladungs-Email Test',
  'settings.inviteEmailDescription': 'Um die Einladungs-Email inklusive Anleitung für Mieter, Eigentümer, Hauswarte und Bewirtschafter zu überprüfen, können Sie sich hier selber eine Test-Email zusenden. Bitte beachten Sie: ein zu häufiger Versand an die gleiche E-Mail-Adresse kann gegebenfalls zu einer Verzögerung von bis zu 15 Minuten führen.',
  'settings.exampleMandateTitle': 'Muster-Immobilie',
  'settings.exampleMandateDescription': 'Mit einer Muster-Immobilie können Sie die Funktionen der ImmoApp testen, ohne reale Daten zu verändern oder versehentlich E-Mails an Benutzer zu senden. Die Muster-Immobilie erhält die ID 9999 und die Muster-Eigentümer die IDs 99900 bis 99999.',
  'settings.exampleMandateCreate': 'Hinzufügen',
  'settings.exampleMandateReset': 'Daten zurücksetzen',
  'settings.exampleMandateDelete': 'Löschen',
  'settings.exampleMandateCreateSuccess': 'Muster-Immobilie wurde erfolgreich hinzugefügt.',
  'settings.exampleMandateResetSuccess': 'Muster-Immobilie wurde erfolgreich zurückgesetzt.',
  'settings.exampleMandateDeleteSuccess': 'Muster-Immobilie wurde erfolgreich gelöscht.',
  'settings.inviteEmailButton': 'Test-Email senden',
  'settings.inviteEmailSuccess': 'E-Mail wurde erfolgreich versendet.',
  'settings.createUsersTitle': 'Accounts erstellen',
  'settings.createUsersButton': 'Accounts anzeigen',
  'settings.createUsersText': 'Für alle importierten Personen einen ImmoApp Account erstellen und eine Einladungs-Email versenden. Im ersten Schritt werden Ihnen zunächst die zutreffenden Accounts für alle ausgewählten Immobilien angezeigt. Erst nachdem Sie erneut bestätigen, werden diese Accounts erstellt. Hinweis: es können nur aktive Immobilien ausgewählt werden.',
  'settings.language.invitation': 'Sprache der Einladung',
  'settings.language.all': 'Sprache der Einladung und ImmoApp',
  'settings.dataLastUpdatedOn': 'Zuletzt ausgeführt am',
  'settings.defaultFolderAccess': 'Standardmäßiger Zugriff auf Ordner',
  'settings.createUsersNotificationEmptyTitle': 'Hinweis',
  'settings.createUsersNotificationEmptyText': 'Alle importierten Personen besitzen bereits einen Account.',
  'settings.createUsersNotificationConfirmTitle': 'Bestätigung',
  'settings.createUsersNotificationConfirmText': 'Wenn Sie bestätigen, wird für die folgenden {personCountTotal} Personen ein neuer Account erstellt und eine Einladungs-Email verschickt.',
  'settings.createUsersNotificationNoInviteText': 'Die folgenden {personCountTotal} Personen erhalten keine Einladung.',
  'settings.createUsersNotificationConfirmNotice': 'Hinweis: Insgesamt haben {personCountTotal} importierte Personen noch keinen Account. Die maximale Anzahl für einen Import beträgt jedoch 100 Benutzer. Bitte wiederholen Sie den Vorgang bis alle Benutzer erfolgreich importiert wurden.',
  'settings.createUsersNotificationWarningTitle': 'Warnung',
  'settings.createUsersNotificationWarningText': 'Bitte aktivieren Sie zunächst die Option "Benutzer automatisch erstellen" bevor Sie den Import starten.',
  'settings.createUsersInProgress': 'Alle Benutzer erhalten einen Account und eine Einladungs-E-Mail mit den Zugangsdaten. Dieser Vorgang kann einige Minuten dauern, bitte haben Sie etwas Geduld.',
  'settings.createUsersSuccess': 'Alle Benutzer wurden erfolgreich erstellt und haben eine Einladungs-Email erhalten.',
  'settings.logoUpdated': 'Logo aktualisiert',
  'settings.filesCommonUpdated': 'Dateien wurden erfolgreich aktualisiert.',
  'settings.dataCommonUpdated': 'Daten wurden erfolgreich aktualisiert.',
  'settings.settingsFieldUpdated': 'Die Einstellung wurde erfolgreich aktualisiert.',
  'settings.inviteCodeForImmoApp': 'Einladungscode für die ImmoApp',
  'settings.autoPermitUsers': 'Benutzer automatisch freischalten?',
  'settings.autoPermitUsersExtra': 'Falls aktiviert, werden Benutzer nach manueller Registrierung automatisch für die ImmoApp freigeschaltet, falls diese bereits in Ihrem ERP-System existieren.',
  'settings.autoInviteUsers': 'Benutzer automatisch erstellen?',
  'settings.autoInviteUsersExtra': 'Falls aktiviert, wird automatisch für alle importierten Personen ein Account in der ImmoApp erstellt und eine Einladungs-E-Mail verschickt (falls noch nicht geschehen). Max {count} neue Accounts pro Tag. Für die erstmalige Aktivierung, wenden Sie sich bitte an ImmoDigi.',
  'settings.autoDeactivateUsers': 'Benutzer automatisch deaktivieren?',
  'settings.autoDeactivateUsersExtra': 'Falls aktiviert, wird ein Benutzer-Account automatisch deaktiviert, falls dieser nicht mehr in Ihrem ERP-System vorhanden ist.',
  'settings.staffInviteMailTitle': 'Zugangsdaten automatisch verschicken',
  'settings.staffInviteMailText': 'Soll der neue Mitarbeiter automatisch seine Zugangsdaten per E-Mail erhalten?',
  'settings.systemEmails': 'E-Mails an ImmoApp Benutzer versenden?',
  'settings.systemEmailsExtra': 'Achtung: wenn Sie diese Option deaktivieren, werden keine automatischen Einladungen, Erinnerungen, Abstimmungscodes, oder ähnliche E-Mails mehr versendet.',
  'settings.systemPush': 'Push-Nachrichten an ImmoApp Benutzer versenden?',
  'settings.systemPushExtra': 'Achtung: wenn Sie diese Option deaktivieren, werden keine automatischen Push-Nachrichten zu neuen Nutzern, Nachrichten, Reparaturmeldungen, oder ähnliches mehr versendet.',
  'settings.newDocumentsPush': '1x Täglich um {time} Uhr per Push-Nachricht auf neu hinzugefügte Dokumente hinweisen?',
  'account.notifications': 'Benachrichtigungen',
  'account.language': 'Sprache',
  'account.loginAndSecurity': 'Login & Sicherheit',
  'account.communicationEmailAdminMessageTitle': 'E-Mail: Nachrichten',
  'account.communicationEmailAdminMessageDescription': 'E-Mail bei neuen Nachrichten erhalten?',
  'account.communicationEmailAdminRepairTitle': 'E-Mail: Tickets Neu',
  'account.communicationEmailAdminRepairDescription': 'E-Mail bei neuem Ticket erhalten?',
  'account.communicationEmailAdminRepairUpdateTitle': 'E-Mail: Tickets Update',
  'account.communicationEmailAdminRepairUpdateDescription': 'E-Mail bei neuem Ticket Status erhalten?',
  'account.communicationEmailAdminPinboardTitle': 'E-Mail: Pinnwandeinträge',
  'account.communicationEmailAdminPinboardDescription': 'E-Mail bei neuem Pinnwandeintrag erhalten?',
  'account.communicationEmailAdminCommentTitle': 'E-Mail: Kommentare',
  'account.communicationEmailAdminCommentDescription': 'E-Mail bei neuem Kommentar zu eigenen Beiträgen erhalten?',
  'account.communicationEmailAdminVoteItemTitle': 'E-Mail: Abstimmung Traktandum (nur für Mitarbeiter)',
  'account.communicationEmailAdminVoteItemDescription': 'E-Mail bei neuem Traktandum erhalten?',
  'account.changeEmail': 'E-Mail ändern',
  'account.newEmail': 'Neue E-Mail-Adresse',
  'account.changePassword': 'Passwort ändern',
  'account.currentPassword': 'Aktuelles Passwort',
  'account.newPassword': 'Neues Passwort',
  'account.modify': 'Ändern',
  'account.updateNotification': 'Benachrichtigungen aktualisieren',
  'account.notificationSettingsUpdated': 'Benachrichtigungseinstellungen aktualisiert',
  'account.passwordChanged': 'Passwort wurde erfolgreich aktualisiert.',
  'account.emailChanged': 'E-Mail-Adresse wurde erfolgreich aktualisiert.',
  'pdf.successfullyDownloaded': 'Das angeforderte PDF wurde erfolgreich generiert und heruntergeladen.',
  'pdf.errorParameters': 'Die übergebenen Daten sind ungültig. Bitte rufen Sie den Link aus Ihrer E-Mail erneut auf.',
  'pdf.openInBrowser': 'Direkt im Browser öffnen',
  'reservation.addCommonSpace': 'Gemeinschaftsraum hinzufügen',
  'reservation.updateCommonSpace': 'Gemeinschaftsraum aktualisieren',
  'reservation.enterName': 'Name eingeben',
  'reservation.days': 'Verfügbare Tage',
  'reservation.everyday': 'Jeden Tag',
  'reservation.selectDays': 'Tage auswählen',
  'reservation.withConfirmation': 'Mit Bestätigung',
  'reservation.confirmationNeededTitle': 'Bestätigung benötigt?',
  'reservation.confirmationNeededText': 'Falls aktiviert, muss die Reservation zunächst von der Verwaltung bestätigt werden, bevor sie gültig ist.',
  'reservation.confirmed': 'Bestätigt',
  'reservation.interval': 'Dauer',
  'reservation.time': 'Reservation möglich von / bis',
  'reservation.updated': 'Der Gemeinschaftsraum wurden erfolgreich aktualisiert.',
  'reservation.added': 'Der Gemeinschaftsraum wurde erfolgreich hinzugefügt.',
  'reservation.deleted': 'Der Gemeinschaftsraum wurde erfolgreich gelöscht.',
  'reservation.errorInterval': 'Intervall ist erforderlich',
  'reservation.errorName': 'Name ist erforderlich',
  'reservation.errorNameDuplicated': 'Dieser Name existiert bereits',
  'reservation.errorTime': 'Zeitauswahl ist erforderlich',
  'reservation.errorWeekdays': 'Wochentage sind erforderlich',
  'switcher.changeLanguage': 'Sprache ändern',
  'staff.addStaff': 'Mitarbeiter hinzufügen',
  'staff.addStaffToMandate': 'Mitarbeiter auswählen...',
  'staff.errorStaffDropdown': 'Bitte wählen Sie einen Wert aus',
  'staff.added': 'Mitarbeiter wurde erfolgreich hinzugefügt.',
  'staff.updated': 'Mitarbeiter wurde erfolgreich aktualisiert.',
  'staff.deleted': 'Mitarbeiter wurde erfolgreich gelöscht.',
  'staff.updateStaff': 'Mitarbeiter aktualisieren',
  'staff.viewStaff': 'Mitarbeiter (Lesemodus)',
  'staff.confirmDelete': 'Möchten Sie diesen Mitarbeiter wirklich löschen?',
  'user.dataNotAutomaticallySynced': 'Einige Daten werden nicht automatisch aus dem ERP-System synchronisiert.',
  'user.approveUser': 'Benutzer freischalten',
  'user.viewUser': 'Benutzer (Lesemodus)',
  'user.deleted': 'Benutzer wurde erfolgreich gelöscht.',
  'user.deactivated': 'Benutzer wurde erfolgreich deaktiviert.',
  'user.reactivated': 'Benutzer wurde erfolgreich reaktiviert.',
  'user.edited': 'Benutzer wurde erfolgreich aktualisiert.',
  'user.updateUser': 'Benutzer aktualisieren',
  'user.confirmDelete': 'Möchten Sie diesen Benutzer wirklich löschen?',
  'user.confirmDeactivate': 'Möchten Sie diesen Benutzer wirklich deaktivieren?',
  'user.confirmReactivate': 'Möchten Sie diesen Benutzer wirklich reaktivieren?',
  'user.confirmReject': 'Möchten Sie diesen Benutzer wirklich ablehnen?',
  'user.resentAccess': 'Zugangsdaten erneut senden',
  'user.resentAccessNotice': 'Das neue Passwort wurde erfolgreich an {email} gesendet. Für mögliche aufkommende Fragen, oder bei Login-Problemen, können Sie oben angezeigtes Passwort direkt mit dem Benutzer teilen.',
  'user.resentAccessConfirm': 'Falls Sie bestätigen, wird <strong>{user_name} ({user_email})</strong> erneut eine Einladungs-Email mit einem neuen zufällig generierten Passwort geschickt.',
  'user.resentAccessSuccess': 'Zugangsdaten wurden erfolgreich versendet.',
  'user.lastLogin': 'Letzter Login',
  'user.dataRefreshOnceADay': 'Die Daten werden einmal täglich aktualisiert.',
  'user.invitedOrRegistered': 'Der Benutzer wurde eingeladen oder hat sich manuell registriert.',
  'user.invited': 'Eingeladen',
  'user.invitedBy': 'Eingeladen von',
  'user.login': 'Login',
  'user.didLogin': 'Hat sich bereits eingeloggt',
  'user.didNeverLogin': 'Hat sich noch nicht eingeloggt',
  'users.withoutERP': 'Benutzer ohne ERP-Verbindung',
  'users.withERP': 'Benutzer mit ERP-Verbindung',
  'users.deactivated': 'Deaktivierte Benutzer',
  'users.connectWithERPInfo': 'Die folgenden ImmoApp Benutzer sind aktuell mit keinem ERP-Account verknüpft. Um alle Funktionalitäten der ImmoApp nutzen zu können, empfehlen wir für möglichst viele Benutzer eine Verknüpfung herzustellen. Dies geschieht automatisch (1x täglich) wenn die E-Mail Adresse des ImmoApp Benutzers mit der eines ERP-Accounts übereinstimmt. (Ihr aktuelles ERP-System ist {erpSystem}.)',
  'source.immoapp_register': 'Registriert in ImmoApp',
  'source.webapp_register': 'Registriert in WebApp',
  'source.immoapp_tenant': 'Eingeladen v. Eigentümer',
  'source.immotop_system': 'Eingeladen v. System',
  'source.webapp_admin': 'Eingeladen v. Mitarbeiter',
  'source.webapp_immomove': 'ImmoMove',
  'footer.allRightsReserved': 'Alle Rechte vorbehalten.',
  'notification.FormSubmit': 'Formular übermittelt',
  'notification.MessageForm': 'Nachrichtenformulardaten wurden erfolgreich übermittelt',
  'pagination.of': 'von',
  'pagination.results': 'Ergebnissen',
  'filter.before': 'vor',
  'filter.equal': 'gleich',
  'filter.after': 'nach',
  'filter.less': 'weniger',
  'filter.more': 'mehr',
  'filter.submitButton': 'Anwenden',
  'filter.selectValue': 'Wert auswählen',
  'filter.selectTyp': 'Typ auswählen',
  'filter.verification': 'Verifizierung',
  'immovable.deleting': 'Immobilie wird gelöscht...',
  'immovable.copying': 'Immobilie wird kopiert...',
  'immovable.pageSubTitle': 'Alle Immobilien im Überlick',
  'immovable.insuranceValue': 'Versicherungswert',
  'immovable.constructionYear': 'Baujahr',
  'immovable.newImmovable': 'Neue Immobilie',
  'immovable.newAccount': 'Neues Konto',
  'immovable.updateAccount': 'Konto aktualisieren',
  'immovable.copyAccount': 'Konto kopieren',
  'immovable.viewAccount': 'Konto anzeigen',
  'immovable.bankTitle': 'Bankkonten',
  'immovable.pickExistingImmovable': 'Eine vorhandene Immobilie auswählen?',
  'immovable.objectCategories': 'Immobilienkategorie',
  'immovable.selectImmovableType': 'Kategorie',
  'immovable.added': 'Immobilie wurde erfolgreich hinzugefügt.',
  'immovable.groundArea': 'Grundstücksfläche',
  'immovable.cubicGvz': 'Kubatur GVZ',
  'immovable.renewalFund': 'Erneuerungsfonds',
  'immovable.calculated': 'Berechnet',
  'immovable.entered': 'Eingegeben',
  'immovable.currentStatus': 'Aktueller Stand',
  'immovable.accountBalanceOptimal': 'Kontostand Optimal',
  'immovable.accountBalanceCurrent': 'Kontostand Aktuell',
  'immovable.depositCurrent': 'Einlage Aktuell',
  'immovable.depositOptimal': 'Einlage Optimal',
  'immovable.depositOptimal.sublabel1': 'Ohne Zins und Teuerung, bis',
  'immovable.depositOptimal.sublabel2': 'Ohne Zins und Teuerung, ab',
  'immovable.depositRegulations': 'Einlage gemäss Reglement',
  'immovable.minimumThreshold': 'Sockelbetrag',
  'immovable.yearlyCorrectionAmount': 'Jährlicher Korrekturbetrag',
  'immovable.depositForThisYearReceived': 'Diesjährige Einlage bereits enthalten',
  'immovable.regulationsGvz': 'Reglement von GVZ (in %)',
  'immovable.interest': 'Zins',
  'immovable.inflation': 'Teuerung',
  'immovable.interestAndRisingPrices': 'Zins & Teuerung',
  'immovable.forecastRenewalFund': 'Erneuerungsfonds Prognose',
  'immovable.numbersRenewalFund': 'Erneuerungsfonds Kennzahlen',
  'immovable.communityComponents': 'Gemeinschaftliche Bauteile',
  'immovable.component': 'Bauteil',
  'immovable.components': 'Bauteile',
  'immovable.replaceCost': 'Erneuerungskosten',
  'immovable.remainServiceTime': 'Restnutzungszeit',
  'immovable.accountBalance': 'Kontostand',
  'immovable.accountName': 'Kontoname',
  'immovable.bankBalance': 'Kontostand',
  'immovable.iban': 'IBAN',
  'immovable.bankName': 'Bankname',
  'immovable.newComponent': 'Neues Bauteil',
  'immovable.updateComponent': 'Bauteil aktualisieren',
  'immovable.copyComponent': 'Bauteil kopieren',
  'immovable.addComponentLabel': 'Bitte die Daten des neuen Bauteils eingeben. (Dies kann alles zu einem späteren Zeitpunkt flexibel angepasst werden.)',
  'immovable.componentName': 'Name des Bauteils',
  'immovable.componentCalculationBase': 'Berechnungsbasis',
  'immovable.componentBase': 'Basis',
  'immovable.componentFactor': 'Faktor',
  'immovable.componentRenewalDate': 'Erneuerungsjahr',
  'immovable.componentRenovationDate': 'Baujahr / Letzte Erneuerung',
  'immovable.higherRenewalDate': 'Das Erneuerungsjahr sollte über dem aktuellen Jahr liegen',
  'immovable.lowerRenovationDate': 'Das Baujahr sollte unter dem Erneuerungsdatum liegen',
  'immovable.lastUpdated': 'Daten zuletzt aktualisiert vor: ',
  'immovable.componentAdded': 'Bauteil wurde erfolgreich hinzugefügt.',
  'immovable.componentCopied': 'Bauteil wurde erfolgreich kopiert.',
  'immovable.bankAccountAdded': 'Konto wurde erfolgreich hinzugefügt.',
  'immovable.bankAccountCopied': 'Konto wurde erfolgreich kopiert.',
  'immovable.bankAccountUpdated': 'Konto wurde erfolgreich aktualisiert.',
  'immovable.year': 'Jahr',
  'immovable.years': 'Jahre',
  'immovable.noDescription': '[Keine Beschreibung vorhanden]',
  'immovable.generateReport': 'Bericht erzeugen',
  'immovable.reportInclInterestAndInflation': 'Soll der Bericht Zins und Teuerung enthalten?',
  'immovable.reportInclCompImages': 'Soll der Bericht Bilder der Bauteile enthalten?',
  'immovable.reportSelectChoiceDescription': 'Bitte wählen sie die gewünschten Teile für den Bericht aus',
  'immovable.reportSelectChoice': 'Teile auswählen',
  'immovable.selectChoiceRequired': 'Wählen Sie die Teile aus',
  'immovable.progressNotificationTitle': 'Export Fortschritt',
  'immovable.progressNotificationFailedTitle': 'Export fehlgeschlagen',
  'immovable.progressNotificationDescription': 'Der Bericht wird erstellt. Dies kann einige Sekunden dauern, bitte haben Sie etwas Geduld.',
  'immovable.progressNotificationFailedDescription': 'Bitte versuchen Sie es erneut.',
  'immovable.pdfSizeErrorTitle': 'Fehler: PDF-Bericht zu groß',
  'immovable.pdfSizeErrorDescription': 'Der Bericht ist größer als 10 MB und konnte daher nicht heruntergeladen werden. Bitte entfernen Sie ein paar Bilder oder wählen Sie weniger Gebäudeteile für den Bericht aus.',
  'immovable.duplicate': 'Möchten Sie den Datensatz wirklich duplizieren?',
  'immovable.duplicated': 'Immobilie wurde erfolgreich kopiert.',
  'immovable.buildingKeyFigures': 'Gebäude Kennzahlen',
  'immovable.bankDeleted': 'Konto wurde erfolgreich gelöscht.',
  'immovable.infoTextGraph': '<strong>EF Aktuell:</strong>\nDiese Linie zeigt den aktuellen Stand Ihres Erneuerungsfonds und die Entwicklung in den kommenden Jahren, basierend auf der aktuellen Einlage die Sie jedes Jahr von den Eigentümern erhalten.\n\n<strong>EF Optimal:</strong>\nDiese Linie zeigt den optimalen Stand Ihres Erneuerungsfonds, falls Sie Ihre aktuelle Einlage durch die berechnete optimale Einlage ersetzen.\n\n<strong>EF Perfekt:</strong>\nDiese Linie zeigt den perfekten Stand Ihres Erneuerungsfonds, beginnend mit dem perfekten Kontostand und der optimalen Einlage. Dieser Linie endet mit dem Korrekturzeitraum, da ab diesem Zeitpunkt der optimale EF dem perfekten EF entspricht.\n\n<strong>Korrekturzeitraum:</strong>\nSollte Ihr aktueller Kontostand größer oder kleiner als der optimale Kontostand sein, ergibt sich eine Anpassung über die Anfangsjahre basierend auf dem eingestellten jährlichen Korrekturbetrag, bis der optimale Stand des Erneuerungsfonds erreicht ist.',
  'immovable.infoTextNumbersCalculated': '<strong>Optimaler Stand EF:</strong>\nDer Kontostand den Ihr Erneuerungsfonds zum jetztigen Zeitpunkt haben sollte, um nie unter den eingegebenen Sockelbetrag zu fallen.\n\n<strong>Einlage Optimal:</strong>\nDie benötigte jährliche optimale Einlage um nie unter den eingegebenen Sockelbetrag zu fallen. Die Werte sind unterteilt in vor und nach Korrekturzeitraum.\n\n<strong>Einlage gemäss Reglement:</strong>\nDie jährliche Einlage die nach den gesetzlichen Auflagen anfallen würde.',
  'immovable.infoTextNumbersEntered': '<strong>Einlage Aktuell:</strong>\nDer jährliche Einlagenbetrag, den Sie aktuell von Ihren Eigentümern erhalten.\n\n<strong>Sockelbetrag:</strong>\nDer minimale Kontostand unter den Ihr Erneuerungsfonds nie fallen soll.\n\n<strong>Jährlicher Korrekturbetrag:</strong>\nSollte Ihr aktueller Kontostand größer oder kleiner als der optimale Kontostand sein, ergibt sich eine Anpassung über die Anfangsjahre basierend auf dem eingestellten jährlichen Korrekturbetrag, bis der optimale Stand des Erneuerungsfonds erreicht ist.',
  'immovable.condominium': 'Eigentumswohnung',
  'immovable.tower': 'Hochhaus',
  'immovable.house': 'Einfamilienhaus',
  'immovable.stweg': 'Stockwerkeigentumsgemeinschaft',
  'immovable.joint_ownership': 'Miteigentum',
  'component.elevator_stops': 'Aufzug-Stopps',
  'component.electricity_usage': 'Elektrizitätsnutzung',
  'component.quantity': 'Anzahl',
  'component.insurance_value': 'Versicherungswert',
  'component.offer': 'Pauschal (Offerte)',
  'component.estimate': 'Kostenschätzung',
  'component.sqmeter': 'm2-Preis',
  'ifwizard.all_available_components': 'Alle verfügbaren Bauteile',
  'ifwizard.chosen_components': 'Ausgewählte Bauteile',
  'ifwizard.infoTextChosenComponents': 'Die hier aufgeführten Bauteile dienen als Vorlage um Ihnen die Arbeit zu erleichtern. Sobald die Immobilie angelegt wurde, können Sie alle Details der Bauteile anpassen und auch komplett neue Bauteile hinzufügen.',
  'ifwizard.component.type.facade': 'Fassade',
  'ifwizard.component.type.building_isolation': 'Gebäudeisolation',
  'ifwizard.component.type.roof': 'Dach',
  'ifwizard.component.type.electrical_installations': 'Elektroinstallationen',
  'ifwizard.component.type.sanitary_lines': 'Steigleitungen / Sanitärleitungen',
  'ifwizard.component.type.heater': 'Heizung',
  'ifwizard.component.type.windows': 'Fenster (in gemeinschaftl. Räumen)',
  'ifwizard.component.type.stockroom': 'Lagerraum',
  'ifwizard.component.type.common_room': 'Gemeinschaftsraum',
  'ifwizard.component.type.visitor_parking': 'Besucherparkplätze',
  'ifwizard.component.type.shelter': 'Schutzraum',
  'ifwizard.component.type.driveway': 'Zufahrtsweg',
  'ifwizard.component.type.elevator': 'Lift',
  'ifwizard.component.type.bicycle_space': 'Veloabstellplatz',
  'ifwizard.component.type.paintwork': 'Malerarbeiten',
  'ifwizard.component.type.hot_water_boiler': 'Warmwasserboiler',
  'ifwizard.component.type.solar_collector': 'Solaranlage',
  'ifwizard.component.type.water_softening_system': 'Wasserenthärtungsanlage',
  'ifwizard.component.type.washing_machine_dryer': 'Waschmaschine und Trockner',
  'ifwizard.component.type.playground': 'Spielplatz',
  'ifwizard.component.type.winter_garden': 'Wintergarten',
  'ifwizard.component.type.balcony': 'Balkon',
  'ifwizard.component.type.flooring': 'Bodenbelag',
  'ifwizard.component.type.doors': 'Türen',
  'ifwizard.component.type.fence_and_wall': 'Zaun und Mauer',
  'ifwizard.component.type.ventilation_ducts': 'Lüftungskanäle',
  'ifwizard.component.type.slat_blinds': 'Lamellenstoren',
  'ifwizard.component.type.terrace_floor': 'Terrassenboden',
  'ifwizard.component.type.garden': 'Garten',
  'ifwizard.component.type.fireplace': 'Cheminée',
  'ifwizard.component.subtype.small': 'Klein',
  'ifwizard.component.subtype.medium': 'Mittel',
  'ifwizard.component.subtype.big': 'Groß',
  'ifwizard.component.subtype.minor': 'Geringfügig',
  'ifwizard.component.subtype.extensive': 'Großflächig',
  'ifwizard.component.subtype.wood': 'Holz',
  'ifwizard.component.subtype.plastic': 'Kunststoff',
  'ifwizard.component.subtype.low_quality': 'Geringe Qualität',
  'ifwizard.component.subtype.mid_quality': 'Mittlere Qualität',
  'ifwizard.component.subtype.high_quality': 'Hohe Qualität',
  'ifwizard.component.subtype.metal': 'Metall',
  'ifwizard.component.subtype.aluminium': 'Stahl/Aluminium',
  'ifwizard.component.subtype.laminate': 'Laminat',
  'ifwizard.component.subtype.pvc': 'PVC',
  'ifwizard.component.subtype.tiling': 'Platten',
  'ifwizard.component.subtype.parquet': 'Parkett',
  'ifwizard.component.subtype.burner': 'Brenner',
  'ifwizard.component.subtype.heat_pumps': 'Wärmepumpen',
  'ifwizard.component.subtype.steel_boiler': 'Stahlheizkessel',
  'ifwizard.component.subtype.underfloor_heating': 'Fussbodenheizung',
  'ifwizard.component.subtype.radiators': 'Heizkörper/Radiatoren',
  'ifwizard.component.subtype.flat_with_grit': 'Flachdach mit Kies',
  'ifwizard.component.subtype.pitched_with_bricks': 'Schrägdach mit Ziegeln',
  'ifwizard.component.subtype.plastered': 'Verputzt',
  'ifwizard.component.subtype.painted': 'Gestrichen',
  'ifwizard.component.subtype.styrofoam': 'Styropor',
  'ifwizard.component.subtype.mineral_wool_insulation': 'Mineralwolldämmplatten',
  'votes.pageSubTitle': 'Auflistung aller Abstimmungen',
  'votes.begin': 'Beginn',
  'votes.end': 'Ende',
  'votes.addButton': 'Abstimmung Hinzufügen',
  'votes.add': 'Abstimmung hinzufügen',
  'votes.view': 'Abstimmung (Lesemodus)',
  'votes.subtasks.vote': 'Abstimmungspunkte',
  'votes.subtasks.survey': 'Umfragepunkte',
  'votes.task': 'Punkt_',
  'votes.noSubtask.vote': 'Keine Abstimmungspunkte.',
  'votes.noSubtask.survey': 'Keine Umfragepunkte.',
  'votes.itemError': 'Fehler bei der Eingabe',
  'votes.dateStart.vote': 'Beginn der Abstimmung',
  'votes.dateStart.survey': 'Beginn der Umfrage',
  'votes.dateEnd.vote': 'Ende der Abstimmung',
  'votes.dateEnd.survey': 'Ende der Umfrage',
  'votes.taskFileRequired': 'Wählen Sie die richtigen Dateien aus',
  'votes.errorDateStart': 'Anfangsdatum ist erforderlich',
  'votes.errorDateEnd': 'Enddatum ist erforderlich',
  'votes.inserted': 'Abstimmung wurde erfolgreich hinzugefügt.',
  'votes.copied': 'Die Abstimmung wurde erfolgreich kopiert.',
  'votes.selectFiles': 'Dateien auswählen',
  'votes.submittedItems': 'Eingereichte Traktanden',
  'votes.charts': 'Statistik',
  'votes.updated': 'Abstimmung wurde erfolgreich aktualisiert.',
  'votes.updateData': 'Alle Daten aktualisieren (als ob eine neue Abstimmung erstellt würde)?',
  'votes.participantsUpdated': 'Teilnehmer wurden erfolgreich aktualisiert.',
  'votes.participantUpdated': 'Teilnehmer wurde erfolgreich aktualisiert.',
  'votes.pleaseEnterNumber': 'Bitte eine Zahl eingeben.',
  'votes.updateVote.vote': 'Abstimmung aktualisieren',
  'votes.updateVote.survey': 'Umfrage aktualisieren',
  'votes.deleted': 'Abstimmung wurde erfolgreich gelöscht.',
  'votes.choice': 'Auswahl',
  'votes.considerForResults': 'Berücksichtigung für Ergebnisse',
  'votes.consideredYes': 'Berücksichtigt',
  'votes.consideredPending': 'Sobald übermittelt',
  'votes.consideredNo': 'Nicht berücksichtigt',
  'votes.exportParticipants': 'Teilnehmerliste als PDF exportieren',
  'votes.startEditAllFields': 'Alle Felder bearbeiten',
  'votes.endEditAllFields': 'Bearbeitungsmodus beenden',
  'votes.voteResults': 'Ergebnisse',
  'votes.withoutResults': 'Noch ohne Stimmabgabe',
  'votes.activeParticipants': 'Aktive Teilnehmer',
  'votes.allOwnersAndQuotas': 'Teilnehmerliste & Wertquoten',
  'votes.allOwnersAndQuotasFkstwe': 'Mitgliederliste & Wertquoten',
  'votes.valueQuotaPersistentNotice': 'Hinweis: Die eingetragenen Wertquoten werden auch automatisch für zukünftige Abstimmungen berücksichtigt.',
  'votes.relationshipId': 'Eigentümernummer',
  'votes.relationshipIdFkstwe': 'Mitgliedernummer',
  'votes.relationshipIds': 'Eigentümernummern',
  'votes.relationshipIdsFkstwe': 'Mitgliedernummern',
  'votes.relationshipIdAbbr': 'Nr',
  'votes.addObject': 'Objekt hinzufügen',
  'votes.relationshipIdAlreadyInUse': 'Die Eigentümernummer wird bereits verwendet.',
  'votes.considerVoteUserWithoutValidRelId': 'Um diese Stimme für die Ergebnisse berücksichtigen zu können, müssen Sie zunächst eine valide Eigentümernummer vergeben. Klicken Sie dazu rechts auf das Editieren-Icon.',
  'votes.warrant': 'Vollmacht',
  'votes.type.subLabel': 'Geben Sie im Feld unten den Nachname oder die E-Mail-Adresse des Vollmachtempfängers ein. Das System überprüft dann, ob ein Eigentümer mit diesen Daten in der Liegenschaft existiert.',
  'votes.manuallyAdded': 'Manuell hinzugefügt',
  'votes.manuallyAdd': 'Manuell hinzufügen',
  'votes.manuallyEditVoteUser': 'Teilnehmer aktualisieren',
  'votes.manuallyAddVoteUser': 'Teilnehmer manuell hinzufügen',
  'votes.manuallyAddVoteUser.label': 'Was möchten Sie erfassen?',
  'votes.manuallyAddVoteUser.sublabel': 'Bitte zunächst eine Eigentümernummer auswählen.',
  'votes.manuallyAddVoteUser.optionA': 'Stimmabgabe',
  'votes.manuallyAddVoteUser.optionB': 'Vollmacht',
  'votes.manuallyAddParticipant': 'Teilnehmer manuell hinzufügen',
  'votes.voteManuallyAddedSuccess': 'Stimme wurde erfolgreich hinzugefügt.',
  'votes.countNoticeSingular': '{vote_count} Stimme wird nicht berücksichtigt',
  'votes.countNoticePlural': '{vote_count} Stimmen werden nicht berücksichtigt',
  'votes.participantsEligible': 'Berechtigte Teilnehmer',
  'votes.participantsEligibleLong': 'Eigentümergemeinschaften',
  'votes.participantsEligibleLongFkstwe': 'Firmenmitglieder',
  'votes.participantsMore': 'Weitere Teilnehmer',
  'votes.chooseOrAddOwnerTitle': 'Eigentümer',
  'votes.chooseOrAddOwnerText': 'Einen vorhanden Eigentümer auswählen oder neu erfassen.',
  'votes.chooseOrAddQuorumText': 'Vordefiniert oder individuell?',
  'votes.quorumsPredefined': 'Vordefinierte Quoren',
  'votes.predefined': 'Vordefiniert',
  'votes.individual': 'Individuell',
  'votes.majority': 'Mehrheit',
  'votes.majorityAt': 'Mehrheit ab {value}',
  'votes.majorityPercVariable': '{perc} Mehrheit',
  'votes.inclAllTrue': 'Alle Eigentümer',
  'votes.inclAllFalse': 'Nur Anwesende',
  'votes.inclQuotaTrue': 'Mit Wertquote',
  'votes.inclQuotaFalse': 'Ohne Wertquote',
  'votes.inclAbstTrue': 'Mit Enthaltungen',
  'votes.inclAbstFalse': 'Ohne Enthaltungen',
  'votes.inclTieTrue': 'Angenommen bei Gleichstand',
  'votes.inclTieFalse': 'Abgelehnt bei Gleichstand',
  'votes.filterProperty': 'Nach Liegenschaft filtern',
  'votes.quorumMajorityPercentage': 'Benötigte Mehrheit in Prozent',
  'votes.quorumInclTieTitle': 'Gleichstand',
  'votes.quorumInclTieText': 'Bei Gleichstand (z.B. 10 JA und 10 NEIN bei 50% Mehrheit) ist das Traktandum angenommen?',
  'votes.quorumInclAbstTitle': 'Enthaltungen',
  'votes.quorumInclAbstText': 'Enthaltungen als NEIN zählen?',
  'votes.quorumInclAllTitle': 'Alle Eigentümer',
  'votes.quorumInclAllText': 'Alle Eigentümer der Liegenschaft oder nur die Anwesenden berücksichtigen?',
  'votes.quorumInclQuotaTitle': 'Wertquote',
  'votes.quorumInclQuotaText': 'Wertquote für die Auswertung berücksichtigen?',
  'votes.quorumMinParticipantsTitle': 'Mindestteilnehmerzahl',
  'votes.quorumMinParticipantsText': 'Wie viele Stimmen müssen mindestens abgegeben werden?',
  'votes.selectUser': 'Benutzer auswählen',
  'votes.participantsMoreNotice1': 'Über den Haken links können Sie Stimmen für die Auswertung hinzufügen oder entfernen.',
  'votes.participantsMoreNotice2': 'Hinweis: Benutzer ohne aktive ERP-Verbindung werden zunächst nicht automatisch für die Ergebnisse berücksichtigt.',
  'votes.resultDownloadError': 'Interner Fehler. Bitte versuchen Sie es erneut.',
  'votes.userStatusUpdated': 'Benutzerstatus wurde erfolgreich aktualisiert.',
  'votes.voteDeleted': 'Stimme wurde erfolgreich gelöscht.',
  'votes.registeredAndEmailSent': 'Zugangscode versendet',
  'votes.pageSearchbox': 'In Abstimmungen suchen...',
  'votes.votingPossibilityInfo': 'Hinweis: Teilnahme an Abstimmung nur in der ImmoApp möglich, nicht über den Verwaltungsbereich.',
  'votes.planning': 'Planung',
  'votes.beforehand': 'Vorab',
  'votes.live': 'Live',
  'votes.online': 'Online',
  'votes.onlineOnly': 'Nur Online',
  'votes.onlineAndLive': 'Online-Vorabstimmung + Live',
  'votes.liveOnly': 'Nur Live',
  'votes.changeToLive.vote': 'Zur Live-Abstimmung wechseln',
  'votes.changeToLive.survey': 'Zur Live-Umfrage wechseln',
  'votes.changeToLiveConfirm.vote': 'Wenn Sie bestätigen, wird die Online-Vorabstimmung beendet und die Live-Abstimmung gestartet. Dies kann nicht rückgängig gemacht werden.',
  'votes.changeToLiveConfirm.survey': 'Wenn Sie bestätigen, wird die Online-Umfrage beendet und die Live-Umfrage gestartet. Dies kann nicht rückgängig gemacht werden.',
  'votes.finishEarly.vote': 'Abstimmung vorzeitig beenden',
  'votes.finishEarly.survey': 'Umfrage vorzeitig beenden',
  'votes.finishConfirm.vote': 'Sind Sie sicher, dass Sie die Abstimmung beenden möchten?',
  'votes.finishConfirm.survey': 'Sind Sie sicher, dass Sie die Umfrage beenden möchten?',
  'votes.buttonProtocol': 'Protokoll',
  'votes.confirmProtocolFileType': 'Bitte wählen Sie den gewünschten Dateityp aus.',
  'votes.pdfResults': 'Alle Stimmzettel',
  'votes.participatingProperties': 'Teilnehmende Liegenschaften',
  'votes.editPropertiesInfo': 'Hier können Sie die Liegenschaften auswählen, welche für das Traktandum teilnehmen dürfen.',
  'votes.valueQuota': 'Wertquote',
  'votes.yes': 'Ja',
  'votes.no': 'Nein',
  'votes.abstain': 'Enthaltung',
  'votes.notActiveYet': 'Noch nicht aktiv',
  'votes.active.vote': 'Abstimmung läuft...',
  'votes.active.survey': 'Umfrage läuft...',
  'votes.completed': 'Beendet',
  'votes.liveNowNoticeTitle.vote': 'Live-Abstimmung ist aktiv',
  'votes.liveNowNoticeTitle.survey': 'Live-Umfrage ist aktiv',
  'votes.liveFutureNoticeTitle.vote': 'Live-Abstimmung ist geplant',
  'votes.liveFutureNoticeTitle.survey': 'Live-Umfrage ist geplant',
  'votes.beforehandNowNoticeTitle.vote': 'Online-Vorabstimmung ist aktiv',
  'votes.beforehandNowNoticeTitle.survey': 'Online-Umfrage ist aktiv',
  'votes.beforehandFutureNoticeTitle.vote': 'Online-Vorabstimmung ist geplant',
  'votes.beforehandFutureNoticeTitle.survey': 'Online-Umfrage ist geplant',
  'votes.onlineNowNoticeTitle.vote': 'Online-Abstimmung ist aktiv',
  'votes.onlineNowNoticeTitle.survey': 'Online-Umfrage ist aktiv',
  'votes.onlineFutureNoticeTitle.vote': 'Online-Abstimmung ist geplant',
  'votes.onlineFutureNoticeTitle.survey': 'Online-Umfrage ist geplant',
  'votes.finishedNoticeTitle.vote': 'Die Abstimmung ist beendet.',
  'votes.finishedNoticeTitle.survey': 'Die Umfrage ist beendet.',
  'votes.activeNoticeStart.vote': 'Die Abstimmung startet planmässig am {date_start}.',
  'votes.activeNoticeStart.survey': 'Die Umfrage startet planmässig am {date_start}.',
  'votes.finishNoticeText': 'Hinweis: Die ImmoApp unterstützt Sie mit einer standardisierten Auswertung der Stimmabgaben. Bitte überprüfen Sie die Richtigkeit der Ergebnisse sorgfältig.',
  'votes.liveVoteResultsInclude': 'Die Ergebnisse berücksichtigen alle Stimmen mit dem Status "Abgeschlossen" und "Stimmt ab" (Live).',
  'votes.onlineVoteResultsInclude': 'Die Ergebnisse berücksichtigen alle Stimmen mit dem Status "Abgeschlossen".',
  'votes.activeNoticeEnd.vote': 'Die Abstimmung endet planmässig am {date_end}.',
  'votes.activeNoticeEnd.survey': 'Die Umfrage endet planmässig am {date_end}.',
  'votes.pastNoticeEnd.vote': 'Die Abstimmung endete am {date_end}.',
  'votes.pastNoticeEnd.survey': 'Die Umfrage endete am {date_end}.',
  'votes.liveNoticeCode.vote': 'Der Einladungscode für die Live-Abstimmung lautet',
  'votes.liveNoticeCode.survey': 'Der Einladungscode für die Live-Umfrage lautet',
  'votes.mode': 'Modus',
  'votes.modePlaceholder': 'Bitte Abstimmungs-Modus auswählen',
  'votes.itemActiveTitle': 'Traktandum aktiv',
  'votes.itemActiveDescription': 'Wenn Sie das Traktandum beenden, können die Eigentümer keine Stimme mehr abgeben.',
  'votes.newFilesNotice': 'Hinweis: Neue Dateien können via Bearbeitung der Abstimmung hinzugefügt werden.',
  'votes.reactivateVoteItem': 'Traktandum reaktivieren',
  'votes.updateVoteItem': 'Traktandum aktualisieren',
  'votes.finishVoteItem': 'Traktandum beenden',
  'votes.viewVoteItem': 'Traktandum (Lesemodus)',
  'votes.agendaItemUpdated': 'Traktandum wurde erfolgreich aktualisiert.',
  'votes.agendaItemModalHeader': 'Traktandum "{title}"',
  'votes.agendaItemModalNoticeHeader': 'Wählen Sie eine Abstimmung aus zu dem das Traktandum hinzugefügt werden soll.',
  'votes.agendaItemModalNoticeHeaderOLD': 'Sobald Sie das Traktandum zu einer Abstimmung hinzufügen, erhält der Eigentümer eine E-Mail mit dem Hinweis für welche Abstimmung das Traktandum verwendet wird.',
  'votes.quorum': 'Quorum',
  'votes.quorumDropdown': 'Bitte wählen Sie das Quorum aus',
  'votes.simpleMajorityNoAbstention': 'Einfaches Mehr (Enth. wird nicht gezählt)',
  'votes.simpleMajorityWithAbstention': 'Einfaches Mehr (Enth. zählt als Nein)',
  'votes.qualifiedMajorityNoAbstention': 'Qualifiziertes Mehr (Enth. wird nicht gezählt)',
  'votes.qualifiedMajorityWithAbstention': 'Qualifiziertes Mehr (Enth. zählt als Nein)',
  'votes.simpleTotalMajority': 'Einfaches Mehr aller Eigentümer',
  'votes.qualifiedTotalMajority': 'Qualifiziertes Mehr aller Eigentümer',
  'votes.unanimityAttendees': 'Einstimmigkeit der Anwesenden',
  'votes.unanimityTotal': 'Einstimmigkeit aller Eigentümer',
  'votes.accepted': 'Angenommen',
  'votes.rejected': 'Abgelehnt',
  'votes.paused': 'Pausiert',
  'votes.noValidQuorum': 'Nicht Beschlussfähig',
  'votes.validQuorum': 'Beschlussfähig',
  'votes.regulation': 'Reglement',
  'votes.voteCountRule': 'Stimmen Reglement',
  'votes.voteCountDropdown': 'Bitte wählen Sie das Stimmen Reglement aus',
  'votes.countQuota': 'Stimmquote',
  'votes.countQuotaAbbr': 'SQ',
  'votes.valueQuotaAbbr': 'WQ',
  'votes.valueHeadCountAbbr': 'KS',
  'votes.valueObjectCountAbbr': 'OS',
  'votes.voteCount': 'Stimmanzahl',
  'votes.objectName': 'Objektname',
  'votes.headCount': 'Kopfstimme',
  'votes.objectCount': 'Objektstimme',
  'voteResults.quorum': 'Beschlussfähigkeit',
  'votes.setAvailable': 'Aktivieren',
  'votes.setPaused': 'Pausieren',
  'votes.setCompleted': 'Beenden',
  'votes.signature.label': 'Unterschrift benötigt',
  'votes.signature.description': 'Müssen die Teilnehmer vor der Übermittlung ihrer Ergebnisse eine Unterschrift innerhalb der ImmoApp zeichnen? (Findet keine Anwendung bei Live-Abstimmungen.)',
  'votes.visibility.label': 'Abstimmung sichtbar vor Beginn?',
  'votes.visibility.description': 'Soll die Abstimmung auch schon vor dem Beginn in der ImmoApp sichtbar sein?',
  'votes.notification.sublabel': 'Sollen die Eigentümer per Push-Nachricht informiert werden, sobald die Abstimmung beginnt?',
  'votes.errorSelectAllItems': 'Bitte zunächst alle Abstimmungspunkte auswählen.',
  'votes.errorSelectWarrantReceiver': 'Bitte zunächst einen Vollmachtsempfänger auswählen.',
  'voteResults.sublabelUserStatus': 'Benutzerstatus ändern',

  'survey.online': 'Online',
  'survey.selectChoices': 'Auswahlmöglichkeiten angeben',

  'component.calculationBasis': 'Berechnungsgrundlage',
  'component.description': 'Bezeichnung',
  'component.value': 'Wert',
  'component.assessment': 'Einschätzung',
  'component.broken': 'Defekt',
  'component.badlyDamaged': 'Stark beschädigt',
  'component.noticeableDamaged': 'Merkbar beschädigt',
  'component.slightlyDamaged': 'Leicht beschädigt',
  'component.goodCondition': 'Guter Zustand',
  'component.likeNew': 'Neuwertig',
  'component.amortisation': 'Amortisation',
  'component.remainingUsefulAmount': 'Restnutzungsbetrag',
  'component.usefulLife': 'Nutzungsdauer',
  'component.remainingLife': 'Restnutzungsdauer',
  'component.costOverview': 'Kostenübersicht',
  'component.use': 'Nutzung',
  'component.deposit': 'Einlagen',
  'component.currentCoverageAmount': 'Aktueller Deckungsbetrag',
  'component.openCoverageAmount': 'Offener Deckungsbetrag',
  'component.forAction': 'Handlungsbedarf',
  'component.lifeExpectancy': 'Lebenserwartung',
  'component.untilRenewal': 'Bis zur Erneuerung',
  'component.depositOptimalPerYear': 'Einlage Optimal pro Jahr',
  'component.deleted': 'Bauteil wurde erfolgreich gelöscht.',
  'component.updated': 'Bauteil wurde erfolgreich aktualisiert.',
  'component.sublabelComponentStatus': 'Nur aktive Bauteile werden bei der Berechnung berücksichtigt.',
  'component.statusAutomaticAdjustmentTitle': 'Zustand Automatisierung',
  'component.statusAutomaticAdjustmentDescription': 'Zustand automatisch anpassen über Zeit',
  'image.gridView': 'Gitter',
  'image.listView': 'Liste',
  'pdfexport.overviewImmovable': 'Übersicht - Immobilie',
  'pdfexport.overviewCalculations': 'Übersicht - Berechnungen',
  'pdfexport.overviewComponents': 'Übersicht - Bauteile',
  'immoMoveHome.created': 'Erstellt',
  'immoMoveHome.reminded': 'Erinnert',
  'immoMoveHome.accessed': 'Login',
  'immoMoveHome.answered': 'Antwort',
  'immoMoveHome.archived': 'Archiv',
  'immoMoveHome.createNewTerminations': 'Neue Kündigung',
  'immoMoveHome.updateTerminations': 'Kündigung aktualisieren',
  'immoMoveHome.pageSubTitle': 'Alle Kündigungen im Überlick',
  'immoMoveHome.terminations': 'Kündigungen',
  'immoMoveHome.searchTermination': 'In Kündigungen suchen...',
  'immoMoveHome.rating': 'Bewertung',
  'immoMoveHome.civilstand': 'Zivilstand',
  'immoMoveHome.staff': 'Bewirtschafter/in',
  'immoMoveHome.timeCreated': 'Erstellt am',
  'immoMoveHome.newTerminationAdding': 'Kündigung wird erstellt...',
  'immoMoveHome.newTerminationCreated': 'Kündigung wurde erfolgreich hinzugefügt und der Mieter informiert.',
  'immoMoveHome.terminationUpdated': 'Kündigung wurde erfolgreich aktualisiert.',
  'immoMoveHome.terminationDeleted': 'Kündigung wurde erfolgreich gelöscht.',
  'immoMoveHome.emailExists': 'Die Kündigung mit derselben E-Mail-Adresse ist vorhanden. Möchten Sie frühere Benutzerdetails überschreiben?',
  'newTermination.terminationType': 'Art der Kündigung',
  'newTermination.salutation': 'Anrede',
  'newTermination.mieterFirstName': 'Mieter Vorname',
  'newTermination.mieterLastName': 'Mieter Nachname',
  'newTermination.mieterEmail': 'Mieter E-Mail-Adresse',
  'terminationType.regular': 'Terminliche Kündigung',
  'terminationType.irregular': 'Ausserterminliche Kündigung',
  'salutation.male': 'Herr',
  'salutation.female': 'Frau',
  'pinboard.pageSubTitle': 'Ihre Pinnwand im Überblick',
  'pinboard.pageSearchbox': 'In Pinnwand suchen...',
  'pinboard.updatePinboard': 'Eintrag aktualisieren',
  'pinboard.copyPinboard': 'Eintrag kopieren',
  'pinboard.addPinboard': 'Eintrag Hinzufügen',
  'pinboard.viewPinboard': 'Pinnwandeintrag (Lesemodus)',
  'pinboard.inserted': 'Pinnwandeintrag wurde erfolgreich hinzugefügt.',
  'pinboard.updated': 'Pinnwandeintrag wurde erfolgreich aktualisiert.',
  'pinboard.deleted': 'Pinnwandeintrag wurde erfolgreich gelöscht.',
  'process.evaluationHouse': 'Feedback Wohnung',
  'process.evaluationAdmin': 'Feedback Verwaltung',
  'process.resendingAccessCode': 'Zugangscode wird versendet...',
  'process.resendAccessCodeConfirm': 'Zugangscode erneut senden',
  'process.resendAccessCodeSuccess': 'Zugangscode erfolgreich versendet.',
  'process.resendAccessCodeError': 'Fehler beim Versenden des Zugangscodes.',
  'process.resetEmailSuccess': 'Es wurde Ihnen eine E-Mail zum Zurücksetzen Ihres Passworts zugeschickt.',
  'process.resetEmailNotFound': 'Es wurde kein Benutzer mit der eingegebenen E-Mail-Adresse gefunden.',
  'process.resetEmailError': 'Fehler beim Versenden der E-Mail. Bitte versuchen Sie es erneut.',
  'process.overView': 'Übersicht',
  'process.address': 'Adresse',
  'process.invitationSentOn': 'Einladung versendet am',
  'process.lastUpdatedOn': 'Zuletzt aktualisiert am',
  'process.voucherRequestedOn': 'Gutscheine angefordert am',
  'process.attachments': 'Anhänge',
  'process.feedbackManagement': 'Feedback Verwaltung',
  'process.noAnswersYet': 'Noch keine Antwort erhalten...',
  'process.questionnaire': 'Fragebogen',
  'process.replies': 'Antworten',
  'start.home': 'Kündigungen',
  'start.weArePleased': 'Es freut uns sehr, dass Sie sich die Zeit für den digitalen Kündigungsprozess nehmen.',
  'start.clickToSurvey': 'Umfrage starten',
  'start.continueSurvey': 'Umfrage fortsetzen',
  'start.contactUs': 'Bei Fragen stehe ich Ihnen gerne zur Verfügung:',
  'start.greetingsMister': 'Sehr geehrter Herr',
  'start.greetingsMisses': 'Sehr geehrte Frau',
  'questionnaire.almostDone': 'Gleich geschafft',
  'questionnaire.completeTheSurvey': 'Füllen Sie noch die restlichen {remainingCount} Fragen aus um die Umfrage abzuschließen.',
  'questionnaire.submitSurveyText': 'Sie haben alle Fragen beantwortet.',
  'questionnaire.questionsAlreadyAnswered': 'Bereits beantwortete Fragen',
  'questionnaire.updateSurvey': 'Fragebogen aktualisieren',
  'questionnaire.submitSurvey': 'Fragebogen übermitteln',
  'questionnaire.objectQuestions': 'Fragen zur Wohnung',
  'questionnaire.adminQuestions': 'Fragen zur Verwaltung',
  'questionnaire.addDate': 'Termin hinzufügen',
  'questionnaire.addPerson': 'Person hinzufügen',
  'questionnaire.accountOwner': 'Kontoinhaber',
  'questionnaire.bankLocation': 'Ort der Bank',
  'questionnaire.questionNotAnsweredYet': 'Frage wurde noch nicht beantwortet',
  'questionnaire.q1': 'Weshalb kündigen Sie Ihre Wohnung?',
  'questionnaire.q1Note': '(Bitte alle zutreffenden auswählen)',
  'questionnaire.q1heading1': 'Private Gründe:',
  'questionnaire.q1heading2': 'Gründe zum Objekt:',
  'questionnaire.q1heading3': 'Gründe zur Lage:',
  'questionnaire.q1heading4': 'andere Gründe:',
  'questionnaire.professional_change': 'Jobwechsel / Arbeitsweg',
  'questionnaire.need_smaller_apartment': 'Bedarf kleinere Wohnung',
  'questionnaire.need_larger_apartment': 'Bedarf grössere Wohnung',
  'questionnaire.homestead': 'Eigenheim',
  'questionnaire.separation': 'Trennung',
  'questionnaire.moving_in_together': 'Zusammenzug (Ehe-)Partner',
  'questionnaire.children_born': 'Nachwuchs',
  'questionnaire.death': 'Todesfall',
  'questionnaire.facilities_dated': 'Ausstattung veraltet',
  'questionnaire.existing_flaw': 'bestehender Mangel:',
  'questionnaire.inconvenient_layout': 'unpraktischer Grundriss',
  'questionnaire.not_age_appropriate': 'nicht altersgerecht',
  'questionnaire.facilities_missing': 'fehlende Ausstattung:',
  'questionnaire.high_house_rent': 'Mietzins zu hoch',
  'questionnaire.poor_appearance': 'mangelhaftes Erscheinungsbild',
  'questionnaire.noise_immission': 'Lärmimmissionen',
  'questionnaire.not_child_friendly': 'nicht kinderfreundlich',
  'questionnaire.no_parking': 'kein Parkplatz',
  'questionnaire.no_visitor_parking': 'keine/zu wenig Besucherparkplätze',
  'questionnaire.distance_main_roads': 'grosse Distanz zu Hauptstrassen/Autobahnen',
  'questionnaire.bad_connection_public_transport': 'ungünstige Anbindung an die ÖV',
  'questionnaire.distance_groceries': 'keine nahen Einkaufsmöglichkeiten',
  'questionnaire.discrepancies_within_neighborhood': 'Diskrepanzen innerhalb der Nachbarschaft',
  'questionnaire.discrepancies_with_janitor': 'Diskrepanzen mit dem Hauswart',
  'questionnaire.discrepancies_with_admin': 'Diskrepanzen mit der Verwaltung/Eigentümer',
  'questionnaire.q2': 'Was hat Ihnen an Ihrer Wohnung besonders gut gefallen?',
  'questionnaire.q2Note': '(Bitte alle zutreffenden auswählen)',
  'questionnaire.q2heading1': 'am Objekt:',
  'questionnaire.q2heading2': 'an der Lage:',
  'questionnaire.good_facilities': 'gute Ausstattung',
  'questionnaire.convenient_layout': 'praktischer Grundriss',
  'questionnaire.age_appropriate': 'altersgerecht',
  'questionnaire.fair_house_rent': 'fairer Mietzins',
  'questionnaire.child_friendly': 'kinderfreundlich',
  'questionnaire.pleasant_neighborhood': 'angenehme Nachbarschaft',
  'questionnaire.reliable_janitor': 'zuverlässiger Hauswart',
  'questionnaire.competent_administration': 'kompetente Verwaltung',
  'questionnaire.quiet_area': 'ruhige Wohnlage',
  'questionnaire.proximity_main_roads': 'Nähe zu Hauptstrassen/Autobahnen',
  'questionnaire.good_connection_public_transport': 'gute Anbindung an die ÖV',
  'questionnaire.proximity_groceries': 'nahe Einkaufsmöglichkeiten',
  'questionnaire.q3': 'Wünschen Sie unsere Hilfe bei der Suche nach einem Nachmieter? Gerne schalten wir für Sie ein Internetinserat für eine Pauschale über CHF 100.00 (inkl. MWST) zuzüglich CHF 6.00 (exkl. MWST) pro Tag für ein Flatfox-Inserat oder zuzüglich CHF 14.00 (exkl. MWST) pro Tag für ein Inserat auf Flatfox und Homegate.',
  'questionnaire.q3o1': 'Ja, Flatfox',
  'questionnaire.q3o2': 'Ja, Flatfox und Homegate',
  'questionnaire.q4': '<span>Für die Wiedervermietung sind wir auf Ihre Hilfe angewiesen. </span>Dürfen wir Ihre Telefonnummer den Interessenten für die Wohnungsbesichtigung bekannt geben und falls ja, welche?',
  'questionnaire.q5': 'Alternativ können auch fixe Besichtigungstermine festgelegt werden. Bitte geben Sie uns einige zeitnahe Terminvorschläge bekannt:',
  'questionnaire.q5Note': '(Definitiv kommunizierte Termine werden wir Ihnen vorab bestätigen.)',
  'questionnaire.q6': 'Wann kann Ihre Wohnung durch die Verwaltung für die Aufnahme von allfälligen Fotos und Mängeln demnächst vorbesichtigt werden? Bitte geben Sie uns einige zeitnahe Terminvorschläge bekannt:',
  'questionnaire.q6Note': '(Den definitiven Termin werden wir Ihnen noch bestätigen.)',
  'questionnaire.q7': 'Wie lautet Ihre neue Adresse?',
  'questionnaire.q8': 'Wie lautet Ihre aktuelle Bankverbindung?',
  'questionnaire.q9': 'Welche Personen sind aktuell in der Wohnung gemeldet?',
  'questionnaire.q10': 'Wie lautet Ihr aktueller Zivilstand?',
  'questionnaire.single': 'ledig',
  'questionnaire.married': 'verheiratet / eingetragene Partnerschaft',
  'questionnaire.judicially_separated': 'gerichtlich getrennt',
  'questionnaire.divorced': 'geschieden',
  'questionnaire.widowed': 'verwitwet',
  'questionnaire.q11': 'Welcher TV-/Internetanschluss wurde durch Sie genutzt?',
  'questionnaire.none': 'Nein',
  'questionnaire.swisscom': 'Swisscom',
  'questionnaire.upc_cablecom': 'UPC Cablecom',
  'questionnaire.sunrise': 'Sunrise',
  'questionnaire.salt': 'Salt',
  'questionnaire.glattnet': 'Glattnet',
  'questionnaire.gga_maur': 'GGA Maur',
  'questionnaire.lkw': 'LKW',
  'questionnaire.ew_rumlang': 'EW Rümlang',
  'questionnaire.q12': 'Haben Sie bereits einen Wunschtermin für Ihre Wohnungsabgabe?',
  'questionnaire.q13': 'Sind Ihnen Mängel bzw. Reparaturarbeiten in Ihrer Wohnung bekannt, welche durch die Verwaltung beauftragt werden sollten?',
  'questionnaire.13o1': 'Ja, folgende Mängel:',
  'questionnaire.13o2': 'Keine bekannt',
  'questionnaire.q14': 'Wie empfanden Sie die Zusammenarbeit mit<span> der</span> {administrationName} bzw. mit Ihrer Bewirtschafterin / Ihrem Bewirtschafter?',
  'questionnaire.14o1': 'Erreichbarkeit:',
  'questionnaire.14o2': 'Freundlichkeit:',
  'questionnaire.14o3': 'Reaktionszeit:',
  'questionnaire.14o4': 'Fachkompetenz:',
  'questionnaire.q15': 'Haben Sie Verbesserungsvorschläge für<span> die</span> {administrationName}?',
  'questionnaire.15o1': 'Ja, folgende Vorschläge:',
  'questionnaire.q16': 'Würden Sie sich freuen, wieder eine von {administrationName} bewirtschaftete Wohnung zu mieten?',
  'questionnaire.q17': 'Haben Sie ein persönliches Feedback an<span> die</span> {administrationName} bzw. an Ihre zuständige Bewirtschafterin oder Ihren zuständigen Bewirtschafter?',
  'questionnaire.17o1': 'Ja, folgendes Feedback:',
  'questionnaire.17o2': 'Nein',
  'questionnaire.q18': 'Sofern Sie auf Wohnungssuche sind, helfen wir Ihnen gerne, indem wir Ihrem potenziell neuen Vermieter eine Referenzauskunft über das bestehende Mietverhältnis geben. Dafür benötigen wir Ihre Einwilligung.',
  'questionnaire.18o1': 'Einwilligung für Referenzauskunft erteilen',
  'questionnaire.18o1a': 'Einwilligung für Referenzauskunft erteilt',
  'questionnaire.18o2': 'Nein Danke',
  'questionnaire.nothingKnown': 'Keine bekannt',
  'questionnaire.notKnownYet': 'Noch nicht bekannt',
  'questionnaire.other': 'sonstige:',
  'questionnaire.informingLater': 'Angaben folgen zu späterem Zeitpunkt.',
  'questionnaire.answersUploadedToDatabase': 'Ihre Antworten wurden erfolgreich übermittelt.',
  'questionnaire.answerError': 'Bitte beantworten Sie zunächst alle Fragen.',
  'questionnaire.errorPrivacyPolicy': 'Bitte lesen und akzeptieren Sie zunächst die Datenschutzerklärung.',
  'questionnaire.ratingModalTitle': 'Herzlichen Dank',
  'questionnaire.ratingModalBody': 'Schön, dass Sie mit uns zufrieden sind! Eine positive Bewertung bei Google würde uns sehr freuen.',
  'questionnaire.ratingModalButton': 'Link zu Google',
  'questionnaire.bottomPopupText': 'Beantwortet',
  'end.thankYou': 'Vielen Dank',
  'end.usefulDocumentsText': 'Im unteren Bereich dieser Seite finden Sie einige nützliche Dokumente für Ihren Auszug. Wir empfehlen Ihnen, diese herunterzuladen. Ansonsten können Sie sich jederzeit erneut auf dieser Webseite einloggen und darauf zugreifen.',
  'end.voucherText': 'Als kleines Dankeschön für Ihre Bemühungen bieten wir Ihnen Gutscheine für einen erfolgreichen Umzug.',
  'end.requestVoucherButton': 'Gutscheine anfordern',
  'end.requestVoucherText': 'Möchten Sie die Gutscheine anfordern?',
  'end.remainingAnswers': 'Offene Fragen beantworten ({questionsCount})',
  'end.deferredQuestions': 'Zurückgestellte Fragen beantworten',
  'end.send': 'Senden',
  'end.logoutConfirmText': 'Sind Sie sicher, dass Sie sich abmelden möchten?',
  'end.voucherRequestSending': 'Anfrage wird übermittelt...',
  'end.voucherRequestSent': 'Gutscheine wurden erfolgreich angefordert.',
  'end.answerDeferredQuestions': 'Möchten Sie Ihre noch offenen Fragen jetzt beantworten?',
  'end.allQuestionsAnswered': 'Sie haben alle Fragen beantwortet.',
  'end.timeVoucherRequested': 'Sie haben die Gutscheine am {timeVoucherRequested} angefordert. Ihr Ansprechpartner wird sich in Kürze mit den Gutscheinen bei Ihnen per E-Mail melden.',
  'document.pageSubtitle': 'Ihre Dokumente im Überblick',
  'document.fileSize': 'Dateigröße',
  'document.rootFolder': 'Hauptverzeichnis',
  'document.disabledInParentFolder': 'In einem Überordner deaktiviert',
  'document.inactiveMandate': 'Inaktive Immobilie - Nur sichtbar für Admins',
  'document.personalFolder': 'Persönlicher Ordner',
  'statistics.tabImmoApp': 'ImmoApp',
  'statistics.tabImmoMove': 'ImmoMove',
  'statistics.totalCreatedProcesses': 'Erstellte Kündigungsprozesse',
  'statistics.totalFinishedProcesses': 'Abgeschlossene Kündigungsprozesse',
  'statistics.rating': '{rating} von 5',
  'statistics.totalRating': 'Eine Bewertung erhalten',
  'statistics.totalRatings': '{count} Bewertungen erhalten',
  'statistics.evaluationAdministration': 'Auswertung Verwaltung',
  'statistics.evaluationObject': 'Auswertung Objekt',
  'statistics.filterObjects': 'Nach Adressen filtern',
  'statistics.filterStaffMember': 'Nach Bewirtschafter filtern',
  'statistics.suggestions': 'Verbesserungsvorschläge',
  'statistics.feedback': 'Persönliches Feedback',
  'statistics.linkTerminationProcess': 'Zum Kündigungsprozess',
  'statistics.users_30days': 'Benutzer (aktiv in letzten 30 Tagen)',
  'statistics.users_365days': 'Benutzer (aktiv in letzten 365 Tagen)',
  'statistics.users_total': 'Benutzer (insgesamt)',
  'statistics.communication_emails_7days': 'E-Mails (in letzten 7 Tagen)',
  'statistics.communication_push_7days': 'Push (in letzten 7 Tagen)',
  'statistics.communication_docs_7days': 'Dok.zugriffe (in letzten 7 Tagen)',
  'statistics.content_messages_7days': 'Nachrichten (in letzten 7 Tagen)',
  'statistics.content_repairs_7days': 'Schad.meldungen (in letzten 7 Tagen)',
  'statistics.content_pinboards_7days': 'Pinnwandeinträge (in letzten 7 Tagen)',
  'statistics.agreeing': 'Zustimmend',
  'statistics.refusing': 'Ablehnend',
  'statistics.undecided': 'Unentschieden',
  'license.limit': 'Abo-Kontingent',
  'license.limitExceededTitle': 'Abo-Kontingent überschritten',
  'license.limitExceededText1': 'Sie nutzen aktuell {activeObjects} von {allowedObjects} gebuchten Objekten.',
  'license.limitExceededText2': 'Bitte reduzieren Sie die Anzahl der Objekte durch Deaktivierung von einzelnen Immobilien oder kontaktieren Sie uns, um eine Erhöhung des Abo-Kontingents zu beantragen.',

  STARTING_FORMGUIDE_KEYS: '---',

  'FORM.GO_TO_FORM': 'Direkt zum Formular',
  'FORM.REPAIR.TITLE.WHERE': 'Wo muss repariert werden?',
  'FORM.REPAIR.TITLE.WHAT': 'Was ist defekt?',
  'FORM.REPAIR.DETAILS.TITLE': 'Schadensbestimmung',
  'FORM.REPAIR.APARTMENT': 'Wohnung',
  'FORM.REPAIR.APARTMENT.DESCRIPTION': 'Küche, Bad, Wohnzimmer',
  'FORM.REPAIR.COMMONSPACE': 'Gemeinanlagen',
  'FORM.REPAIR.COMMONSPACE.DESCRIPTION': 'Lift, Garage, Keller',
  'FORM.REPAIR.COMMERCIAL': 'Gewerbe',
  'FORM.REPAIR.COMMERCIAL.DESCRIPTION': 'Büro, Shops, Gastro',
  'FORM.REPAIR.EXTERIOR': 'Aussenbereich',
  'FORM.REPAIR.EXTERIOR.DESCRIPTION': 'Grünanlagen, Fassade',

  'FORM.REPAIR.1X': '1',
  'FORM.REPAIR.2X': '2',
  'FORM.REPAIR.3X': '3',
  'FORM.REPAIR.4X': '4',
  'FORM.REPAIR.5X': '5',
  'FORM.REPAIR.6X': '6',
  'FORM.REPAIR.7X': '7',
  'FORM.REPAIR.8X': '8',
  'FORM.REPAIR.ALL': 'Alle',
  'FORM.REPAIR.APARTMENT.WHOLE': 'Gesamte Wohnung',
  'FORM.REPAIR.APARTMENTDOOR': 'Wohnungstüre',
  'FORM.REPAIR.ATTIC': 'Estrich',
  'FORM.REPAIR.ATTICDOOR': 'Estrichtüre',
  'FORM.REPAIR.ATTICLADDER': 'Estrichklappe, -leiter',
  'FORM.REPAIR.ATTICLADDER.BROKEN': 'Gebrochen',
  'FORM.REPAIR.ATTICLADDER.CEILINGFLAP': 'Deckenlappe',
  'FORM.REPAIR.ATTICLADDER.CEILINGFLAP.CROOKED': 'Schief, schräg',
  'FORM.REPAIR.ATTICLADDER.CEILINGFLAP.HINGEDEFECT': 'Scharniere defekt, ausgerissen',
  'FORM.REPAIR.ATTICLADDER.FOLD': 'Estrich&shy;klappen, ausklappen',
  'FORM.REPAIR.ATTICLADDER.FOLD.NOTCLOSES': 'Schliesst schlecht',
  'FORM.REPAIR.ATTICLADDER.FOLD.NOTOPENS': 'Öffnet nicht richtig',
  'FORM.REPAIR.ATTICLADDER.FOLD.DIFFICULTY': 'Öffnet, schliesst schwer',
  'FORM.REPAIR.ATTICLADDER.GRINDS': 'Streift, steht an',
  'FORM.REPAIR.ATTICLADDER.HANDRAIL': 'Handlauf',
  'FORM.REPAIR.ATTICLADDER.LOCK': 'Schloss, Verschluss',
  'FORM.REPAIR.ATTICLADDER.LOCK.NOTCLOSES': 'Bleibt nicht zu',
  'FORM.REPAIR.ATTICLADDER.LOCK.TORN': 'Ausgerissen',
  'FORM.REPAIR.ATTICLADDER.LOOSE': 'Locker, wackelt',
  'FORM.REPAIR.ATTICLADDER.STAIRS': 'Leiter, Treppe',
  'FORM.REPAIR.ATTICLADDER.STAIRS.ROPETORN': 'Drahtseil gerissen',
  'FORM.REPAIR.ATTICLADDER.STAIRS.SPRINGDEFECT': 'Feder defekt',
  'FORM.REPAIR.ATTICLADDER.STAIRS.STEPLOOSE': 'Stufe lose, locker',
  'FORM.REPAIR.ATTICLADDER.STAIRS.TORN': 'Stufe ausgerissen',
  'FORM.REPAIR.BALCONY': 'Balkon',
  'FORM.REPAIR.BALCONY.BALCONYDOOR': 'Balkon-, Terrassen&shy;türe',
  'FORM.REPAIR.BATHROOM': 'Badezimmer',
  'FORM.REPAIR.BATHROOM.FURNITURE': 'Badezimmer&shy;möbel',
  'FORM.REPAIR.BATHROOM.FURNITURE.BROKEN': 'Gebrochen, gesprungen',
  'FORM.REPAIR.BATHROOM.FURNITURE.CISTERN': 'Spülkasten&shy;möbel defekt',
  'FORM.REPAIR.BATHROOM.FURNITURE.EDGE': 'Kante des Waschtischs',
  'FORM.REPAIR.BATHROOM.FURNITURE.EDGE.CRACK': 'Riss',
  'FORM.REPAIR.BATHROOM.FURNITURE.EDGE.FRAYED': 'Ausgefranst',
  'FORM.REPAIR.BATHROOM.FURNITURE.EDGE.PEELING': 'Ablösende Beschichtung',
  'FORM.REPAIR.BATHROOM.FURNITURE.LOOSE': 'Locker, wackelt',
  'FORM.REPAIR.BATHROOM.FURNITURE.MIRROR': 'Spiegel',
  'FORM.REPAIR.BATHROOM.FURNITURE.MIRROR.FALLEN': 'Abgefallen',
  'FORM.REPAIR.BATHROOM.FURNITURE.MIRROR.SCRATCHED': 'Verkratzt',
  'FORM.REPAIR.BATHROOM.FURNITURE.MIRROR.STAINS': 'Hat Flecken, ist angelaufen',
  'FORM.REPAIR.BATHROOM.FURNITURE.MIRRORCABINET': 'Spiegel&shy;schrank',
  'FORM.REPAIR.BATHROOM.FURNITURE.MIRRORCABINET.LAMPCRACKS': 'Lampe knackt',
  'FORM.REPAIR.BATHROOM.FURNITURE.MIRRORCABINET.LAMPDEFECT': 'Leuchtmittel defekt',
  'FORM.REPAIR.BATHROOM.FURNITURE.MIRRORCABINET.LIGHTSHIELDDEFECT': 'Lichtblenden defekt',
  'FORM.REPAIR.BATHROOM.FURNITURE.MIRRORCABINET.SHELFDEFECT': 'Tablar defekt',
  'FORM.REPAIR.BATHROOM.FURNITURE.MIRRORCABINET.SHELFHOLDERDEFECT': 'Tablarhalter defekt',
  'FORM.REPAIR.BATHROOM.FURNITURE.MIRRORCABINET.SHELFMISSING': 'Tablar fehlt',
  'FORM.REPAIR.BATHROOM.FURNITURE.MIRRORCABINET.SOCKETDEFECT': 'Steckdose defekt',
  'FORM.REPAIR.BATHROOM.FURNITURE.MIRRORCABINETDOOR': 'Spiegel&shy;schrank&shy;türe',
  'FORM.REPAIR.BATHROOM.FURNITURE.MIRRORCABINETDOOR.HANDLEDEFECT': 'Griff defekt',
  'FORM.REPAIR.BATHROOM.FURNITURE.MIRRORCABINETDOOR.HANGSDOWN': 'Hängt runter',
  'FORM.REPAIR.BATHROOM.FURNITURE.MIRRORCABINETDOOR.HINGEDEFECT': 'Scharniere defekt, ausgerissen',
  'FORM.REPAIR.BATHROOM.FURNITURE.MIRRORCABINETDOOR.HINGEFALLEN': 'Scharniere abgefallen',
  'FORM.REPAIR.BATHROOM.FURNITURE.MIRRORCABINETDOOR.RUBBERMISSING': 'Gummipuffer fehlt',
  'FORM.REPAIR.BATHROOM.FURNITURE.SINK': 'Waschbecken&shy;möbel',
  'FORM.REPAIR.BATHROOM.FURNITURE.SINK.CHAFINGPOINT': 'Scheuerstelle',
  'FORM.REPAIR.BATHROOM.FURNITURE.SINK.HOLE': 'Loch',
  'FORM.REPAIR.BATHROOM.FURNITURE.SINK.STAINS': 'Flecken, Verfärbung',
  'FORM.REPAIR.BATHROOM.FURNITURE.SWOLLEN': 'Aufgequollen',
  'FORM.REPAIR.BATHROOM.TOILET': 'WC, Toilette',
  'FORM.REPAIR.BATHROOM.TOILET.BOWL': 'Toiletten&shy;schüssel',
  'FORM.REPAIR.BATHROOM.TOILET.BOWL.HANDRAILDEFECT': 'Haltestange wackelt, ausgerissen',
  'FORM.REPAIR.BATHROOM.TOILET.BOWL.ROLLHOLDERDEFECT': 'WC-Papierhalter defekt',
  'FORM.REPAIR.BATHROOM.TOILET.BROKEN': 'Gebrochen, gesprungen',
  'FORM.REPAIR.BATHROOM.TOILET.DRAIN': 'Spülen, Abfliessen, etc.',
  'FORM.REPAIR.BATHROOM.TOILET.DRAIN.CLOGGED': 'Verstopft',
  'FORM.REPAIR.BATHROOM.TOILET.DRAIN.DRAINSSLOW': 'Läuft langsam ab',
  'FORM.REPAIR.BATHROOM.TOILET.DRAIN.LITTLEPRESSURE': 'Wenig Wasserdruck',
  'FORM.REPAIR.BATHROOM.TOILET.DRAIN.NOWATER': 'Kein oder wenig Wasser',
  'FORM.REPAIR.BATHROOM.TOILET.DRAIN.OVERFLOW': 'Läuft über',
  'FORM.REPAIR.BATHROOM.TOILET.DRAIN.SEWAGE': 'Verschmutztes Wasser',
  'FORM.REPAIR.BATHROOM.TOILET.DRAIN.WATERRINSEDEFECT': 'Wasserspülung rinnt',
  'FORM.REPAIR.BATHROOM.TOILET.LOOSE': 'Locker, wackelt',
  'FORM.REPAIR.BATHROOM.TOILET.SEAT': 'Toilettensitz',
  'FORM.REPAIR.BATHROOM.SHOWER': 'Badewanne, Dusche',
  'FORM.REPAIR.BATHROOM.SHOWER.BACKWATER': 'Abwasser drückt aus Abfluss',
  'FORM.REPAIR.BATHROOM.SHOWER.BATHTUB': 'Badewanne',
  'FORM.REPAIR.BATHROOM.SHOWER.CHALKY': 'Kalkig',
  'FORM.REPAIR.BATHROOM.SHOWER.CLOGGED': 'Verstopft',
  'FORM.REPAIR.BATHROOM.SHOWER.CRACK': 'Riss',
  'FORM.REPAIR.BATHROOM.SHOWER.CURTAIN': 'Vorhang, Duschkabine',
  'FORM.REPAIR.BATHROOM.SHOWER.CURTAIN.RODDEFECT': 'Vorhangstange defekt',
  'FORM.REPAIR.BATHROOM.SHOWER.CURTAIN.GLIDERDEFECT': 'Vorhanggleiter defekt',
  'FORM.REPAIR.BATHROOM.SHOWER.CURTAIN.CUBICLEDEFECT': 'Duschkabine defekt',
  'FORM.REPAIR.BATHROOM.SHOWER.CURTAIN.SEALDEFECT': 'Gummidichtung Türe defekt',
  'FORM.REPAIR.BATHROOM.SHOWER.DIRTY': 'Dreckig',
  'FORM.REPAIR.BATHROOM.SHOWER.FITTINGS': 'Armaturen',
  'FORM.REPAIR.BATHROOM.SHOWER.HANDRAIL': 'Haltestange',
  'FORM.REPAIR.BATHROOM.SHOWER.HANDRAIL.BROKEN': 'Gebrochen, gesprungen',
  'FORM.REPAIR.BATHROOM.SHOWER.HANDRAIL.TOINSTALL': 'Zu installieren',
  'FORM.REPAIR.BATHROOM.SHOWER.HOLE': 'Loch',
  'FORM.REPAIR.BATHROOM.SHOWER.JOINT': 'Fugen',
  'FORM.REPAIR.BATHROOM.SHOWER.JOINT.DISSOLVES': 'Löst sich',
  'FORM.REPAIR.BATHROOM.SHOWER.JOINTDEFECT': 'Silikonfuge undicht',
  'FORM.REPAIR.BATHROOM.SHOWER.LEAKY': 'Undicht',
  'FORM.REPAIR.BATHROOM.SHOWER.LOOSE': 'Locker, wackelt',
  'FORM.REPAIR.BATHROOM.SHOWER.MOLDY': 'Schimmlig',
  'FORM.REPAIR.BATHROOM.SHOWER.NOISE': 'Gluckert / Geräusche',
  'FORM.REPAIR.BATHROOM.SHOWER.NOTDRAINING': 'Läuft kaum ab',
  'FORM.REPAIR.BATHROOM.SHOWER.QUIRK': 'Macke',
  'FORM.REPAIR.BATHROOM.SHOWER.SHOWERHEAD': 'Brause, Schlauch',
  'FORM.REPAIR.BATHROOM.SHOWER.SHOWERHEAD.DRIPS': 'Tropft',
  'FORM.REPAIR.BATHROOM.SHOWER.SHOWERHEAD.SPLASHES': 'Spritzt',
  'FORM.REPAIR.BATHROOM.SHOWER.SHOWERTRAY': 'Duschwanne',
  'FORM.REPAIR.BATHROOM.SHOWER.SMELLS': 'Stinkt',
  'FORM.REPAIR.BATHROOM.SHOWER.STAINS': 'Hat Flecken, ist angelaufen',
  'FORM.REPAIR.BATHROOM.SHOWER.WORN': 'Abgenutzt',
  'FORM.REPAIR.BEDROOM': 'Schlafzimmer',
  'FORM.REPAIR.BENCH': 'Parkbank',
  'FORM.REPAIR.BENCH.LOOSE': 'Locker, wackelt',
  'FORM.REPAIR.BENCH.DIRTY': 'Verdreckt, abgenutzt',
  'FORM.REPAIR.BENCH.FALLINGAPART': 'Fällt auseinander',
  'FORM.REPAIR.BIKEROOM': 'Veloraum',
  'FORM.REPAIR.BIKESTAND': 'Veloständer',
  'FORM.REPAIR.BIKESTAND.DEFECT': 'Defekt',
  'FORM.REPAIR.BIKESTAND.LOOSE': 'Locker, wackelt',
  'FORM.REPAIR.BIKESTAND.MISSING': 'Fehlt',
  'FORM.REPAIR.BIKESHELTER': 'Velounterstand, -box',
  'FORM.REPAIR.BIKESHELTER.BIKEBOX': 'Velo&shy;unterstand',
  'FORM.REPAIR.BIKESHELTER.BIKEBOX.BROKENROOF': 'Dach defekt',
  'FORM.REPAIR.BIKESHELTER.BIKEBOX.DOORDEFECT': 'Türe defekt',
  'FORM.REPAIR.BIKESHELTER.BIKEBOX.LOOSE': 'Locker, wackelt',
  'FORM.REPAIR.BIKESHELTER.BIKEBOX.NOTDRAINING': 'Regenwasser läuft nicht ab',
  'FORM.REPAIR.BOILERROOM': 'Heizraum',
  'FORM.REPAIR.BUILDINGENTRANCE': 'Hauseingang',
  'FORM.REPAIR.CABINET': 'Einbauschrank',
  'FORM.REPAIR.CABINET.BASE': 'Sockel',
  'FORM.REPAIR.CABINET.BASE.BROKEN': 'Gebrochen, gesprungen',
  'FORM.REPAIR.CABINET.BASE.JOINTLEAKS': 'Fuge undicht, löst sich',
  'FORM.REPAIR.CABINET.BRACKETDEFECT': 'Halterung defekt',
  'FORM.REPAIR.CABINET.BRACKETMISSING': 'Halterung fehlt',
  'FORM.REPAIR.CABINET.DOOR': 'Schranktüre',
  'FORM.REPAIR.CABINET.DOOR.EDGESDEFECT': 'Kanten defekt',
  'FORM.REPAIR.CABINET.DOOR.EDGESMISSING': 'Kante fehlt, abgebrochen',
  'FORM.REPAIR.CABINET.DOOR.GRINDS': 'Streift, steht an',
  'FORM.REPAIR.CABINET.DOOR.HINGEBROKEN': 'Scharniere defekt, ausgerissen',
  'FORM.REPAIR.CABINET.DRAWER': 'Schublade',
  'FORM.REPAIR.CABINET.DRAWER.NOTOPEN': 'Öffnet nicht richtig',
  'FORM.REPAIR.CABINET.DRAWER.REMAINSOPEN': 'Schliesst schlecht',
  'FORM.REPAIR.CABINET.DRAWER.RUSTED': 'Verrostet',
  'FORM.REPAIR.CABINET.DRAWER.WORN': 'Zerschlissen',
  'FORM.REPAIR.CABINET.HANDLEDEFECT': 'Griff defekt',
  'FORM.REPAIR.CABINET.HANGSDOWN': 'Hängt runter',
  'FORM.REPAIR.CABINET.LOOSE': 'Locker, wackelt',
  'FORM.REPAIR.CABINET.MISSING': 'Fehlt',
  'FORM.REPAIR.CABINET.MOLD': 'Schimmlig',
  'FORM.REPAIR.CABINET.RAIL': 'Kleiderstange',
  'FORM.REPAIR.CABINET.RAIL.DEFECT': 'Defekt',
  'FORM.REPAIR.CABINET.SHELF': 'Tablar',
  'FORM.REPAIR.CABINET.SHELF.CROOKED': 'Krumm',
  'FORM.REPAIR.CABINET.SHELF.PEELING': 'Ablösende Beschichtung',
  'FORM.REPAIR.CABINET.SHELF.WORN': 'Abgenutzt',
  'FORM.REPAIR.CABINET.SWOLLEN': 'Aufgequollen',
  'FORM.REPAIR.CABINET.WALLSFLOOR': 'Wände, Boden, ...',
  'FORM.REPAIR.CABINET.WALLSFLOOR.REARWALLBROKEN': 'Rückwand gebrochen',
  'FORM.REPAIR.CABINET.WALLSFLOOR.REARWALLBACK': 'Rückwand nach hinten gedrückt',
  'FORM.REPAIR.CABINET.WALLSFLOOR.WORN': 'Abgenutzt',
  'FORM.REPAIR.CANOPY': 'Vordach',
  'FORM.REPAIR.CANOPY.GLASSBROKEN': 'Glasvordach kaputt',
  'FORM.REPAIR.CANOPY.NOTDRAINING': 'Regenwasser läuft nicht ab',
  'FORM.REPAIR.CANOPY.OVERFLOWS': 'Regenwasser läuft über',
  'FORM.REPAIR.CEILING': 'Decke',
  'FORM.REPAIR.CELLAR': 'Keller',
  'FORM.REPAIR.CELLAR.COMPARTMENTDOOR': 'Keller-Abteiltüre',
  'FORM.REPAIR.CELLARCOMPARTMENT': 'Kellerabteil',
  'FORM.REPAIR.CELLARDOOR': 'Kellertüre',
  'FORM.REPAIR.CLEANING': 'Reinigung',
  'FORM.REPAIR.COLORDISSOLVING': 'Farbe löst sich',
  'FORM.REPAIR.COMMONSPACE.OTHER': 'Andere Ort',
  'FORM.REPAIR.CONTAINERROOM': 'Container&shy;raum',
  'FORM.REPAIR.CORRIDOR.APARTMENT': 'Flur, Wohnungs&shy;eingang',
  'FORM.REPAIR.CORRIDOR.COMMERCIAL': 'Flur, Eingangs&shy;bereich',
  'FORM.REPAIR.CRACK': 'Riss',
  'FORM.REPAIR.DAMAGED': 'Beschädigt',
  'FORM.REPAIR.DISPLAYDEFECT': 'Display defekt',
  'FORM.REPAIR.DRAIN': 'Bodenabfluss Garage',
  'FORM.REPAIR.DOOR': 'Zimmertüre',
  'FORM.REPAIR.DOOR.OPENINGCLOSING': 'Öffnen & schliessen',
  'FORM.REPAIR.DOOR.OPENINGCLOSING.BADCLOSING': 'Schliesst schlecht',
  'FORM.REPAIR.DOOR.OPENINGCLOSING.DIFFICULTCLOSING': 'Öffnet, schliesst schwer',
  'FORM.REPAIR.DOOR.OPENINGCLOSING.GRINDS': 'Streift, steht an',
  'FORM.REPAIR.DOOR.OPENINGCLOSING.REMAINSOPEN': 'Bleibt nicht zu',
  'FORM.REPAIR.DOOR.DOOR': 'Türe',
  'FORM.REPAIR.DOOR.DOOR.BROKEN': 'Aufgebrochen',
  'FORM.REPAIR.DOOR.DOOR.GLASSPANELDEFECT': 'Glasscheibe defekt',
  'FORM.REPAIR.DOOR.DOOR.HINGEBROKEN': 'Scharniere defekt, ausgerissen',
  'FORM.REPAIR.DOOR.DOOR.HOLE': 'Loch',
  'FORM.REPAIR.DOOR.DOOR.LIGHT': 'Lichteinfall',
  'FORM.REPAIR.DOOR.DOOR.SPLIT': 'Gespalten',
  'FORM.REPAIR.DOOR.DOORBELL': 'Türklingel',
  'FORM.REPAIR.DOOR.DOORBELL.CHANGENAME': 'Änderung Namensschild',
  'FORM.REPAIR.DOOR.DOORBELL.DEFECT': 'Klingel defekt',
  'FORM.REPAIR.DOOR.DOORBELL.MISSING': 'Namensschild fehlt',
  'FORM.REPAIR.DOOR.DOORSTEP': 'Türschwelle',
  'FORM.REPAIR.DOOR.DOORSTEP.BARBROKEN': 'Übergangs&shy;profil gebrochen',
  'FORM.REPAIR.DOOR.DOORSTEP.BARLOOSE': 'Übergangs&shy;profil lose',
  'FORM.REPAIR.DOOR.DOORSTEP.BARMISSING': 'Übergangs&shy;profil fehlt',
  'FORM.REPAIR.DOOR.DOORSTEP.SEALINGDEFECT': 'Silikonfuge defekt',
  'FORM.REPAIR.DOOR.FRAME': 'Türrahmen',
  'FORM.REPAIR.DOOR.FRAME.BROKEN': 'Aufgebrochen',
  'FORM.REPAIR.DOOR.FRAME.HINGEBROKEN': 'Scharniere defekt, ausgerissen',
  'FORM.REPAIR.DOOR.FRAME.SEALINGDEFECT': 'Dichtung defekt',
  'FORM.REPAIR.DOOR.FRAME.SEALINGMISSING': 'Dichtung fehlt',
  'FORM.REPAIR.DOOR.FRONTDOOR': 'Haustüre',
  'FORM.REPAIR.DOOR.KNOB': 'Türgriff',
  'FORM.REPAIR.DOOR.KNOB.FALLSAPART': 'Fällt auseinander',
  'FORM.REPAIR.DOOR.KNOB.HANGSDOWN': 'Hängt runter',
  'FORM.REPAIR.DOOR.KNOB.LOOSE': 'Locker, wackelt',
  'FORM.REPAIR.DOOR.KNOB.MISSING': 'Fehlt',
  'FORM.REPAIR.DOOR.KNOB.WORN': 'Stark abgenutzt',
  'FORM.REPAIR.DOOR.LOCK': 'Schloss',
  'FORM.REPAIR.DOOR.LOCK.BOLTBLOCKS': 'Riegel blockiert',
  'FORM.REPAIR.DOOR.LOCK.BROKEN': 'Aufgebrochen',
  'FORM.REPAIR.DOOR.LOCK.BROKENKEY': 'Schlüssel abgebrochen',
  'FORM.REPAIR.DOOR.LOCK.COVERMISSING': 'Schloss&shy;abdeckungen fehlt',
  'FORM.REPAIR.DOOR.LOCK.LATCHBLOCKS': 'Springfalle blockiert',
  'FORM.REPAIR.DOOR.LOCK.MISSINGKEY': 'Schlüssel fehlt',
  'FORM.REPAIR.DOOR.LOCK.NOTWORKING': 'Auf-, abschliessen defekt',
  'FORM.REPAIR.DOOR.LOCK.PLATELOOSE': 'Schliessblech lose',
  'FORM.REPAIR.DOOR.RAMPDEFECT': 'Rampe defekt',
  'FORM.REPAIR.DOOR.TRAPPINGDEFECT': 'Schmutz&shy;schleuse defekt',
  'FORM.REPAIR.ELECTRICITY': 'Strom, Licht',
  'FORM.REPAIR.ELECTRICITY.ENTRANCELIGHT': 'Eingangs&shy;beleuchtung',
  'FORM.REPAIR.ELECTRICITY.EXTERIORLIGHT': 'Aussen&shy;beleuchtung',
  'FORM.REPAIR.ELECTRICITY.EXTERIORLIGHT.DAMAGED': 'Beschädigt',
  'FORM.REPAIR.ELECTRICITY.EXTERIORLIGHT.LIGHTSDURINGDAY': 'Leuchtet tagsüber',
  'FORM.REPAIR.ELECTRICITY.EXTERIORLIGHT.LOOSE': 'Locker, wackelt',
  'FORM.REPAIR.ELECTRICITY.EXTERIORLIGHT.SCREENDEFECT': 'Lichtblenden defekt',
  'FORM.REPAIR.ELECTRICITY.FUSEOUT': 'Sicherung springt raus',
  'FORM.REPAIR.ELECTRICITY.GARAGELIGHT': 'Garagen&shy;beleuchtung',
  'FORM.REPAIR.ELECTRICITY.GARAGELIGHT.AUTOSWITCHONDEFECT': 'Einschalt&shy;automatik defekt',
  'FORM.REPAIR.ELECTRICITY.GARAGELIGHT.AUTOSWITCHOFFDEFECT': 'Ausschalt&shy;automatik defekt',
  'FORM.REPAIR.ELECTRICITY.INTERIORLIGHT': 'Innen&shy;beleuchtung',
  'FORM.REPAIR.ELECTRICITY.INTERIORLIGHT.LOOSE': 'Locker, wackelt',
  'FORM.REPAIR.ELECTRICITY.INTERIORLIGHT.SCREENDEFECT': 'Lichtblenden defekt',
  'FORM.REPAIR.ELECTRICITY.LIGHT.BURNMARK': 'Brandfleck',
  'FORM.REPAIR.ELECTRICITY.LIGHT.DEFECT': 'Leuchtmittel defekt',
  'FORM.REPAIR.ELECTRICITY.LIGHT.FLICKERING': 'Flackert',
  'FORM.REPAIR.ELECTRICITY.LIGHT.SMELLS': 'Stinkt verschmort',
  'FORM.REPAIR.ELECTRICITY.NOPOWER': 'Kein Strom',
  'FORM.REPAIR.ELECTRICITY.NOPOWER.APARTMENT': 'Ganze Wohnung / Gerwerbe&shy;fläche',
  'FORM.REPAIR.ELECTRICITY.NOPOWER.BUILDING': 'Ganzes Gebäude',
  'FORM.REPAIR.ELECTRICITY.NOPOWER.ONLYROOM': 'Nur ein Raum oder eine Strom&shy;gruppe',
  'FORM.REPAIR.ELECTRICITY.POWER': 'Strom',
  'FORM.REPAIR.ELECTRICITY.SOCKET': 'Steckdose',
  'FORM.REPAIR.ELECTRICITY.SOCKET.BROKEN': 'Rahmen gebrochen',
  'FORM.REPAIR.ELECTRICITY.SOCKET.DIRTY': 'Dreckig',
  'FORM.REPAIR.ELECTRICITY.SOCKET.LOOSE': 'Locker, wackelt',
  'FORM.REPAIR.ELECTRICITY.SOCKET.SMELLS': 'Stinkt verschmort',
  'FORM.REPAIR.ELECTRICITY.STAIRWELLLIGHT': 'Treppen&shy;haus&shy;beleuchtung',
  'FORM.REPAIR.ELECTRICITY.SWITCH': 'Lichtschalter, Dimmer',
  'FORM.REPAIR.ELECTRICITY.SWITCH.BROKEN': 'Rahmen gebrochen',
  'FORM.REPAIR.ELECTRICITY.SWITCH.MALFUNCTION': 'Defekt',
  'FORM.REPAIR.ELECTRICITY.SWITCH.SMELLS': 'Stinkt verschmort',
  'FORM.REPAIR.ELECTRICITY.WIRE': 'Kabel',
  'FORM.REPAIR.ELECTRICITY.WIRE.HANGSDOWN': 'Hängt runter',
  'FORM.REPAIR.ELECTRICITY.WIRE.SMELLS': 'Stinkt verschmort',
  'FORM.REPAIR.ERROR': 'Fehlermeldung',
  'FORM.REPAIR.EXTERIORSHAFT': 'Schachtdeckel (aussen)',
  'FORM.REPAIR.FACADE': 'Fassade, Dach',
  'FORM.REPAIR.FACADE.BRICK': 'Ziegel',
  'FORM.REPAIR.FACADE.BRICK.BROKEN': 'Gebrochen',
  'FORM.REPAIR.FACADE.BRICK.MISSING': 'Fehlt',
  'FORM.REPAIR.FACADE.BRICK.POSTPONED': 'Verschoben',
  'FORM.REPAIR.FACADE.FACADEWALL': 'Fassade',
  'FORM.REPAIR.FACADE.FACADEWALL.MOLD': 'Schimmel an der Fassade',
  'FORM.REPAIR.FACADE.FACADEWALL.PEELING': 'Fassade blättert ab',
  'FORM.REPAIR.FACADE.RAINGUTTER': 'Regenrinne',
  'FORM.REPAIR.FACADE.RAINGUTTER.CLOGGED': 'Verstopft',
  'FORM.REPAIR.FACADE.RAINGUTTER.DEFECT': 'Defekt',
  'FORM.REPAIR.FACADE.RAINGUTTER.DOWNPIPECLOGGED': 'Fallrohr verstopft',
  'FORM.REPAIR.FACADE.RAINGUTTER.DOWNPIPEDEFECT': 'Fallrohr defekt',
  'FORM.REPAIR.FACADE.RAINGUTTER.LICKS': 'Leckt',
  'FORM.REPAIR.FILLINGDRILLHOLE': 'Bohrloch schliessen',
  'FORM.REPAIR.FIREPROTECTION': 'Brandschutz',
  'FORM.REPAIR.FIREPROTECTION.SMOKESENSOR': 'Rauchmelder',
  'FORM.REPAIR.FLOORDOOR': 'Etagentüre',
  'FORM.REPAIR.FLOOR': 'Boden',
  'FORM.REPAIR.FLOOR.SURFACE': 'Bodenbelag, Oberfläche',
  'FORM.REPAIR.FLOOR.SURFACE.BULGES': 'Wölbt sich hoch',
  'FORM.REPAIR.FLOOR.SURFACE.BURNMARK': 'Brandfleck',
  'FORM.REPAIR.FLOOR.SURFACE.CHAFEMARK': 'Scheuerstelle',
  'FORM.REPAIR.FLOOR.SURFACE.DISSOLVING': 'Ablösende Beschichtung',
  'FORM.REPAIR.FLOOR.SURFACE.LIGHTSHADE': 'Lichtschatten: hell, dunkel',
  'FORM.REPAIR.FLOOR.SURFACE.LOOSE': 'Lose Klötzli',
  'FORM.REPAIR.FLOOR.SURFACE.QUIRK': 'Macke',
  'FORM.REPAIR.FLOOR.SURFACE.SCRATCH': 'Verkratzt',
  'FORM.REPAIR.FLOOR.SURFACE.STAINS': 'Flecken, Verfärbung',
  'FORM.REPAIR.FLOOR.SURFACE.WATERDAMAGE': 'Wasserschaden',
  'FORM.REPAIR.FLOOR.SURFACE.WORNOUT': 'Zerschlissen',
  'FORM.REPAIR.FLOOR.TILES': 'Fliesen',
  'FORM.REPAIR.FLOOR.TILES.BROKEN': 'Gebrochen',
  'FORM.REPAIR.FLOOR.TILES.JOINTSDEFECT': 'Fuge defekt',
  'FORM.REPAIR.FLOOR.TILES.LOOSE': 'Locker, wackelt',
  'FORM.REPAIR.FLOOR.TILES.WORNOUT': 'Dreckig, abgenutzt',
  'FORM.REPAIR.FLOOR.SKIRTINGBOARDS': 'Sockelleiste',
  'FORM.REPAIR.FLOOR.SKIRTINGBOARDS.GAP': 'Spalt zum Boden',
  'FORM.REPAIR.FLOOR.SKIRTINGBOARDS.LOOSE': 'Locker, wackelt',
  'FORM.REPAIR.FLOOR.SKIRTINGBOARDS.MISSING': 'Fehlt',
  'FORM.REPAIR.FLOOR.SKIRTINGBOARDS.WORNOUT': 'Zerschlissen',
  'FORM.REPAIR.FLOOR.JOINTS': 'Bodenfugen',
  'FORM.REPAIR.FLOOR.JOINTS.DISSOLVING': 'Löst sich',
  'FORM.REPAIR.FLOOR.JOINTS.MISSING': 'Fehlt',
  'FORM.REPAIR.FLOOR.JOINTS.MOLD': 'Schimmlig',
  'FORM.REPAIR.FLOOR.DOORSTEP': 'Türschwelle, Absatz',
  'FORM.REPAIR.FLOOR.DOORSTEP.BARBROKEN': 'Übergangsprofil gebrochen',
  'FORM.REPAIR.FLOOR.DOORSTEP.BARLOOSE': 'Übergangsprofil lose',
  'FORM.REPAIR.FLOOR.DOORSTEP.BARMISSING': 'Übergangsprofil fehlt',
  'FORM.REPAIR.FLOOR.DOORSTEP.WORNOUT': 'Zerschlissen',
  'FORM.REPAIR.FLOOR.DRAIN': 'Bodenabfluss (innen)',
  'FORM.REPAIR.GARAGE': 'Garage',
  'FORM.REPAIR.GARAGEDOOR': 'Garagentüre',
  'FORM.REPAIR.GARAGEGATE': 'Garagentor',
  'FORM.REPAIR.GARAGEGATE.DRAINDEFECT': 'Regenablauf defekt',
  'FORM.REPAIR.GARAGEGATE.ENGINE': 'Motor',
  'FORM.REPAIR.GARAGEGATE.ENGINE.DEFECT': 'Defekt',
  'FORM.REPAIR.GARAGEGATE.ENGINE.NOISE': 'Ungewöhnliche Geräusche',
  'FORM.REPAIR.GARAGEGATE.GATE': 'Garagentor, Aufhängung',
  'FORM.REPAIR.GARAGEGATE.GATE.BENT': 'Verbogen',
  'FORM.REPAIR.GARAGEGATE.GATE.BROKEN': 'Aufgebrochen',
  'FORM.REPAIR.GARAGEGATE.GATE.DISENGAGED': 'Ausgehängt',
  'FORM.REPAIR.GARAGEGATE.GATE.LOOSE': 'Locker, wackelt',
  'FORM.REPAIR.GARAGEGATE.GATE.NOISE': 'Macht ungewöhnliche Geräusche',
  'FORM.REPAIR.GARAGEGATE.OPENINGCLOSING.KEYDEFECT': 'Schlüssel&shy;öffnung defekt',
  'FORM.REPAIR.GARAGEGATE.OPENINGCLOSING.KEYPOSTDEFECT': 'Schlüssel&shy;öffnung Pfosten defekt',
  'FORM.REPAIR.GARAGEGATE.OPENINGCLOSING.NOOPENS': 'Öffnet nicht mehr',
  'FORM.REPAIR.GARAGEGATE.OPENINGCLOSING.NOTCLOSES': 'Schliesst nicht',
  'FORM.REPAIR.GARAGEGATE.OPENINGCLOSING.REMOTEDEFECT': 'Fernöffnung funktioniert nicht',
  'FORM.REPAIR.GARAGEGATE.OPENINGCLOSING.STUCK': 'Steckt fest',
  'FORM.REPAIR.GARAGEGATE.RUSTEDDOORSTEP': 'Schwelle verrostet',
  'FORM.REPAIR.GARBAGE': 'Müllstand',
  'FORM.REPAIR.GARBAGE.BROKEN': 'Mülltonne, Container defekt',
  'FORM.REPAIR.GARDEN': 'Grünanlagen, Mieter&shy;garten',
  'FORM.REPAIR.GREENAREA': 'Begrünung',
  'FORM.REPAIR.GREENAREA.BRANCHESFELL': 'Äste runtergefallen',
  'FORM.REPAIR.GREENAREA.BRANCHESHANG': 'Äste hängen runter',
  'FORM.REPAIR.GREENAREA.HEDGESUNCUT': 'Hecken ungeschnitten',
  'FORM.REPAIR.GREENAREA.UNMOWED': 'Rasen ungemäht',
  'FORM.REPAIR.GUESTAREA': 'Gastraum',
  'FORM.REPAIR.HEATING': 'Gebäude&shy;heizung',
  'FORM.REPAIR.HEATING.DEFECT': 'Ausgefallen',
  'FORM.REPAIR.HEATING.INSUFFICIENT': 'Heizt unzureichend',
  'FORM.REPAIR.HEATING.NOISE': 'Geräusche (z.B. klopfen)',
  'FORM.REPAIR.HEATING.OILEMPTY': 'Öltank: fast leer oder leer',
  'FORM.REPAIR.HEATING.PELLETEMPTY': 'Pelletslager: fast leer oder leer',
  'FORM.REPAIR.HEATING.WOODCHIPEMPTY': 'Holzschnitzel&shy;lager: fast leer oder leer',
  'FORM.REPAIR.HEATINGVENTILATION': 'Heizung, Belüftung',
  'FORM.REPAIR.HEATINGVENTILATION.FLOORHEATING': 'Boden&shy;heizung',
  'FORM.REPAIR.HEATINGVENTILATION.FLOORHEATING.COLD': 'Bleibt kalt',
  'FORM.REPAIR.HEATINGVENTILATION.FLOORHEATING.CONTROLLERDEFECT': 'Regler defekt',
  'FORM.REPAIR.HEATINGVENTILATION.FLOORHEATING.NOTWARMINGUP': 'Wärmt nicht den ganzen Boden',
  'FORM.REPAIR.HEATINGVENTILATION.FLOORHEATING.WATERDAMAGE': 'Wasserschaden',
  'FORM.REPAIR.HEATINGVENTILATION.RADIATOR': 'Heizkörper',
  'FORM.REPAIR.HEATINGVENTILATION.RADIATOR.COLD': 'Bleibt kalt',
  'FORM.REPAIR.HEATINGVENTILATION.RADIATOR.COLORDISSOLVING': 'Farbe blättert ab',
  'FORM.REPAIR.HEATINGVENTILATION.RADIATOR.CONTROLLERDEFECT': 'Regler defekt',
  'FORM.REPAIR.HEATINGVENTILATION.RADIATOR.DRIPPING': 'Tropft',
  'FORM.REPAIR.HEATINGVENTILATION.RADIATOR.GROUNDWATERDAMAGE': 'Wasserschaden am Boden',
  'FORM.REPAIR.HEATINGVENTILATION.RADIATOR.NOTWARMINGUP': 'Wärmt nicht mehr richtig',
  'FORM.REPAIR.HEATINGVENTILATION.RADIATOR.NOISE': 'Macht Geräusche',
  'FORM.REPAIR.HEATINGVENTILATION.THERMOSTAT': 'Thermostat',
  'FORM.REPAIR.HEATINGVENTILATION.THERMOSTAT.DEFECT': 'Funktioniert nicht mehr',
  'FORM.REPAIR.HEATINGVENTILATION.THERMOSTAT.HANGSDOWN': 'Hängt runter',
  'FORM.REPAIR.HEATINGVENTILATION.VENTILATION': 'Belüftung',
  'FORM.REPAIR.HEATINGVENTILATION.VENTILATION.DEFECT': 'Funktioniert nicht mehr',
  'FORM.REPAIR.HEATINGVENTILATION.VENTILATION.LOWPERFORMANCE': 'Leistung zu gering',
  'FORM.REPAIR.HEATINGVENTILATION.VENTILATION.NOISE': 'Macht Geräusche',
  'FORM.REPAIR.HOLE': 'Loch',
  'FORM.REPAIR.HOMESTAIRS': 'Treppe (in der Wohnung)',
  'FORM.REPAIR.HOTWATER': 'Warmwasser',
  'FORM.REPAIR.HOTWATER.NOHOTWATER': 'Kein Warmwasser',
  'FORM.REPAIR.HOTWATER.NOHOTWATER.ONECONNECTION': 'Nur ein Anschluss',
  'FORM.REPAIR.HOTWATER.NOHOTWATER.TITLE': 'An wie vielen Anschlüssen kommt kein heisses Wasser?',
  'FORM.REPAIR.HOTWATER.NOWARMWATER': 'Wasser nicht richtig warm',
  'FORM.REPAIR.HOTWATER.NOWARMWATER.TITLE': 'An wie vielen Anschlüssen kommt nur warmes Wasser?',
  'FORM.REPAIR.HOUSEDOOR': 'Hauseingangs&shy;türe',
  'FORM.REPAIR.INNERSHAFT': 'Schachtdeckel (innen)',
  'FORM.REPAIR.KITCHEN': 'Küche',
  'FORM.REPAIR.KITCHEN.APPLIANCES': 'Küchen&shy;geräte',
  'FORM.REPAIR.KITCHEN.APPLIANCES.BROKENLIGHT': 'Licht kaputt',
  'FORM.REPAIR.KITCHEN.APPLIANCES.COOKER': 'Kochherd',
  'FORM.REPAIR.KITCHEN.APPLIANCES.COOKER.HOB': 'Nur Kochfeld',
  'FORM.REPAIR.KITCHEN.APPLIANCES.COOKER.HOBOVEN': 'Kochfeld & Backofen',
  'FORM.REPAIR.KITCHEN.APPLIANCES.COOKER.HOBOVEN.LOOSE': 'Locker, wackelt',
  'FORM.REPAIR.KITCHEN.APPLIANCES.COOKER.HOBOVEN.SWITCHONDEFECT': 'Einschalten geht nicht',
  'FORM.REPAIR.KITCHEN.APPLIANCES.COOKER.OVEN': 'Nur Backofen',
  'FORM.REPAIR.KITCHEN.APPLIANCES.DISHWASHER': 'Geschirr&shy;spüler',
  'FORM.REPAIR.KITCHEN.APPLIANCES.DISHWASHER.BACKWATER': 'Abwasser drückt aus Abfluss',
  'FORM.REPAIR.KITCHEN.APPLIANCES.DISHWASHER.BASKETDEFECT': 'Besteck&shy;korb defekt',
  'FORM.REPAIR.KITCHEN.APPLIANCES.DISHWASHER.FALLSOFF': 'Front fällt ab',
  'FORM.REPAIR.KITCHEN.APPLIANCES.DISHWASHER.FOAMS': 'Schäumt',
  'FORM.REPAIR.KITCHEN.APPLIANCES.DISHWASHER.GRINDS': 'Front streift, steht an',
  'FORM.REPAIR.KITCHEN.APPLIANCES.DISHWASHER.LEAKING': 'Undicht: Wasser',
  'FORM.REPAIR.KITCHEN.APPLIANCES.DISHWASHER.LOUD': 'Brummt laut',
  'FORM.REPAIR.KITCHEN.APPLIANCES.DISHWASHER.NOTCLEAN': 'Spült nicht sauber',
  'FORM.REPAIR.KITCHEN.APPLIANCES.DISHWASHER.NOTDRIES': 'Trocknet nicht mehr',
  'FORM.REPAIR.KITCHEN.APPLIANCES.DISHWASHER.WATERINMACHINE': 'Wasser steht in Maschine',
  'FORM.REPAIR.KITCHEN.APPLIANCES.EXTRACTOR': 'Dunstabzug',
  'FORM.REPAIR.KITCHEN.APPLIANCES.EXTRACTOR.CLOGGED': 'Filter verstopft',
  'FORM.REPAIR.KITCHEN.APPLIANCES.EXTRACTOR.DRIPS': 'Tropft',
  'FORM.REPAIR.KITCHEN.APPLIANCES.EXTRACTOR.LOUD': 'Piepst / zu laut / macht Geräusche',
  'FORM.REPAIR.KITCHEN.APPLIANCES.EXTRACTOR.POWERDEFECT': 'Zieht nicht ab / zu schwach',
  'FORM.REPAIR.KITCHEN.APPLIANCES.EXTRACTOR.REGULATIONDEFECT': 'Lässt sich nicht regeln',
  'FORM.REPAIR.KITCHEN.APPLIANCES.EXTRACTOR.SMELLS': 'Stinkt',
  'FORM.REPAIR.KITCHEN.APPLIANCES.FREEZER': 'Tiefkühl&shy;schrank',
  'FORM.REPAIR.KITCHEN.APPLIANCES.HOB.BROKEN': 'Gebrochen, gesprungen',
  'FORM.REPAIR.KITCHEN.APPLIANCES.HOB.BROKENFRAME': 'Rahmen gebrochen',
  'FORM.REPAIR.KITCHEN.APPLIANCES.HOB.BURNMARK': 'Brandfleck',
  'FORM.REPAIR.KITCHEN.APPLIANCES.HOB.JOINTLEAKS': 'Fuge undicht, löst sich',
  'FORM.REPAIR.KITCHEN.APPLIANCES.HOB.LOOSE': 'Locker, wackelt',
  'FORM.REPAIR.KITCHEN.APPLIANCES.HOB.SWITCHDEFECT': 'Einschalten geht nicht',
  'FORM.REPAIR.KITCHEN.APPLIANCES.HOB.WORN': 'Abgenutzt',
  'FORM.REPAIR.KITCHEN.APPLIANCES.MICROWAVE': 'Mikrowelle',
  'FORM.REPAIR.KITCHEN.APPLIANCES.MICROWAVE.HANDLEDEFECT': 'Griff defekt',
  'FORM.REPAIR.KITCHEN.APPLIANCES.MICROWAVE.LOCKDEFECT': 'Türsicherung beschädigt',
  'FORM.REPAIR.KITCHEN.APPLIANCES.MICROWAVE.TURNTABLEDEFECT': 'Drehteller defekt',
  'FORM.REPAIR.KITCHEN.APPLIANCES.NOELECTRICITY': 'Kein Strom',
  'FORM.REPAIR.KITCHEN.APPLIANCES.NOTHOT': 'Heizt nicht auf',
  'FORM.REPAIR.KITCHEN.APPLIANCES.OVEN': 'Backofen',
  'FORM.REPAIR.KITCHEN.APPLIANCES.OVEN.GLASSDEFECT': 'Glasscheibe defekt',
  'FORM.REPAIR.KITCHEN.APPLIANCES.OVEN.REPLACEACCESSORIES': 'Zubehör ersetzen / ergänzen',
  'FORM.REPAIR.KITCHEN.APPLIANCES.OVEN.SEALDEFECT': 'Dichtung porös, defekt',
  'FORM.REPAIR.KITCHEN.APPLIANCES.OVEN.SMELL': 'Starker Geruch',
  'FORM.REPAIR.KITCHEN.APPLIANCES.REFRIGERATOR': 'Kühlschrank',
  'FORM.REPAIR.KITCHEN.APPLIANCES.REFRIGERATOR.CONDENSATION': 'Kondenswasser',
  'FORM.REPAIR.KITCHEN.APPLIANCES.REFRIGERATOR.GLASSDEFECT': 'Glasablage, Tablar defekt',
  'FORM.REPAIR.KITCHEN.APPLIANCES.REFRIGERATOR.DOOR': 'Türe',
  'FORM.REPAIR.KITCHEN.APPLIANCES.REFRIGERATOR.DOOR.BOTTLEHOLDERDEFECT': 'Defekte Flaschen&shy;halter',
  'FORM.REPAIR.KITCHEN.APPLIANCES.REFRIGERATOR.DOOR.CRACKS': 'Knackt',
  'FORM.REPAIR.KITCHEN.APPLIANCES.REFRIGERATOR.DOOR.GRINDS': 'Streift, steht an',
  'FORM.REPAIR.KITCHEN.APPLIANCES.REFRIGERATOR.DOOR.HANGSDOWN': 'Front hängt runter',
  'FORM.REPAIR.KITCHEN.APPLIANCES.REFRIGERATOR.DOOR.HANDLEDEFECT': 'Griff defekt',
  'FORM.REPAIR.KITCHEN.APPLIANCES.REFRIGERATOR.DOOR.NOTCLOSE': 'Bleibt nicht zu',
  'FORM.REPAIR.KITCHEN.APPLIANCES.REFRIGERATOR.DOORCOMPARTMENTSDEFECT': 'Defekte Türfächer',
  'FORM.REPAIR.KITCHEN.APPLIANCES.REFRIGERATOR.DRAWERDEFECT': 'Gemüseschublade, -fach defekt',
  'FORM.REPAIR.KITCHEN.APPLIANCES.REFRIGERATOR.FREEZERDOORDEFECT': 'Gefrierfach&shy;türe defekt',
  'FORM.REPAIR.KITCHEN.APPLIANCES.REFRIGERATOR.FREEZES': 'Vereist häufig',
  'FORM.REPAIR.KITCHEN.APPLIANCES.REFRIGERATOR.LOUD': 'Brummt laut',
  'FORM.REPAIR.KITCHEN.APPLIANCES.REFRIGERATOR.NOCOOLS': 'Kühlt nicht mehr',
  'FORM.REPAIR.KITCHEN.APPLIANCES.REFRIGERATOR.SEALDEFECT': 'Dichtung hält nicht',
  'FORM.REPAIR.KITCHEN.APPLIANCES.STEAMER': 'Steamer',
  'FORM.REPAIR.KITCHEN.APPLIANCES.STEAMER.LEAKING': 'Undicht: Wasser',
  'FORM.REPAIR.KITCHEN.APPLIANCES.STEAMER.TANKDEFECT': 'Wasserbehälter defekt',
  'FORM.REPAIR.KITCHEN.APPLIANCES.SWITCHDEFECT': 'Schalter defekt',
  'FORM.REPAIR.KITCHEN.HOB': 'Kochfeld',
  'FORM.REPAIR.KITCHEN.WORKTOP': 'Arbeits&shy;platte',
  'FORM.REPAIR.KITCHEN.WORKTOP.EDGE': 'Kante',
  'FORM.REPAIR.KITCHEN.WORKTOP.EDGE.CRACK': 'Riss',
  'FORM.REPAIR.KITCHEN.WORKTOP.EDGE.FRAYS': 'Franst aus',
  'FORM.REPAIR.KITCHEN.WORKTOP.JOINT': 'Fuge',
  'FORM.REPAIR.KITCHEN.WORKTOP.JOINT.DISSOLVES': 'Löst sich',
  'FORM.REPAIR.KITCHEN.WORKTOP.JOINT.MOLDY': 'Schimmlig',
  'FORM.REPAIR.KITCHEN.WORKTOP.SURFACE': 'Oberfläche',
  'FORM.REPAIR.KITCHEN.WORKTOP.SURFACE.BROKEN': 'Gebrochen',
  'FORM.REPAIR.KITCHEN.WORKTOP.SURFACE.BURNMARK': 'Brandfleck',
  'FORM.REPAIR.KITCHEN.WORKTOP.SURFACE.CHAFING': 'Scheuerstelle',
  'FORM.REPAIR.KITCHEN.WORKTOP.SURFACE.HOLE': 'Loch',
  'FORM.REPAIR.KITCHEN.WORKTOP.SURFACE.WATERSTAINS': 'Wasserflecken',
  'FORM.REPAIR.KITCHEN.WORKTOP.SWOLLEN': 'Aufgequollen',
  'FORM.REPAIR.KITCHEN.FURNITURE': 'Küchenmöbel',
  'FORM.REPAIR.KITCHEN.FURNITURE.APOTHECARYCABINET': 'Apotheker&shy;schrank',
  'FORM.REPAIR.KITCHEN.FURNITURE.AUTOFEEDDEFECT': 'Automatischer Einzug defekt',
  'FORM.REPAIR.KITCHEN.FURNITURE.BASE': 'Sockel',
  'FORM.REPAIR.KITCHEN.FURNITURE.BASE.BROKEN': 'Gebrochen',
  'FORM.REPAIR.KITCHEN.FURNITURE.BASE.DEPRESSED': 'Eingedrückt',
  'FORM.REPAIR.KITCHEN.FURNITURE.BASE.JOINTDEFECT': 'Fuge defekt',
  'FORM.REPAIR.KITCHEN.FURNITURE.DRAWER': 'Schublade',
  'FORM.REPAIR.KITCHEN.FURNITURE.DRAWER.INSERTMISSING': 'Besteckeinsatz fehlt',
  'FORM.REPAIR.KITCHEN.FURNITURE.DOORS': 'Türen, Fronten',
  'FORM.REPAIR.KITCHEN.FURNITURE.DOORS.CUSHIONINGDEFECT': 'Dämpfung defekt',
  'FORM.REPAIR.KITCHEN.FURNITURE.DOORS.EDGEDEFECT': 'Kanten defekt',
  'FORM.REPAIR.KITCHEN.FURNITURE.DOORS.EDGEMISSING': 'Kante fehlt',
  'FORM.REPAIR.KITCHEN.FURNITURE.DOORS.GRINDS': 'Streift, steht an',
  'FORM.REPAIR.KITCHEN.FURNITURE.DOORS.HINGEDEFECT': 'Scharnier defekt',
  'FORM.REPAIR.KITCHEN.FURNITURE.DOORS.SNAPPERDEFECT': 'Schnapper defekt',
  'FORM.REPAIR.KITCHEN.FURNITURE.HANDLEDEFECT': 'Griff defekt',
  'FORM.REPAIR.KITCHEN.FURNITURE.HANGSDOWN': 'Hängt runter',
  'FORM.REPAIR.KITCHEN.FURNITURE.LOOSE': 'Locker, wackelt',
  'FORM.REPAIR.KITCHEN.FURNITURE.MOLDY': 'Schimmlig',
  'FORM.REPAIR.KITCHEN.FURNITURE.NOTOPENS': 'Öffnet nicht richtig',
  'FORM.REPAIR.KITCHEN.FURNITURE.NOTCLOSES': 'Schliesst nicht richtig',
  'FORM.REPAIR.KITCHEN.FURNITURE.PANSPULLOUT': 'Pfannen-, Flaschen&shy;auszug',
  'FORM.REPAIR.KITCHEN.FURNITURE.SHELF': 'Tablar',
  'FORM.REPAIR.KITCHEN.FURNITURE.SHELF.BRACKETDEFECT': 'Halterung defekt',
  'FORM.REPAIR.KITCHEN.FURNITURE.SHELF.BRACKETMISSING': 'Halterung fehlt',
  'FORM.REPAIR.KITCHEN.FURNITURE.SHELF.CROOKED': 'Krumm',
  'FORM.REPAIR.KITCHEN.FURNITURE.SHELF.MISSING': 'Fehlt',
  'FORM.REPAIR.KITCHEN.FURNITURE.SHELF.SURFACEDEFECT': 'Oberfläche defekt',
  'FORM.REPAIR.KITCHEN.FURNITURE.SWOLLEN': 'Aufgequollen',
  'FORM.REPAIR.KITCHEN.FURNITURE.WALLS': 'Wände, Boden, ...',
  'FORM.REPAIR.KITCHEN.FURNITURE.WALLS.BROKEN': 'Rückwand gebrochen',
  'FORM.REPAIR.KITCHEN.FURNITURE.WALLS.WALLPRESSED': 'Rückwand eingedrückt',
  'FORM.REPAIR.KITCHEN.FURNITURE.WORN': 'Abgenutzt',
  'FORM.REPAIR.KITCHEN.WASTE': 'Abfallsystem',
  'FORM.REPAIR.KITCHEN.WASTE.TRASHBIN': 'Abfalleimer',
  'FORM.REPAIR.KITCHEN.WASTE.TRASHBIN.BRACKETTORN': 'Boden&shy;halterung ausgerissen',
  'FORM.REPAIR.KITCHEN.WASTE.TRASHBIN.CONNECTIONDEFECT': 'Türverbindung defekt',
  'FORM.REPAIR.KITCHEN.WASTE.TRASHBIN.LIDHOLDERDEFECT': 'Deckelhalter defekt',
  'FORM.REPAIR.KITCHEN.WASTE.TRASHBIN.LIDTORN': 'Deckel, Schrägablage gerissen',
  'FORM.REPAIR.KITCHEN.WASTE.TRASHBIN.MISSING': 'Fehlt',
  'FORM.REPAIR.KITCHEN.WASTE.TRASHBIN.WORN': 'Abgenutzt',
  'FORM.REPAIR.KITCHEN.WASTE.FLOOR': 'Möbelboden Abfall&shy;eimer',
  'FORM.REPAIR.KITCHEN.WASTE.FLOOR.EDGEDEFECT': 'Kanten defekt',
  'FORM.REPAIR.KITCHEN.WASTE.FLOOR.MOLDY': 'Schimmlig',
  'FORM.REPAIR.KITCHEN.WASTE.FLOOR.SWOLLEN': 'Aufgequollen',
  'FORM.REPAIR.KITCHEN.WASTE.FLOOR.WORN': 'Zerschlissen',
  'FORM.REPAIR.KITCHEN.WASTE.SUSPENSION': 'Aufhängung',
  'FORM.REPAIR.KITCHEN.WASTE.SUSPENSION.BROKEN': 'Gebrochen, gesprungen',
  'FORM.REPAIR.KITCHEN.WASTE.SUSPENSION.LOOSE': 'Locker, wackelt',
  'FORM.REPAIR.KITCHEN.WASTE.DOOR': 'Auszug oder Türe',
  'FORM.REPAIR.KITCHEN.WASTE.DOOR.AUTOFEEDDEFECT': 'Automatischer Einzug defekt',
  'FORM.REPAIR.KITCHEN.WASTE.DOOR.GRINDS': 'Streift, steht an',
  'FORM.REPAIR.KITCHEN.WASTE.DOOR.HANDLEDEFECT': 'Griff defekt',
  'FORM.REPAIR.KITCHEN.WASTE.DOOR.NOTCLOSES': 'Schliesst schlecht',
  'FORM.REPAIR.KITCHEN.WASTE.DOOR.NOTOPENS': 'Öffnet nicht richtig',
  'FORM.REPAIR.KITCHEN.SINK': 'Spülbecken',
  'FORM.REPAIR.KITCHEN.SINK.BACKWATER': 'Abwasser drückt aus Abfluss',
  'FORM.REPAIR.KITCHEN.SINK.CLOGGED': 'Verstopft',
  'FORM.REPAIR.KITCHEN.SINK.DRAIN': 'Abfluss',
  'FORM.REPAIR.KITCHEN.SINK.DRAIN.DRAINSSLOW': 'Läuft langsam ab',
  'FORM.REPAIR.KITCHEN.SINK.DRAIN.NOISE': 'Gluckert / Geräusche',
  'FORM.REPAIR.KITCHEN.SINK.DRIPS': 'Tropft',
  'FORM.REPAIR.KITCHEN.SINK.FITTINGS': 'Armaturen',
  'FORM.REPAIR.KITCHEN.SINK.FITTINGS.CHALKY': 'Kalkig',
  'FORM.REPAIR.KITCHEN.SINK.FITTINGS.LITTLEPRESSURE': 'Wenig Wasser&shy;druck',
  'FORM.REPAIR.KITCHEN.SINK.FITTINGS.SEALDEFECT': 'Dichtung porös, defekt',
  'FORM.REPAIR.KITCHEN.SINK.FITTINGS.SPLASHES': 'Spritzt',
  'FORM.REPAIR.KITCHEN.SINK.JOINT': 'Fuge',
  'FORM.REPAIR.KITCHEN.SINK.JOINT.DIRTY': 'Dreckig',
  'FORM.REPAIR.KITCHEN.SINK.JOINT.DISSOLVES': 'Löst sich',
  'FORM.REPAIR.KITCHEN.SINK.JOINT.MOLDY': 'Schimmlig',
  'FORM.REPAIR.KITCHEN.SINK.LEAKY': 'Undicht',
  'FORM.REPAIR.KITCHEN.SINK.LOOSE': 'Locker, wackelt',
  'FORM.REPAIR.KITCHEN.SINK.NOPLUG': 'Stöpsel fehlt',
  'FORM.REPAIR.KITCHEN.SINK.PIPES': 'Siphon, Abflussrohre',
  'FORM.REPAIR.KITCHEN.SINK.PIPES.LOOSE': 'Lose, nicht verbunden',
  'FORM.REPAIR.KITCHEN.SINK.SUPPLYLINES': 'Zuleitungen',
  'FORM.REPAIR.KITCHEN.SINK.SINKBASIN': 'Spülbecken',
  'FORM.REPAIR.KITCHEN.SINK.SINKBASIN.GRAZES': 'Abtropfsieb fehlt / defekt',
  'FORM.REPAIR.KITCHEN.SINK.SINKBASIN.WORN': 'Abgenutzt',
  'FORM.REPAIR.KITCHEN.SINK.SMELLS': 'Stinkt',
  'FORM.REPAIR.LAUNDRYROOM': 'Wasch- und Trocken&shy;raum',
  'FORM.REPAIR.LIFT': 'Lift',
  'FORM.REPAIR.LIFT.ALARMBUTTON': 'Notruftaste defekt',
  'FORM.REPAIR.LIFT.BURNINGSMELL': 'Brandgeruch',
  'FORM.REPAIR.LIFT.CALLBUTTON': 'Ruftaste (aussen)',
  'FORM.REPAIR.LIFT.CONTROLPANEL': 'Kabinen&shy;tableau',
  'FORM.REPAIR.LIFT.DOOR': 'Türe',
  'FORM.REPAIR.LIFT.DOOR.CLOSESSLOWLY': 'Schliesst zu langsam',
  'FORM.REPAIR.LIFT.DOOR.GRINDS': 'Schleift',
  'FORM.REPAIR.LIFT.DOOR.NOTCLOSING': 'Schliesst nicht',
  'FORM.REPAIR.LIFT.DOOR.SLAMS': 'Schlägt zu (knallt zu)',
  'FORM.REPAIR.LIFT.DOOR.SQUEAKS': 'Quietscht',
  'FORM.REPAIR.LIFT.DOOR.STUCK': 'Klemmt',
  'FORM.REPAIR.LIFT.FAILURE': 'Störung Fahrbetrieb',
  'FORM.REPAIR.LIFT.FAILURE.COMPLETE': 'Kompletter Ausfall',
  'FORM.REPAIR.LIFT.FAILURE.JERKING': 'Ruckelt',
  'FORM.REPAIR.LIFT.FAILURE.NOISE': 'Macht Geräusche',
  'FORM.REPAIR.LIFT.FAILURE.SPORADICALLY': 'Fällt sporadisch aus',
  'FORM.REPAIR.LIFT.FAILURE.STOPS': 'Hält an falscher Etage',
  'FORM.REPAIR.LIFT.INDICATOR': 'Etagenanzeige Vorraum',
  'FORM.REPAIR.LIFT.LIGHT': 'Licht defekt',
  'FORM.REPAIR.LIFT.OBJECTINSHAFT': 'Gegenstand im Schacht',
  'FORM.REPAIR.LIFT.OUTSIDEPANEL': 'Aussentableau',
  'FORM.REPAIR.LIFT.POSITION': 'Unbündige Kabinen&shy;position',
  'FORM.REPAIR.LIFT.WATERDAMAGE': 'Wasser eingedrungen',
  'FORM.REPAIR.LIVINGROOM': 'Wohnzimmer',
  'FORM.REPAIR.MAILBOX': 'Briefkasten',
  'FORM.REPAIR.MAILBOX.DOORDEFECT': 'Paketfachtüre defekt',
  'FORM.REPAIR.MAILBOX.FLAPDEFECT': 'Einwurfklappe defekt',
  'FORM.REPAIR.MAILBOX.LOCKDEFECT': 'Schloss defekt',
  'FORM.REPAIR.MAILBOX.LOOSE': 'Locker, wackelt',
  'FORM.REPAIR.MAILBOX.LOSTKEY': 'Schlüssel verloren',
  'FORM.REPAIR.MAILBOX.TAGCHANGE': 'Änderung Namensschild',
  'FORM.REPAIR.MAILBOX.TAGMISSING': 'Namensschild fehlt',
  'FORM.REPAIR.MOIST': 'Feucht',
  'FORM.REPAIR.MOLD': 'Schimmel, Pilz',
  'FORM.REPAIR.MULTIMEDIA': 'Multimedia&shy;dienst (TV, Internet, Glasfaser, ...)',
  'FORM.REPAIR.MULTIMEDIA.FIBERFAIL': 'Glasfaser&shy;anschluss ausgefallen',
  'FORM.REPAIR.MULTIMEDIA.SEALCONNECTION': 'Plombierung des Kabel&shy;anschlusses',
  'FORM.REPAIR.MULTIMEDIA.UNSEALCONNECTION': 'Plombierung am Kabel&shy;anschluss aufheben',
  'FORM.REPAIR.NURSERY': 'Kinderzimmer',
  'FORM.REPAIR.OFFICE': 'Büro',
  'FORM.REPAIR.OTHER': 'Anderer Schaden',
  'FORM.REPAIR.OUTSIDEFLOOR': 'Außenboden',
  'FORM.REPAIR.OUTSIDEFLOOR.BIGJOINTS': 'Fugen zu gross',
  'FORM.REPAIR.OUTSIDEFLOOR.BROKEN': 'Gebrochen, fehlen',
  'FORM.REPAIR.OUTSIDEFLOOR.DIRTY': 'Verfärbt, Flecken, dreckig',
  'FORM.REPAIR.OUTSIDEFLOOR.LOOSE': 'Lose',
  'FORM.REPAIR.OUTSIDEFLOOR.UNEVEN': 'Fläche uneben',
  'FORM.REPAIR.OUTSIDEFLOOR.WEEDSINJOINTS': 'Unkraut in Fugen',
  'FORM.REPAIR.PAINTING': 'Streichen',
  'FORM.REPAIR.PAINTING.DURATION.TITLE': 'Wann wurde das letzte Mal gestrichen?',
  'FORM.REPAIR.PAINTING.MINUSFIVE': 'Jünger als 5 Jahre',
  'FORM.REPAIR.PAINTING.NONSMOKERS': 'Nichtraucher',
  'FORM.REPAIR.PAINTING.PLUSFIVE': 'Älter als 5 Jahre',
  'FORM.REPAIR.PAINTING.SMOKERS': 'Raucher',
  'FORM.REPAIR.PAINTING.SMOKING.TITLE': 'Wurde geraucht?',
  'FORM.REPAIR.PARKING': 'Parkplatz',
  'FORM.REPAIR.PARKING.TIREBOX': 'Reifenschrank, Pneukasten',
  'FORM.REPAIR.PARKING.TIREBOX.LOOSE': 'Locker, wackelt',
  'FORM.REPAIR.PARKING.TIREBOX.LOCKDEFECT': 'Schloss defekt',
  'FORM.REPAIR.PARKING.TIREBOX.MISSINGKEY': 'Schlüssel fehlt',
  'FORM.REPAIR.PARKING.TIREBOX.BOXDEFECT': 'Kasten defekt',
  'FORM.REPAIR.PLAYGROUND': 'Spielplatz',
  'FORM.REPAIR.PLAYGROUND.EQUIPMENT': 'Spielgeräte',
  'FORM.REPAIR.PLAYGROUND.EQUIPMENT.FRAMEDEFECT': 'Klettergerüst defekt',
  'FORM.REPAIR.PLAYGROUND.EQUIPMENT.OTHERDEFECT': 'Weiteres Spielgerät defekt',
  'FORM.REPAIR.PLAYGROUND.EQUIPMENT.SANDPITDEFECT': 'Sandkasten defekt',
  'FORM.REPAIR.PLAYGROUND.EQUIPMENT.SLIDEDEFECT': 'Rutsche defekt',
  'FORM.REPAIR.PESTS': 'Schädlinge',
  'FORM.REPAIR.PESTS.ANTS': 'Ameisen',
  'FORM.REPAIR.PESTS.BEDBUGS': 'Bettwanzen',
  'FORM.REPAIR.PESTS.BIRDS': 'Tauben / Spatzen / Vögel',
  'FORM.REPAIR.PESTS.COCKROACHES': 'Schaben',
  'FORM.REPAIR.PESTS.MICE': 'Mäuse / Ratten',
  'FORM.REPAIR.PESTS.OTHER': 'Anderer Schädling',
  'FORM.REPAIR.PESTS.SILVERFISH': 'Silberfische',
  'FORM.REPAIR.PESTS.WASPS': 'Wespen',
  'FORM.REPAIR.RAILINGS': 'Geländer',
  'FORM.REPAIR.RAILINGS.BROKEN': 'Gebrochen',
  'FORM.REPAIR.RAILINGS.LOOSE': 'Locker, wackelt',
  'FORM.REPAIR.RAILINGS.RUSTY': 'Rostig',
  'FORM.REPAIR.RAILINGS.STACKSDEFECT': 'Stakete defekt',
  'FORM.REPAIR.SIDEWALK': 'Gehweg',
  'FORM.REPAIR.SINGLEGARAGE': 'Einzel&shy;garage',
  'FORM.REPAIR.SHADES': 'Beschattung und Verdunklung',
  'FORM.REPAIR.SHADES.BLINDS': 'Fensterladen',
  'FORM.REPAIR.SHADES.BLINDS.BRACKETBROKEN': 'Rückhaltung kaputt',
  'FORM.REPAIR.SHADES.BLINDS.GRINDS': 'Streift, steht an',
  'FORM.REPAIR.SHADES.BLINDS.HANGSDOWN': 'Hängt runter',
  'FORM.REPAIR.SHADES.BLINDS.HINGEDEFECT': 'Scharnier defekt',
  'FORM.REPAIR.SHADES.BLINDS.HOLDERDEFECT': 'Halter defekt',
  'FORM.REPAIR.SHADES.BLINDS.JAMS': 'Klemmt',
  'FORM.REPAIR.SHADES.BLINDS.LOOSE': 'Locker, wackelt',
  'FORM.REPAIR.SHADES.BLINDS.REMAINSOPEN': 'Schliesst nicht',
  'FORM.REPAIR.SHADES.BLINDS.SLATSDEFECT': 'Lamellen defekt',
  'FORM.REPAIR.SHADES.BLINDS.WEATHERED': 'Verwittert',
  'FORM.REPAIR.SHADES.CANOPEN': 'Ja, Fenster lassen sich öffnen',
  'FORM.REPAIR.SHADES.CANTOPEN': 'Nein, Fenster lassen sich nicht öffnen',
  'FORM.REPAIR.SHADES.CONTROLLINGQUANTITY.TITLE': 'Wie viele Storen werden mit dem Schalter gleichzeitig gesteuert?',
  'FORM.REPAIR.SHADES.CRANK': 'Kurbel',
  'FORM.REPAIR.SHADES.CRANKJOINT.TITLE': 'Ist das Kurbelgelenk intakt?',
  'FORM.REPAIR.SHADES.CRANKTURN.TITLE': 'Dreht die Kurbel?',
  'FORM.REPAIR.SHADES.CROOKED': 'Hängt schief',
  'FORM.REPAIR.SHADES.CURTAINRAIL': 'Vorhang&shy;schiene',
  'FORM.REPAIR.SHADES.CURTAINRAIL.MISSING': 'Fehlt',
  'FORM.REPAIR.SHADES.CURTAINRAIL.RIPPED': 'Ausgerissen',
  'FORM.REPAIR.SHADES.CURTAINRAIL.STUCK': 'Gleiter, Vorhang läuft nicht',
  'FORM.REPAIR.SHADES.DETACHED': 'Aus der Führungsschiene',
  'FORM.REPAIR.SHADES.ELECTRICALREMOTE': 'Elektrisch (Fernbedienung)',
  'FORM.REPAIR.SHADES.ELECTRICALREMOTE.BATTERIES.TITLE': 'Haben Sie neue Batterien für die Fern&shy;bedienung getestet?',
  'FORM.REPAIR.SHADES.ELECTRICALREMOTE.NEWBATTERIES': 'Neu',
  'FORM.REPAIR.SHADES.ELECTRICALREMOTE.OLDBATTERIES': 'Alt',
  'FORM.REPAIR.SHADES.ELECTRICALWALL': 'Elektrisch (Wandschalter)',
  'FORM.REPAIR.SHADES.ENGINESOUND': 'Ja, ein Motoren&shy;geräusch',
  'FORM.REPAIR.SHADES.FABRICBLINDS': 'Stoffstoren',
  'FORM.REPAIR.SHADES.FABRICBLINDS.DEFECT': 'Stoffstab defekt',
  'FORM.REPAIR.SHADES.FABRICBLINDS.KIND.TITLE': 'Was für ein Stoffstoren ist es?',
  'FORM.REPAIR.SHADES.FABRICBLINDS.RIPPED': 'Stoff gerissen oder lose',
  'FORM.REPAIR.SHADES.FABRICBLINDS.UNROLLS': 'Stoff rollt ab, Storen schliesst nicht',
  'FORM.REPAIR.SHADES.HAILDAMAGE': 'Hagelschaden',
  'FORM.REPAIR.SHADES.HANDCRANK': 'Handkurbel',
  'FORM.REPAIR.SHADES.HANDCRANK.NOTOKAY': 'nicht o.k.',
  'FORM.REPAIR.SHADES.HANDCRANK.NOTTURN': 'Dreht nicht',
  'FORM.REPAIR.SHADES.HANDCRANK.OKAY': 'o.k.',
  'FORM.REPAIR.SHADES.HANDCRANK.TURNSEMPTY': 'Dreht leer',
  'FORM.REPAIR.SHADES.INSTALLATIONAGE.TITLE': 'Wie alt ist die Installation?',
  'FORM.REPAIR.SHADES.KIND.TITLE': 'Was für ein Lamellenstoren ist es?',
  'FORM.REPAIR.SHADES.LOOSE': 'Führungsschiene lose',
  'FORM.REPAIR.SHADES.METALBLINDS': 'Ganz&shy;metall&shy;storen',
  'FORM.REPAIR.SHADES.METALBLINDS.KIND.TITLE': 'Was für ein Lamellenstoren ist es?',
  'FORM.REPAIR.SHADES.MINUS2YEARS': 'Jünger als 2 Jahre',
  'FORM.REPAIR.SHADES.MINUS3M': 'Schmaler als 3m',
  'FORM.REPAIR.SHADES.NOISE': 'Macht Geräusche',
  'FORM.REPAIR.SHADES.NOSOUND': 'Nein, kein Motoren&shy;geräusch',
  'FORM.REPAIR.SHADES.NOTPULLDOWNUP': 'Geht nicht mehr hoch / runter',
  'FORM.REPAIR.SHADES.NOTSHUTOFF': 'Lässt sich nicht mehr ausstellen',
  'FORM.REPAIR.SHADES.NOTTURN': 'Wippt nicht mehr',
  'FORM.REPAIR.SHADES.ONE': '1',
  'FORM.REPAIR.SHADES.PLUS2YEARS': 'Älter als 2 Jahre',
  'FORM.REPAIR.SHADES.PLUS3M': 'Breiter als 3m',
  'FORM.REPAIR.SHADES.QUANTITY.TITLE': 'Wie viele Storen sind betroffen?',
  'FORM.REPAIR.SHADES.SEVERAL': 'Mehrere',
  'FORM.REPAIR.SHADES.SHUTTER': 'Rollladen',
  'FORM.REPAIR.SHADES.SHUTTER.BELT': 'Rollladen&shy;gurt',
  'FORM.REPAIR.SHADES.SHUTTER.COVER': 'Rollladen&shy;deckel',
  'FORM.REPAIR.SHADES.SHUTTER.FALLSDOWN': 'Hält nicht, fällt runter',
  'FORM.REPAIR.SHADES.SHUTTER.NOTFULLYUP': 'Nicht vollständig hochziehen',
  'FORM.REPAIR.SHADES.SHUTTER.REMAINSOPEN': 'Schliesst nicht vollständig',
  'FORM.REPAIR.SHADES.SHUTTER.STUCK': 'Steckt fest',
  'FORM.REPAIR.SHADES.SLATBLINDS': 'Lamellen&shy;storen',
  'FORM.REPAIR.SHADES.SLATBLINDS.CORDTORN': 'Schnur gerissen',
  'FORM.REPAIR.SHADES.SOUND.TITLE': 'Wenn Sie den Schalter bedienen, hören sie ein Geräusch?',
  'FORM.REPAIR.SHADES.SQUEAKING': 'Ja, Schleifen oder Quietschen',
  'FORM.REPAIR.SHADES.TWO': '2',
  'FORM.REPAIR.SHADES.WIDTHSTOREN.TITLE': 'Wie breit ist der Storen?',
  'FORM.REPAIR.SHADES.WINDOWSOPEN.TITLE': 'Können die Fenster geöffnet werden?',
  'FORM.REPAIR.SHAFT.BACKWATER': 'Abwasser drückt aus Abfluss',
  'FORM.REPAIR.SHAFT.CLOGGED': 'Verstopft',
  'FORM.REPAIR.SHAFT.DIRTY': 'Verschmutzt',
  'FORM.REPAIR.SHAFT.LOOSE': 'Wackelt',
  'FORM.REPAIR.SHAFT.SMELLS': 'Stinkt',
  'FORM.REPAIR.SHOWROOM': 'Verkaufsraum',
  'FORM.REPAIR.SHOWWINDOW': 'Schaufenster',
  'FORM.REPAIR.SHOWWINDOW.FRAME': 'Fensterrahmen',
  'FORM.REPAIR.SHOWWINDOW.FRAME.JOINTLEAK': 'Glasfuge undicht, löst sich',
  'FORM.REPAIR.SHOWWINDOW.FRAME.LEAK': 'Undicht',
  'FORM.REPAIR.SHOWWINDOW.GLASS': 'Fenster&shy;scheibe',
  'FORM.REPAIR.SHOWWINDOW.RAILS': 'Vorhang&shy;schiene',
  'FORM.REPAIR.SHOWWINDOW.RAILS.MISSING': 'Fehlt',
  'FORM.REPAIR.SHOWWINDOW.RAILS.STUCK': 'Gleiter, Vorhang läuft nicht',
  'FORM.REPAIR.SHOWWINDOW.RAILS.TORN': 'Ausgerissen',
  'FORM.REPAIR.SHOWWINDOW.SHUTTER': 'Rollladen, Rollgitter',
  'FORM.REPAIR.SHOWWINDOW.SHUTTER.BELT': 'Rollladen&shy;gurt',
  'FORM.REPAIR.SHOWWINDOW.SHUTTER.CRANK': 'Rollladen&shy;kurbel',
  'FORM.REPAIR.SHOWWINDOW.SHUTTER.CRANKBRACKET': 'Rollladen&shy;kurbel&shy;halterung',
  'FORM.REPAIR.SHOWWINDOW.SHUTTER.GRILLES': 'Rollgitter',
  'FORM.REPAIR.SHOWWINDOW.SHUTTER.LID': 'Rollladen&shy;deckel',
  'FORM.REPAIR.SHOWWINDOW.SHUTTER.ROLLER': 'Rollladen',
  'FORM.REPAIR.SHOWWINDOW.SILL': 'Fensterbank, -sims',
  'FORM.REPAIR.STORAGE': 'Stauraum',
  'FORM.REPAIR.SMARTCONTROL': 'Intelligente Steuerung',
  'FORM.REPAIR.SMARTCONTROL.BLINDS': 'Storen-Steuerung defekt',
  'FORM.REPAIR.SMARTCONTROL.DISPLAY': 'Display defekt (Hardware)',
  'FORM.REPAIR.SMARTCONTROL.HEATINGCOOLING': 'Heizungs- / Kühlungs-Steuerung defekt',
  'FORM.REPAIR.SMARTCONTROL.OTHER': 'Anderers Problem',
  'FORM.REPAIR.SMARTCONTROL.USERGUIDE': 'Frage zur Nutzung',
  'FORM.REPAIR.SMARTCONTROL.VIDEO': 'Video-Gegensprech&shy;anlage defekt',
  'FORM.REPAIR.STAIRWELL': 'Treppen&shy;haus',
  'FORM.REPAIR.SNOWREMOVAL': 'Schnee&shy;räumung',
  'FORM.REPAIR.STAIRS': 'Treppe',
  'FORM.REPAIR.STAIRS.STEPS': 'Treppe, Tritt',
  'FORM.REPAIR.STAIRS.STEPS.BROKEN': 'Gebrochen',
  'FORM.REPAIR.STAIRS.STEPS.LOOSE': 'Locker, wackelt',
  'FORM.REPAIR.STAIRS.STEPS.WORN': 'Zerschlissene Oberfläche',
  'FORM.REPAIR.STAIRS.HANDRAIL': 'Handlauf',
  'FORM.REPAIR.STAIRS.HANDRAIL.COATINGLOOSE': 'Beschichtung lose',
  'FORM.REPAIR.STAIRS.HANDRAIL.LOOSE': 'Locker, wackelt',
  'FORM.REPAIR.STAIRS.HANDRAIL.STACKSDEFECT': 'Stakete defekt',
  'FORM.REPAIR.STAIRS.HANDRAIL.TORN': 'Ausgerissen',
  'FORM.REPAIR.TECHNICALROOM': 'Technik&shy;raum',
  'FORM.REPAIR.TREATMENTROOM': 'Behandlungs&shy;zimmer',
  'FORM.REPAIR.VENTILATOR': 'Entlüfter',
  'FORM.REPAIR.VENTILATOR.FILTERDEFECT': 'Filter defekt',
  'FORM.REPAIR.VENTILATOR.LOWPERFORMANCE': 'Zieht nicht mehr richtig',
  'FORM.REPAIR.VENTILATOR.NOISE': 'Lärmt, pfeift',
  'FORM.REPAIR.VENTILATOR.NOTSWITCHINGOFFAUTO': 'Schaltet nicht automatisch aus',
  'FORM.REPAIR.VENTILATOR.NOTSWITCHINGON': 'Schaltet nicht mehr ein',
  'FORM.REPAIR.WALL': 'Wand',
  'FORM.REPAIR.WALL.COMESOFF': 'Färbt ab',
  'FORM.REPAIR.WALL.PLASTER': 'Verputz, Gips',
  'FORM.REPAIR.WALL.PLASTER.CRUMBLES': 'Bröckelt ab',
  'FORM.REPAIR.WALL.STAINS': 'Hat Flecken',
  'FORM.REPAIR.WALL.WALLPAPER': 'Tapete',
  'FORM.REPAIR.WALL.WALLPAPER.DISSOLVING': 'Blättert ab',
  'FORM.REPAIR.WALL.YELLOWSPOTS': 'Gelbe Flecken, gelbe Wand',
  'FORM.REPAIR.WASHBASIN': 'Waschbecken',
  'FORM.REPAIR.WASHBASIN.BASIN': 'Waschbecken',
  'FORM.REPAIR.WASHBASIN.BASIN.BACKWATER': 'Abwasser drückt aus Abfluss',
  'FORM.REPAIR.WASHBASIN.BASIN.CRACK': 'Riss',
  'FORM.REPAIR.WASHBASIN.BASIN.HOLE': 'Loch',
  'FORM.REPAIR.WASHBASIN.BASIN.QUIRK': 'Macke',
  'FORM.REPAIR.WASHBASIN.BASIN.SOAPDISHDEFECT': 'Seifenschale defekt',
  'FORM.REPAIR.WASHBASIN.BASIN.SOAPDISHHOLDERDEFECT': 'Seifen&shy;schalen-Halterung defekt',
  'FORM.REPAIR.WASHBASIN.BASIN.TOOTHBRUSHCUPDEFECT': 'Zahnputz&shy;becher defekt',
  'FORM.REPAIR.WASHBASIN.BASIN.TOOTHBRUSHCUPHOLDERDEFECT': 'Zahnputz&shy;becher&shy;halter defekt',
  'FORM.REPAIR.WASHBASIN.CLOGGED': 'Verstopft',
  'FORM.REPAIR.WASHBASIN.DRAIN': 'Abfluss, Siphon',
  'FORM.REPAIR.WASHBASIN.DRAIN.DRAINSSLOW': 'Läuft langsam ab',
  'FORM.REPAIR.WASHBASIN.DRAIN.NOISE': 'Gluckert / Geräusche',
  'FORM.REPAIR.WASHBASIN.FITTINGS': 'Armaturen',
  'FORM.REPAIR.WASHBASIN.FITTINGS.CLAMPS': 'Klemmt',
  'FORM.REPAIR.WASHBASIN.FITTINGS.DRIPS': 'Tropft',
  'FORM.REPAIR.WASHBASIN.FITTINGS.MOLDY': 'Schimmlig',
  'FORM.REPAIR.WASHBASIN.FITTINGS.SPLASHES': 'Spritzt',
  'FORM.REPAIR.WASHBASIN.FITTINGS.STAINS': 'Hat Flecken, ist angelaufen',
  'FORM.REPAIR.WASHBASIN.LEAKY': 'Undicht',
  'FORM.REPAIR.WASHBASIN.LOOSE': 'Locker, wackelt',
  'FORM.REPAIR.WASHBASIN.NOTCONNECTED': 'Lose, nicht verbunden',
  'FORM.REPAIR.WASHBASIN.PIPES': 'Abflussrohre',
  'FORM.REPAIR.WASHBASIN.SMELLS': 'Stinkt',
  'FORM.REPAIR.WASHBASIN.SUPPLYLINES': 'Zuleitungen',
  'FORM.REPAIR.WASHBASIN.TOWELRAIL': 'Handtuch&shy;halter',
  'FORM.REPAIR.WASHBASIN.TOWELRAIL.BROKEN': 'Gebrochen',
  'FORM.REPAIR.WASHBASIN.TOWELRAIL.TORN': 'Ausgerissen',
  'FORM.REPAIR.WASHBASIN.TOWELRAILHEATER': 'Handtuch&shy;heizung',
  'FORM.REPAIR.WASHBASIN.TOWELRAILHEATER.NOTWARM': 'Wird nicht mehr warm',
  'FORM.REPAIR.WASHBASIN.TOWELRAILHEATER.PAINTOFF': 'Farbe blättert ab',
  'FORM.REPAIR.WASHERDRYER': 'Waschmaschine, Trockner',
  'FORM.REPAIR.WASHERDRYER.DRYER': 'Wäschetrockner',
  'FORM.REPAIR.WASHERDRYER.DRYER.NOISE': 'Ungewöhnliche Geräusche',
  'FORM.REPAIR.WASHERDRYER.DRYER.NOTDRIES': 'Trocknet nicht mehr',
  'FORM.REPAIR.WASHERDRYER.HANGER': 'Wäsche&shy;aufhängung',
  'FORM.REPAIR.WASHERDRYER.HANGER.LINELOOSE': 'Wäscheleine locker',
  'FORM.REPAIR.WASHERDRYER.HANGER.LINETORN': 'Wäscheleine gerissen',
  'FORM.REPAIR.WASHERDRYER.HANGER.LOOSE': 'Befestigung wackelt, lose',
  'FORM.REPAIR.WASHERDRYER.LEAKING': 'Undicht: Wasser',
  'FORM.REPAIR.WASHERDRYER.NOELECTRICITY': 'Kein Strom',
  'FORM.REPAIR.WASHERDRYER.NOTHEATUP': 'Heizt nicht auf',
  'FORM.REPAIR.WASHERDRYER.SMELLS': 'Stinkt',
  'FORM.REPAIR.WASHERDRYER.SWITCHDEFECT': 'Schalter defekt',
  'FORM.REPAIR.WASHERDRYER.WASHER': 'Wasch&shy;maschine',
  'FORM.REPAIR.WASHERDRYER.WASHER.CARDNOTWORKING': 'Waschkarte geht nicht',
  'FORM.REPAIR.WASHERDRYER.WASHER.NOTPUMPOUTWATER': 'Pumpt Wasser nicht ab',
  'FORM.REPAIR.WASHERDRYER.WASHER.NOISE': 'Macht ungewöhnliche Geräusche',
  'FORM.REPAIR.WASHERDRYER.WASHER.NOTFLINGING': 'Schleudert nicht mehr',
  'FORM.REPAIR.WASHERDRYER.WASHER.NOOPENS': 'Öffnet nicht mehr',
  'FORM.REPAIR.WASTE': 'Abfalleimer',
  'FORM.REPAIR.WASTE.CROWDED': 'Überfüllt, nicht geleert',
  'FORM.REPAIR.WASTE.DEFECT': 'Abfalleimer defekt',
  'FORM.REPAIR.WASTEDUMP': 'Müllstandplatz',
  'FORM.REPAIR.WATERDAMAGE': 'Wasserschaden, Schimmel, Flecken',
  'FORM.REPAIR.WATERDAMAGE.5CHFCOIN': 'Etwa so gross wie Fünf&shy;franken&shy;stück',
  'FORM.REPAIR.WATERDAMAGE.BULGES': 'Boden hebt sich',
  'FORM.REPAIR.WATERDAMAGE.CEILING': 'Decke',
  'FORM.REPAIR.WATERDAMAGE.CEILINGPITCHEDROOF': 'Decke Schrägdach',
  'FORM.REPAIR.WATERDAMAGE.CEILINGWITHSKYLIGHT': 'Decke: Nahe einem Dachfenster',
  'FORM.REPAIR.WATERDAMAGE.DOUBLEDORMORE': 'Verdoppelt oder noch grösser',
  'FORM.REPAIR.WATERDAMAGE.DROPPING': 'Wasser tropfen an Wand oder Decke',
  'FORM.REPAIR.WATERDAMAGE.DURATION': 'Seit wann ist der Schaden ist zu sehen?',
  'FORM.REPAIR.WATERDAMAGE.FLOODING': 'Wasser&shy;pfütze oder Über&shy;schwemmung',
  'FORM.REPAIR.WATERDAMAGE.JOINTSMOLD': 'Fugen am Boden verschimmelt',
  'FORM.REPAIR.WATERDAMAGE.LARGERNOTDOUBLED': 'Grösser, aber nicht verdoppelt',
  'FORM.REPAIR.WATERDAMAGE.LOWERHALF': 'Wand: Untere Hälfte',
  'FORM.REPAIR.WATERDAMAGE.MINUS24HOURS': 'Innerhalb der letzten 24h',
  'FORM.REPAIR.WATERDAMAGE.MINUS5CHFCOIN': 'Kleiner als ein Fünf&shy;franken&shy;stück',
  'FORM.REPAIR.WATERDAMAGE.MINUS7DAYS': 'Innerhalb der letzten 7 Tage',
  'FORM.REPAIR.WATERDAMAGE.MOLD': 'Schimmel an Wand oder Decke',
  'FORM.REPAIR.WATERDAMAGE.NODROPPING': 'Nein',
  'FORM.REPAIR.WATERDAMAGE.NOSIZECHANGE': 'Nicht merklich',
  'FORM.REPAIR.WATERDAMAGE.PLUS5CHFCOIN': 'Deutlich grösser als ein Fünf&shy;franken&shy;stück',
  'FORM.REPAIR.WATERDAMAGE.PLUS7DAYS': 'Schon länger',
  'FORM.REPAIR.WATERDAMAGE.SIZE': 'Wie gross ist der Schaden?',
  'FORM.REPAIR.WATERDAMAGE.SIZECHANGE': 'Wie hat sich die Grösse innerhalb von 24h verändert?',
  'FORM.REPAIR.WATERDAMAGE.UPPERHALF': 'Wand: Obere Hälfte',
  'FORM.REPAIR.WATERDAMAGE.VISIBILITY': 'Sind Tropfen zu sehen?',
  'FORM.REPAIR.WATERDAMAGE.WALLCEILING': 'Wand und Decke',
  'FORM.REPAIR.WATERDAMAGE.WALLCEILINGFLOOR': 'Wand, Decke und Boden',
  'FORM.REPAIR.WATERDAMAGE.WHERE': 'Wo ist der Schaden?',
  'FORM.REPAIR.WATERDAMAGE.WINDOW': 'Wand: An einem Fenster',
  'FORM.REPAIR.WATERDAMAGE.WINDOWMOLD': 'Schimmel am Fenster',
  'FORM.REPAIR.WATERDAMAGE.WINDOWSAFFECTED': 'Wie viele Fenster sind betroffen?',
  'FORM.REPAIR.WATERDAMAGE.YELLOWSTAINS': 'Gelbe Flecken and Wand oder Decke',
  'FORM.REPAIR.WATERDAMAGE.YESDROPPING': 'Ja',
  'FORM.REPAIR.WATERSTAINS': 'Wasserflecken',
  'FORM.REPAIR.WATERWASTE': 'Abwasser&shy;rinne (aussen)',
  'FORM.REPAIR.WHOLE': 'Gesamte Gerwerbe&shy;fläche',
  'FORM.REPAIR.WINDOW': 'Fenster',
  'FORM.REPAIR.WINDOW.CASEMENT': 'Flügelrahmen',
  'FORM.REPAIR.WINDOW.FRAME': 'Fensterrahmen',
  'FORM.REPAIR.WINDOW.FRAME.BROKEN': 'Aufgebrochen',
  'FORM.REPAIR.WINDOW.FRAME.MOLD': 'Schimmlig',
  'FORM.REPAIR.WINDOW.FRAME.ROTTEN': 'Fäulnis / rostig',
  'FORM.REPAIR.WINDOW.GLASS': 'Fenster&shy;scheibe',
  'FORM.REPAIR.WINDOW.GLASS.BLIND': 'Blind',
  'FORM.REPAIR.WINDOW.GLASS.BROKEN': 'Zerbrochen',
  'FORM.REPAIR.WINDOW.GLASS.CONDENSATION': 'Kondenswasser auf Scheibe',
  'FORM.REPAIR.WINDOW.GLASS.CONDENSATIONBETWEEN': 'Kondenswasser zwischen Scheiben',
  'FORM.REPAIR.WINDOW.GLASS.CATFLAPDEFECT': 'Katzentüre defekt',
  'FORM.REPAIR.WINDOW.GLASS.CATFLAPINSTALL': 'Katzentüre einbauen',
  'FORM.REPAIR.WINDOW.GLASS.CATFLAPREMOVE': 'Katzentüre ausbauen',
  'FORM.REPAIR.WINDOW.GLASS.CRACKED': 'Gesprungen',
  'FORM.REPAIR.WINDOW.GLASS.SMASHED': 'Eingeschlagen',
  'FORM.REPAIR.WINDOW.GLASS.STONECHIP': 'Steinschlag',
  'FORM.REPAIR.WINDOW.HANDLE': 'Fenstergriff',
  'FORM.REPAIR.WINDOW.HANDLE.COVERMISSING': 'Griffabdeckung defekt, fehlt',
  'FORM.REPAIR.WINDOW.HANDLE.FALLEN': 'Abgefallen',
  'FORM.REPAIR.WINDOW.HANDLE.HARDTURN': 'Dreht schwer',
  'FORM.REPAIR.WINDOW.HANDLE.IDLE': 'Dreht leer',
  'FORM.REPAIR.WINDOW.HANDLE.JAMS': 'Klemmt',
  'FORM.REPAIR.WINDOW.HANDLE.KEYBROKEN': 'Schlüssel abgebrochen',
  'FORM.REPAIR.WINDOW.HANDLE.KEYMISSING': 'Schlüssel fehlt',
  'FORM.REPAIR.WINDOW.HANDLE.LOOSE': 'Locker, wackelt',
  'FORM.REPAIR.WINDOW.HANDLE.STAINS': 'Hat Flecken, ist angelaufen',
  'FORM.REPAIR.WINDOW.HANDLE.VERTICALTURN': 'Dreht nicht senkrecht',
  'FORM.REPAIR.WINDOW.JOINTMOLD': 'Glasfuge schimmlig',
  'FORM.REPAIR.WINDOW.LEAKING': 'Undicht',
  'FORM.REPAIR.WINDOW.LEAKING.CONDENSATION': 'Kondenswasser',
  'FORM.REPAIR.WINDOW.LEAKING.DRAFTS': 'Undicht Zugluft',
  'FORM.REPAIR.WINDOW.LEAKING.HEAVYRAIN': 'Undicht bei Starkregen',
  'FORM.REPAIR.WINDOW.LEAKING.SEALDEFECT': 'Gummidichtung defekt',
  'FORM.REPAIR.WINDOW.OPENINGCLOSING': 'Öffnen & schliessen',
  'FORM.REPAIR.WINDOW.OPENINGCLOSING.AUTOMATICDEFECT': 'Automatische Bedienung defekt',
  'FORM.REPAIR.WINDOW.OPENINGCLOSING.BROKEN': 'Aufgebrochen',
  'FORM.REPAIR.WINDOW.OPENINGCLOSING.GRINDS': 'Streift, steht an',
  'FORM.REPAIR.WINDOW.OPENINGCLOSING.HUNGOUT': 'Ausgehängt',
  'FORM.REPAIR.WINDOW.OPENINGCLOSING.JAMS': 'Klemmt',
  'FORM.REPAIR.WINDOW.OPENINGCLOSING.LIMITER': 'Öffnungs&shy;begrenzer',
  'FORM.REPAIR.WINDOW.OPENINGCLOSING.MECHANISMDEFECT': 'Öffnungs&shy;mechanik defekt',
  'FORM.REPAIR.WINDOW.OPENINGCLOSING.ONLYTILTED': 'Nur kippen möglich',
  'FORM.REPAIR.WINDOW.OPENINGCLOSING.REMAINSOPEN': 'Schliesst nicht',
  'FORM.REPAIR.WINDOW.OPENINGCLOSING.SECURITY': 'Fenstersicherung',
  'FORM.REPAIR.WINDOW.SEAL': 'Dichtungen',
  'FORM.REPAIR.WINDOW.SEAL.DEFECT': 'Gummidichtung defekt',
  'FORM.REPAIR.WINDOW.SEAL.JOINTLEAK': 'Glasfuge undicht, löst sich',
  'FORM.REPAIR.WINDOW.SEAL.MISSING': 'Gummidichtung fehlt',
  'FORM.REPAIR.WINDOW.SEAL.OVERLAPDEFECT': 'Überschlags&shy;dichtung defekt',
  'FORM.REPAIR.WINDOW.SEAL.OVERLAPMOLD': 'Überschlags&shy;dichtung schimmlig',
  'FORM.REPAIR.WINDOW.SILL': 'Fensterbank, -sims',
  'FORM.REPAIR.WINDOW.SILL.BROKEN': 'Gebrochen, gesprungen',
  'FORM.REPAIR.WINDOW.SILL.EDGEDEFECT': 'Kanten defekt',
  'FORM.REPAIR.WINDOW.SILL.LOOSE': 'Locker, wackelt',
  'FORM.REPAIR.WINDOW.SILL.RELEASING': 'Äblösende Flächen',
  'FORM.REPAIR.WINDOW.SILL.WATERSTAINS': 'Wasser&shy;flecken',

  'FORM.KEYORDER.DETAILS.TITLE': 'Grund der Bestellung',
  'FORM.KEYORDER.TITLE': 'Was ist der Grund der Bestellung?',
  'FORM.KEYORDER.LOST': 'Schlüssel verloren',
  'FORM.KEYORDER.KEY.TITLE': 'Schlüssel von',
  'FORM.KEYORDER.APARTMENTDOOR': 'Wohnungstüre',
  'FORM.KEYORDER.BUILDINGENTRANCE': 'Hauseingang',
  'FORM.KEYORDER.BASEMENT': 'Keller',
  'FORM.KEYORDER.MAILBOX': 'Briefkasten',
  'FORM.KEYORDER.OTHER': 'Anderer Schlüssel',
  'FORM.KEYORDER.ALL': 'Für alle: Wohnungstüre, Hauseingang, Keller, Briefkasten',
  'FORM.KEYORDER.EXTRA': 'Mehrbedarf',
  'FORM.KEYORDER.REPLACE': 'Zylinder und Schlüssel ersetzen',
  'FORM.KEYORDER.WASHCARD': 'Neue Waschkarte',
  'FORM.KEYORDER.SIPA': 'Neuer SIPA-Steck&shy;schlüssel',
  'FORM.KEYORDER.SIPA.TITLE': 'Welche Farbe hat der Schlüssel?',
  'FORM.KEYORDER.SIPA.BLUE': 'Blau',
  'FORM.KEYORDER.SIPA.GREEN': 'Grün',
  'FORM.KEYORDER.SIPA.RED': 'Rot',
  'FORM.KEYORDER.SIPA.YELLOW': 'Gelb',
  'FORM.KEYORDER.PARKINGTRADITIONAL': 'Neue Parkkarte',
  'FORM.KEYORDER.PARKINGWIFI': 'Neue Funk-Parkkarte',

  'FORM.NAMEPLATE.TITLE': 'Wo benötigen Sie ein neues Namensschild?',
  'FORM.NAMEPLATE.DETAILS.TITLE': 'Wo wird das Namensschild benötigt',
  'FORM.NAMEPLATE.DOOROUTSIDE': 'Türklingel',
  'FORM.NAMEPLATE.DOORINSIDE': 'Wohnungstüre',
  'FORM.NAMEPLATE.MAILBOX': 'Briefkasten',
  'FORM.NAMEPLATE.ALL': 'Für alle: Türklingel, Wohnungs&shy;türe & Brief&shy;kasten',

  'FORM.DOCUMENT.TITLE': 'Welches Dokument benötigen Sie?',
  'FORM.DOCUMENT.DETAILS.TITLE': 'Dokument oder Formular',
  'FORM.DOCUMENT.HANDOVER': 'Kopie Wohnungs&shy;übergabe&shy;protokoll',
  'FORM.DOCUMENT.RENTALCONTRACT': 'Kopie Mietvertrag',
  'FORM.DOCUMENT.UTILITYBILL': 'Kopie Nebenkosten&shy;abrechnung',
  'FORM.DOCUMENT.APPLICATIONPET': 'Antrag Haustier&shy;haltung',
  'FORM.DOCUMENT.REMODELLING': 'Antrag mieterseitige Ausbauten',
  'FORM.DOCUMENT.SUBLEASING': 'Antrag Untervermietung',
  'FORM.DOCUMENT.INSURANCE': 'Nachweis Gebäuder&shy;versicherung',
  'FORM.DOCUMENT.OTHER': 'Anderes Dokument',

  'FORM.OTHER.TITLE': 'Was ist Ihr Anliegen?',
  'FORM.OTHER.DETAILS.TITLE': 'Anliegen',
  'FORM.OTHER.PROPERTY': 'Allgemeines zur Liegenschaft',
  'FORM.OTHER.NAMECHANGE': 'Namensänderung, Heirat',
  'FORM.OTHER.EMAILCHANGE': 'Änderung E-Mail-Adresse',
  'FORM.OTHER.BANKACCOUNTCHANGE': 'Änderung Bank&shy;verbindung',
  'FORM.OTHER.SUBLEASING': 'Untervermietung',
  'FORM.OTHER.TERMOFNOTICE': 'Kündigungsfrist',
  'FORM.OTHER.OTHER': 'Etwas anderes',
};
